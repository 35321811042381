import { Box, Divider, SxProps } from "@mui/material";
import Input from "components/common/FormComponents/Input";
import errorMessage from "constants/errorMessage";
import React, { useEffect } from "react";
import {
  Control,
  UseFormSetValue,
  UseFormWatch,
  FieldErrors,
  useFieldArray,
} from "react-hook-form";
import regex from "utils/regex";
import { ResidentDetails, defaultValue } from "../../../../AddResident";
import { ClearBtn } from "../../../Resident";
import styles from "../../../styles";

type FamilyFieldsProps = {
  control: Control<ResidentDetails, any>;
  customStyles?: { [key: string]: SxProps };
  errors: FieldErrors;
  setValue: UseFormSetValue<ResidentDetails>;
  watch: UseFormWatch<any>;
  setEmail: React.Dispatch<
    React.SetStateAction<{ fieldName: string; email: string }>
  >;
};

const FamilyFields = ({
  control,
  errors,
  customStyles,
  setValue,
  watch,
  setEmail,
}: FamilyFieldsProps) => {
  const { fields, remove, prepend } = useFieldArray({
    name: `residents.familyMembers`,
    control,
  });

  const checkIfEmptyName = () => {
    const familyLength = watch("residents.familyMembers")?.length;
    for (let i = 0; i < familyLength; i++) {
      if (
        !watch(`residents.familyMembers.${i}.firstName`) ||
        !watch(`residents.familyMembers.${i}.lastName`)
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (fields?.length === 0) {
      prepend(defaultValue.familyMembers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <>
      {true && (
        <>
          <Box sx={styles.familyMemberWrapper}>
            <Box sx={styles.subHeadingV2}>Family Member details</Box>
            <Box
              sx={{
                ...styles.familyMemberAddBtn,
                color: !checkIfEmptyName() ? "#056EE6" : "#056ee696",
              }}
              onClick={() => {
                return (
                  !checkIfEmptyName() && prepend(defaultValue.familyMembers)
                );
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  prepend(defaultValue.familyMembers);
                }
              }}
              tabIndex={0}
            >
              + Family Members
            </Box>
          </Box>
          <Divider sx={styles.dividerStyles} color="#82B7F3" />
        </>
      )}
      <Box sx={{ maxHeight: "168px", overflowY: "auto", paddingRight: "3px" }}>
        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            <Box sx={styles.fieldsPrimaryWrapper}>
              <Box
                sx={
                  {
                    ...styles.fieldWrapper,
                    ...customStyles?.customFieldWrapper,
                  } as SxProps
                }
              >
                <Input
                  control={control}
                  label="First Name"
                  name={`residents.familyMembers.${index}.firstName`}
                  errors={errors}
                  isAlpha
                />
              </Box>
              <Box
                sx={
                  {
                    ...styles.fieldWrapper,
                    ...customStyles?.customFieldWrapper,
                  } as SxProps
                }
              >
                <Input
                  control={control}
                  label="Last Name"
                  name={`residents.familyMembers.${index}.lastName`}
                  errors={errors}
                  isAlpha
                />
              </Box>
              <Box
                sx={
                  {
                    ...styles.emailWrapper,
                    ...customStyles?.customEmailWrapper,
                  } as SxProps
                }
              >
                <Input
                  control={control}
                  label="Email"
                  name={`residents.familyMembers.${index}.email`}
                  errors={errors}
                  rules={{
                    pattern: {
                      value: regex.email,
                      message: errorMessage.invalidEmail,
                    },
                  }}
                  onBlur={() =>
                    setEmail({
                      fieldName: `residents.familyMembers.${index}.email`,
                      email: watch(`residents.familyMembers.${index}.email`),
                    })
                  }
                />
              </Box>
              <Box sx={styles.updateIconsWrapper}>
                {index > 0 && (
                  <Box
                    sx={styles.familyDelBtn}
                    component="img"
                    src="/icons/del-bin.svg"
                    alt="del-icon"
                    onClick={() => {
                      remove(index);
                    }}
                    tabIndex={0}
                  />
                )}
                <ClearBtn
                  onClick={() => {
                    setValue(`residents.familyMembers.${index}.firstName`, "");
                    setValue(`residents.familyMembers.${index}.lastName`, "");
                    setValue(`residents.familyMembers.${index}.email`, "");
                  }}
                />
              </Box>
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

export default FamilyFields;
