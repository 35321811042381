import { SxProps } from "@mui/material";

const styles: { [key: string]: SxProps } = {
  wrapper: {
    "& .MuiInputBase-root": {
      borderColor: "#7F7F7F",
    },
    "& .MuiInput-underline:after": {},
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  },
};

export default styles;
