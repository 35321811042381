import { useDropzone } from "react-dropzone";

import {
  Button,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputBaseProps,
  InputLabel,
  SxProps as SxPropsMaterial,
} from "@mui/material";
import { Box, SxProps as SxPropsSystem } from "@mui/system";
import errorMessage from "constants/errorMessage";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import styles from "./styles";

type FileUploadProps<T extends object> = UseControllerProps<T> &
  FormControlProps &
  InputBaseProps & {
    label: string;
    labelStyle?: SxPropsMaterial;
    labelPos?: boolean;
    maxLimit: number;
    multiple: boolean;
    disabled?: boolean;
    accept?: string;
    maxSize?: number;
    readOnly?: boolean;
    clearErrors: UseFormClearErrors<T>;
    setValue: UseFormSetValue<T>;
    getValues?: UseFormGetValues<T>;
    setError?: UseFormSetError<T>;
    customStyle?: Record<string, SxPropsSystem<T>>;
    fileDropperLabel?: string;
    errorsAtTop?: boolean;
    topErrorMsg?: string;
    backendValidation?: boolean;
    setFileParent: React.Dispatch<React.SetStateAction<File[]>>;
    backendErrors?: {
      row: number;
      error: string;
    }[];
    file?: File[];
  };

const FileUpload = <T extends FieldValues>({
  control,
  name,
  rules,
  label,
  disabled = false,
  labelPos = true,
  maxLimit = 10,
  maxSize = 10000000, // 10 MB
  multiple = true,
  accept = "image/jpeg , application/pdf",
  readOnly = false,
  clearErrors,
  setValue,
  getValues,
  setError,
  customStyle,
  fileDropperLabel,
  errorsAtTop = false,
  topErrorMsg = "",
  backendValidation,
  setFileParent,
  backendErrors,
  file,
}: FileUploadProps<T>) => {
  const ref = useRef(null);
  const [files, setFiles] = useState<File[]>(file ?? getValues(name));
  const fileType = accept.split(",").map((str) => str.split("/")[1].trim());

  const validateFiles = useCallback(
    (uploadFile: File) => {
      if (
        multiple &&
        files.some((fileInstance) => uploadFile.name === fileInstance.name)
      ) {
        setError(name, {
          type: "duplicateFile",
          message: errorMessage.fileUpload.alreadyExist,
        });
        return false;
      }

      if (uploadFile.size > maxSize) {
        setError(name, {
          type: "sizeLimit",
          message: errorMessage.fileUpload.maxFileSize,
        });
        return false;
      }

      if (!fileType.some((type) => uploadFile.name.includes(type))) {
        setError(name, {
          type: "invalidFiletype",
          message: errorMessage.fileUpload.invalidFileType,
        });
        return false;
      }

      clearErrors(name);
      return true;
    },
    [clearErrors, fileType, files, maxSize, multiple, name, setError],
  );

  const handleAllFiles = useCallback(
    (allFiles) => {
      let rightFiles: File[] = [];
      for (let key in allFiles) {
        const err = validateFiles(allFiles[key]);
        if (err) {
          rightFiles.push(allFiles[key]);
        }
      }

      const sliceFiles = rightFiles.splice(0, maxLimit - files.length);

      if (sliceFiles.length)
        setFiles((prevFiles) => [...prevFiles, ...sliceFiles]);
      setFileParent((prevFiles) => [...prevFiles, ...sliceFiles]);
    },
    [files.length, maxLimit, setFileParent, validateFiles],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let allFiles = { ...e.target.files };
    handleAllFiles(allFiles);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleAllFiles(acceptedFiles);
    },
    [handleAllFiles],
  );

  const deleteFile = (fileName: string) => {
    setFiles(files.filter((fileInstance) => fileInstance.name !== fileName));
    setFileParent(
      files.filter((fileInstance) => fileInstance.name !== fileName),
    );
  };

  useEffect(() => {
    setValue(name, files as PathValue<T, Path<T> & string>);
  }, [files, name, setValue]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ fieldState: { error } }) => {
          return (
            <>
              {errorsAtTop && !!error && (
                <Box sx={styles.errorAtTop}>
                  {error?.type === "invalidFiletype"
                    ? topErrorMsg
                    : error?.message}
                </Box>
              )}

              <Box
                sx={
                  {
                    ...styles.wrapper,
                    ...customStyle?.wrapper,
                  } as SxPropsSystem
                }
              >
                <Box className={labelPos && "labelPos"} {...getRootProps()}>
                  {label && (
                    <InputLabel
                      shrink={false}
                      className="label"
                      disabled={disabled}
                      required={!!rules?.required}
                    >
                      {label}
                    </InputLabel>
                  )}

                  <FormControl
                    fullWidth={true}
                    sx={
                      {
                        ...styles.inputWrapper,
                        ...customStyle?.inputWrapper,
                        background:
                          !!error || backendValidation
                            ? `rgba(192, 41, 29,${isDragActive ? "24%" : "0.05"
                            })`
                            : `rgba(5, 110, 230,${isDragActive ? "24%" : "0.05"
                            })`,
                      } as SxPropsSystem
                    }
                  >
                    {backendValidation && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 20,
                          color: "#C0291D",
                          fontWeight: "500",
                          fontSize: "15px",
                          width: "340px",
                          height: "220px",
                          overflowY: "auto",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",
                        }}
                      >
                        {Array.isArray(backendErrors)
                          ? fileDropperLabel
                          : !!backendErrors
                            ? backendErrors
                            : ""}
                        {Array.isArray(backendErrors) &&
                          backendErrors?.map((message) => {
                            return (
                              <Box>
                                • Row {message?.row}, {message?.error}
                              </Box>
                            );
                          })}
                      </Box>
                    )}
                    <Box
                      {...getInputProps()}
                      multiple={multiple}
                      component="input"
                      type="file"
                      value=""
                      onChange={handleChange}
                      ref={ref}
                      sx={styles.hide}
                    />
                    <Box
                      sx={
                        {
                          ...styles.filesWrapper,
                          ...customStyle?.filesWrapper,
                          border: `1px dashed ${!!error || backendValidation ? "#C0291D" : "#056EE6"
                            }`,
                        } as SxPropsSystem
                      }
                    >
                      {files.length < maxLimit && (
                        <Button
                          disabled={readOnly}
                          disableRipple
                          sx={
                            {
                              ...styles.uploadBtn,
                              ...customStyle?.uploadBtn,
                              fontWeight: backendValidation ? "500" : "700",
                              top: backendValidation ? "-110px" : "0",
                            } as SxPropsSystem
                          }
                          onClick={(event) => {
                            ref.current.click();
                            event.stopPropagation();
                          }}
                        >
                          {!!fileDropperLabel
                            ? fileDropperLabel
                            : files.length < 1 &&
                            "Attach a files (max 2 files)"}
                        </Button>
                      )}
                      {!!files.length && !backendValidation && (
                        <Box sx={styles.files}>
                          {files.length !== 0 &&
                            files.map((fileInstance, idx) => (
                              <Box key={idx} sx={styles.fileInstance}>
                                {fileInstance.name.length > 10 && multiple
                                  ? `${fileInstance.name.slice(0, 12)}...`
                                  : fileInstance.name}
                                {!readOnly && (
                                  <Box
                                    sx={styles.delIcon}
                                    component="img"
                                    src="/icons/del-file.svg"
                                    alt="delete"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      deleteFile(fileInstance.name);
                                    }}
                                  />
                                )}
                              </Box>
                            ))}
                        </Box>
                      )}
                    </Box>
                    {!!error && !errorsAtTop && (
                      <>
                        {error.message}
                        <FormHelperText sx={styles.error}>
                          {error.message}
                        </FormHelperText>
                      </>
                    )}
                  </FormControl>
                </Box>
              </Box>
            </>
          );
        }}
      />
    </>
  );
};

export default FileUpload;
