import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    height: "70vh",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "primary.main",
  },
};

export default styles;
