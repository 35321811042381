import { SxProps } from "@mui/material";

const styles: { [key: string]: SxProps } = {};

export const uploaderStyles: { [key: string]: SxProps } = {
  wrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  inputWrapper: {
    width: "505px",
    height: "305px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  filesWrapper: {
    width: "504px",
    height: "305px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "15px",
    color: "black",
    flexDirection: "column",
  },
  uploadBtn: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    whiteSpace: "pre-wrap",
    padding: "0 128px",
    fontSize: "15px",
    fontWeight: "700",
    textTransform: "none",
    "&:hover": {
      background: "transparent",
    },
  },
};

export default styles;
