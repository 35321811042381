import { Box, Divider } from "@mui/material";
import Header from "components/common/Header";
import styles, { MenuItem } from "./styles";
import { sidebarMenu } from "constants/common";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { ReactNode } from "react";
type DefaultLayoutProps = {
  children?: ReactNode;
};

const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  const location = useLocation();

  const sideBarStyleManager = ["/", "/user-profile", "/add-resident"].includes(
    location.pathname,
  );

  return (
    <Box sx={styles.layout}>
      <Header />
      <Box sx={styles.wrapper}>
        {sidebarMenu.map((item) => item.route).includes(location.pathname) && (
          <Box sx={styles.left}>
            <Box sx={styles.sidebar}>
              {sidebarMenu.map((item) => (
                <Box key={item.name} sx={styles.container}>
                  <Box sx={styles.item}>
                    <Link to={item.route}>
                      <MenuItem matchedRoute={location.pathname === item.route}>
                        <Box
                          className="icon"
                          component="img"
                          src={item.icon}
                          alt={item.icon}
                        />
                        <Box sx={styles.option}>{item.name}</Box>
                      </MenuItem>
                    </Link>
                  </Box>
                  <Divider sx={styles.divider} />
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <Box
          sx={{
            ...styles.right,
            marginLeft: sideBarStyleManager ? "0" : "200px",
          }}
        >
          <Box>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DefaultLayout;
