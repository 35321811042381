import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  maskedFormat: string;
};

const MaskedInput = forwardRef<HTMLElement, CustomProps>((props, ref) => {
  const { onChange, maskedFormat, ...rest } = props;
  const handleAccept = (value: string) => {
    const valueInUpperCase = value.toUpperCase();
    onChange({ target: { name: props.name, value: valueInUpperCase } });
  };
  return (
    <IMaskInput
      mask={maskedFormat}
      definitions={{
        "*": /[A-Za-z0-9]/,
      }}
      lazy={false}
      ref={ref}
      onAccept={handleAccept}
      overwrite
      {...rest}
    />
  );
});

export default MaskedInput;
