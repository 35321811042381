import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    "& a": {
      textDecoration: "underline",
    },
    "& td": {
      textAlign: "center",
      padding: "5.5px",
      border: "none",
      borderRadius: "5px",
    },
    display: "flex",
    height: "calc(100vh - 90px)",
    flexBasis: "100%",
    flexDirection: "column",
  },

  backWrapper: {
    position: "absolute",
    marginTop: "10px",
    marginLeft: "45px",
  },

  formBtnWrapper: {
    position: "relative",
  },

  backArrow: {
    marginRight: "10px",
    cursor: "pointer",
  },

  btnWrapper: {
    display: "flex",
    justifyContent: "end",
    alignSelf: "center",
    width: "100%",
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
    },
  },

  btn: {
    fontSize: "14px",
    minWidth: "150px",
    margin: "10px 0",
    height: "28px",
    backgroundColor: "primary.dark",
  },

  tableLink: {
    color: "primary.main",
    cursor: "pointer",
    textDecoration: "underline",
    maxWidth: "130px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },

  del: {
    cursor: "pointer",
  },

  tableWrapper: { display: "flex", flexDirection: "column", flexBasis: "100%" },

  backStyles: {
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    cursor: "pointer",
    color: "custom.background.dropdownCta",
    top: "10px",
    left: "10px",
    "&:after": {
      top: "20px",
      content: '""',
      display: "block",
      margin: "auto",
      height: "1.5px",
      width: "100%",
      background: "#056EE6",
      position: "absolute",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
      marginRight: "0",
    },
  },
};

export default styles;
