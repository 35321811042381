import Close from "@mui/icons-material/Close";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputAdornment,
  InputBaseProps,
  InputLabel,
} from "@mui/material";
import { SxProps } from "@mui/system";
import {
  Controller,
  FieldErrors,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { getError } from "utils/common";
import { preventNumberInput } from "utils/helper";
import styles, { InputBase } from "./styles";

type InputProps<T> = UseControllerProps<T> &
  FormControlProps &
  InputBaseProps & {
    label: string;
    errors?: FieldErrors;
    maxLength?: number;
    customStyles?: SxProps;
    labelPos?: boolean;
    labelPosStyles?: boolean;
    readOnly?: boolean;
    showAdornment?: boolean;
    adornmentPos?: "start" | "end";
    iconSrc?: string;
    placeholder?: string;
    autoFocus?: boolean;
    isValid?: boolean;
    isAlpha?: boolean;
    customStyle?: { [key: string]: SxProps };
  };

const Input = <T extends FieldValues>({
  name,
  control,
  label,
  type = "text",
  fullWidth = true,
  labelPos = false,
  labelPosStyles = false,
  errors,
  disabled = false,
  rules,
  customStyles,
  maxLength,
  inputProps,
  placeholder,
  isAlpha,
  showAdornment = false,
  adornmentPos = "end",
  variant = "standard",
  className,
  isValid = false,
  readOnly = false,
  autoFocus = false,
  customStyle,
  ...rest
}: InputProps<T>) => {
  const error = getError(name, errors);

  return (
    <Box sx={{ ...styles.wrapper, ...customStyle?.wrapper } as SxProps}>
      <Controller
        render={({ field }) => (
          <FormControl
            sx={customStyles}
            fullWidth={fullWidth}
            variant={variant}
            className={className}
          >
            <Box
              sx={
                labelPos &&
                styles[labelPosStyles ? "labelPosStyles" : "labelPos"]
              }
            >
              <InputLabel
                shrink
                className="label"
                disabled={disabled}
                sx={{ ...styles.label, ...customStyle?.input } as SxProps}
                required={!!rules?.required}
                htmlFor={name}
              >
                {label}
              </InputLabel>
              <InputBase
                id={name}
                sx={{ ...styles.input, ...customStyle?.inputField } as SxProps}
                type={type}
                disabled={disabled}
                value={field?.value}
                onChange={field.onChange}
                inputRef={field.ref}
                onBlur={field.onBlur}
                readOnly={readOnly}
                placeholder={placeholder}
                autoFocus={autoFocus}
                onKeyDown={isAlpha && preventNumberInput}
                inputProps={{ maxLength: maxLength, ...inputProps }}
                style={{ background: readOnly ? "none" : "white" }}
                startAdornment={
                  <InputAdornment
                    aria-label="Search Icon"
                    sx={{
                      ...styles.adornment,
                      display:
                        showAdornment && adornmentPos === "start"
                          ? "block"
                          : "none",
                    }}
                    position="start"
                  >
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment
                    sx={{
                      ...styles.adornment,
                      display:
                        showAdornment && adornmentPos === "end"
                          ? "block"
                          : "none",
                    }}
                    position="end"
                  >
                    {!!error ? (
                      <Close color="error" />
                    ) : (
                      field.value !== "" && (
                        <DoneRoundedIcon sx={styles.doneIcon} />
                      )
                    )}
                  </InputAdornment>
                }
                error={!!error}
                hasError={!!error && showAdornment}
                valid={isValid}
                typed={showAdornment && adornmentPos === "end"}
                {...rest}
              />
            </Box>
            {error && (
              <FormHelperText sx={labelPos && styles.error}>
                {error.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
        name={name}
        control={control}
        rules={rules}
        {...rest}
      />
    </Box>
  );
};
export default Input;
