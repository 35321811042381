import { Styles } from "types/common";

const styles: Styles = {
  infoPopupCont: { height: "305px", padding: "0", width: "100%" },

  infoPopupView: { background: "#F3F5F8", padding: "0" },

  wrapper: {
    "& .MuiInputBase-root": {
      marginTop: "0",
      fontWeight: "400 !important",
    },
  },

  helperText: {
    maxWidth: "393px",
    fontWeight: 500,
    fontSize: "15px",
    color: "black",
    lineHeight: "20px",
    margin: "15px 0 4px 0",
  },

  phoneHelperText: {
    fontSize: "12px",
    color: "black",
    fontWeight: 500,
  },

  fieldPrimaryWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },

  fieldSecondaryWrapperV1: { width: "240px" },

  contactFieldWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    gap: "14px",
    mt: "15px",
  },

  inputContact: {
    "& .MuiInputBase-root": {
      borderColor: "custom.text.darkGray",
    },
    "& .MuiInputBase-input": {
      MozAppearance: "textfield",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        MozAppearance: "textfield",
        "&::-moz-focus-inner": {
          borderStyle: "none",
        },
      },
    },
    // "& .MuiFormHelperText-root": {
    //   bottom: "-32px",
    // },
  },
};

export default styles;
