import { AxiosResponse } from "axios";
import DropDown from "components/common/DropDown";
import { useState } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import Manually from "./Manually";
import Pcc from "./Pcc";

export type AddHomeFormData = {
  pccId: string;
};

export type AddNewHome = {
  careHomeName: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
};

export type AddHomeProps = {
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<QueryObserverResult<AxiosResponse<any>, unknown>>;
};

type Modal = "add" | "addManually" | undefined;

const AddHome = ({ refetch }: AddHomeProps) => {
  const [openModal, setModal] = useState<Modal>();

  return (
    <>
      <DropDown
        title="Add Home"
        id="addHome"
        setOpenModal={setModal}
        menuItems={[
          {
            label: "Manual",
            onClick: () => {
              setModal("addManually");
            },
          },
          {
            label: "PointClickCare",
            onClick: () => {
              setModal("add");
            },
          },
        ]}
      />
      {openModal === "addManually" && (
        <Manually refetch={refetch} setDropDown={setModal} />
      )}
      {openModal === "add" && <Pcc refetch={refetch} setDropDown={setModal} />}
    </>
  );
};

export default AddHome;
