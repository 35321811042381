import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import Button from "components/common/Button";
import {
  Date as DatePicker,
  Input,
  Time,
} from "components/common/FormComponents";
import TextArea from "components/common/FormComponents/TextArea";
import PopUp, { Action } from "components/common/PopUp";
import { SimpleInput, SimplePopUp } from "components/common/PopUp/Modal";
import { errorModal } from "constants/dummyData";
import errorMessage from "constants/errorMessage";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { deleteAppointmentById } from "utils/api/user";
import { getTime, toLocalDate } from "utils/common";
import { FormType } from "../..";
import styles from "./styles";

export type CancelFormData = {
  title: string;
  residentName: string;
  visitorName: string;
  date: string;
  startTime: string;
  endTime: string;
  visitors: string;
  description: string;
  cancel: string;
};

export type CancelFormProps = {
  submitBtnLabel: string;
  rowData: any;
  btnLabel?: string;
  viewOnly?: boolean;
  onSubmitForm?: (data: any) => void;
  setForm: React.Dispatch<React.SetStateAction<FormType>>;
  refetch?: any;
};

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
  props?: any;
};

const Cancel = ({
  btnLabel,
  rowData,
  viewOnly = false,
  submitBtnLabel,
  setForm,
  refetch,
}: CancelFormProps) => {
  const [openModal, setModal] = useState<"cancel" | "error">(null);

  const { mutate: appointmentDelete, isLoading } = useMutation(
    "deleteAppointment",
    () =>
      deleteAppointmentById({
        appointmentId: rowData?.id,
        reason: watch("cancel"),
      }),
    {
      onSuccess: () => {
        refetch();
        setModal(null);
        setForm(null);
        setValue("cancel", "");
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setModal("error");
        }
      },
      retry: 1,
    },
  );

  const data = useMemo(
    () => ({
      title: rowData.title,
      residentName: rowData.residentName,
      visitors: rowData.noOfVisitors,
      description: rowData.description,
      visitorName: rowData.visitorName.split("(")[0],
      date: toLocalDate(rowData.startTime),
      startTime: getTime(rowData.startTime),
      endTime: getTime(rowData.endTime),
      cancel: "",
    }),
    [
      rowData.description,
      rowData.endTime,
      rowData.noOfVisitors,
      rowData.residentName,
      rowData.startTime,
      rowData.title,
      rowData.visitorName,
    ],
  );

  const {
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<CancelFormData>({
    mode: "onChange",
    defaultValues: useMemo(() => data, [data]),
  });

  const handleClose = () => {
    setValue("cancel", "");
    clearErrors("cancel");
    setModal(null);
  };

  const cancelModal: ModalData = {
    heading: "Cancel Appointment",
    actionLeft: {
      label: "Cancel",
      onClick: handleClose,
    },
    actionRight: {
      label: "Yes",
      type: "submit",
    },
  };

  const handleRightBtnClick = () => {
    appointmentDelete();
  };

  const [modalData, setModalData] = useState(cancelModal);
  return (
    <Box>
      <PopUp
        open={!!openModal}
        handleClose={handleClose}
        heading={modalData?.heading}
        actionLeft={modalData?.actionLeft}
        actionRight={{
          ...modalData?.actionRight,
          disabled: !watch("cancel").length,
        }}
        loading={isLoading}
        customStyles={{
          button: { marginTop: "0px" },
        }}
        handleRightBtnClick={handleRightBtnClick}
      >
        {openModal === "cancel" && (
          <SimpleInput
            name="cancel"
            label={"Please enter the reason for canceling this appointment"}
            control={control}
            placeholder="Reason for cancelation"
            errors={errors}
            autoFocus={false}
            rules={{
              required: {
                value: true,
                message: errorMessage.required,
              },
            }}
            minRows={3.5}
            maxRows={3.5}
            multiline
            textArea
          />
        )}
        {openModal === "error" && (
          <SimplePopUp description={modalData.props.description} />
        )}
      </PopUp>
      <form>
        <Box sx={styles.submitWrapper}>
          <Button
            label={submitBtnLabel}
            sx={styles.submitBtn}
            variant="contained"
            icon={false}
            onClick={() => {
              setModalData(cancelModal);
              setModal("cancel");
            }}
          >
            <AddIcon fontSize="small" sx={styles.icon} />
          </Button>
        </Box>
        <Box sx={styles.inputWrapper}>
          <Box sx={styles.input}>
            <Input
              control={control}
              label="Title"
              name="title"
              errors={errors}
              labelPos
              readOnly={true}
            />
          </Box>
          <Box sx={styles.dflex}>
            <Box sx={styles.input}>
              <Input
                control={control}
                label="Resident Name:"
                name="residentName"
                readOnly={true}
                labelPos
                errors={errors}
              />
            </Box>
            <Box sx={styles.input}>
              <Input
                control={control}
                label="Visitor Name:"
                name="visitorName"
                readOnly={true}
                labelPos
                errors={errors}
              />
            </Box>
          </Box>

          <Box sx={{ ...styles.dflex, marginTop: "20px" }}>
            <Box sx={{ ...styles.inputVariant }}>
              <DatePicker
                name="date"
                control={control}
                type="date"
                label="Date"
                labelPos
                className="date"
                minDate={watch().date}
                readOnly={true}
                errors={errors}
                fullWidth
                setValue={setValue}
              />
            </Box>
          </Box>
          <Box sx={{ ...styles.dflex, marginBottom: "-5px" }}>
            <Box sx={styles.inputVariant}>
              <Time
                name="startTime"
                label="Start Time"
                control={control}
                labelPos
                readOnly={true}
                getValues={getValues}
                setValue={setValue}
                clearErrors={clearErrors}
              />
            </Box>
            <Box sx={styles.inputVariant}>
              <Time
                name="endTime"
                label="End Time"
                control={control}
                labelPos
                readOnly={true}
                getValues={getValues}
                setValue={setValue}
                clearErrors={clearErrors}
              />
            </Box>
          </Box>
          <Box sx={{ ...styles.input, width: "49%" }}>
            <Input
              control={control}
              label="Visitors"
              name="visitors"
              errors={errors}
              labelPos
              readOnly={true}
            />
          </Box>
          <Box
            sx={{ ...styles.input, marginTop: "20px", marginBottom: "40px" }}
          >
            <TextArea
              name="description"
              label="Description"
              type="text"
              control={control}
              multiline
              minRows={10}
              maxRows={10}
              errors={errors}
              readOnly={true}
              labelPos
              customStyles={styles.textArea}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Cancel;
