import { Box } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import DataGrid from "components/common/DataGrid";
import PopUp, { Action } from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import SearchBar from "components/common/SearchField";
import Toggle from "components/common/Toggle";
import { errorModal } from "constants/dummyData";
import { route } from "constants/routes";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import { setPccId } from "redux/slices/pccInfo";
import { setCareHomeData, setCareHomeRowData } from "redux/slices/user";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getCareHome, statusToggle } from "utils/api/user";
import { dataTransformer } from "utils/common";
import AddHome from "./AddHome";
import Invite from "./Invite";
import styles from "./styles";

export type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
};

export type ToggleFamilyMember = {
  props: {
    description: string;
    note?: string;
  };
} & ModalData;

type CareHome = {
  id: string;
  careHomeName: string;
  city: string;
  prov: string;
  country: string;
  invite: boolean;
  status: boolean;
}[];

export const deactivateFamilyMember: ToggleFamilyMember = {
  heading: "Deactivate Care Home",
  props: {
    description: "Are you sure you want to deactivate ‘Asus Nursing Home’?",
  },

  actionLeft: {
    label: "Cancel",
  },
  actionRight: {
    label: "Deactivate",
    actionCustomStyles: {
      button: {
        backgroundColor: "#c0291d",
        "&:hover": {
          backgroundColor: "#c0291d",
        },
      },
    },
  },
};

export const activateFamilyMember: ToggleFamilyMember = {
  heading: "Activate Care Home",
  props: {
    description: "Do you want to activate ‘Asus Nursing Home’?",
    note: "",
  },
  actionLeft: {
    label: "Cancel",
  },
  actionRight: {
    label: "Activate",
  },
};

const ManageHomeDefault = () => {
  const [modalData, setModalData] = useState<ToggleFamilyMember>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>();
  const history = useHistory();
  const [page, setPage] = useState<number>(1);
  const [filteredData] = useState<CareHome>([]);
  const { isLoggedIn, careHomeData } = useAppSelector((state) => state.user);
  const [search, setSearch] = useState("");

  const dispatch = useAppDispatch();

  const { data: careHomes, refetch } = useQuery(
    ["getAllCareHome", page, search, careHomeData],
    () => getCareHome({ page, search }),
    {
      onSuccess: (data) => {
        dispatch(setCareHomeData(dataTransformer(data?.data?.data)));
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal(true);
        }
      },
      retry: 1,
    },
  );

  const { mutate, isLoading } = useMutation(
    "statusToggle",
    (data: { id: string; status: boolean }) => statusToggle(data),
    {
      onSuccess: () => {
        refetch();
        handleClose();
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal(true);
        }
      },
      retry: 1,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const getSearchText = useCallback((text: string) => {
    const txt = text.trim().toLowerCase();
    setSearch(txt);
  }, []);

  const getDescWithName = (name, isActive) => {
    return isActive
      ? `Are you sure you want to deactivate '${name}'?`
      : `Do you want to activate '${name}'?`;
  };

  const handleToggle = (data) => {
    setRowData(data);
    data.status
      ? setModalData({
          ...deactivateFamilyMember,
          props: {
            ...deactivateFamilyMember.props,
            description: getDescWithName(data.careHomeName, data.status),
          },
        })
      : setModalData({
          ...activateFamilyMember,
          props: {
            ...activateFamilyMember.props,
            description: getDescWithName(data.careHomeName, data.status),
          },
        });
    setOpenModal(true);
  };

  const handleRightBtnClick = () => {
    mutate(rowData);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const renderCareHome = (params: GridCellParams) => {
    return (
      <Box
        sx={styles.tableLink}
        onClick={() => {
          dispatch(setPccId(params.row.id));
          dispatch(setCareHomeRowData(params.row));
          history.push(route.manageResidents.path);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            dispatch(setPccId(params.row.id));
            dispatch(setCareHomeRowData(params.row));
            history.push(route.manageResidents.path);
          }
        }}
        tabIndex={0}
      >
        {params.row.careHomeName}
      </Box>
    );
  };

  const renderStatus = (params: GridCellParams) => {
    return (
      <Box sx={styles.actionIcon}>
        <Toggle
          value={params.row.status}
          onChange={() => {
            handleToggle(params.row);
          }}
        />
      </Box>
    );
  };

  const renderInvite = (params: GridCellParams) => {
    return (
      <Box sx={styles.tableLink}>
        <Invite
          label={"Send Invite"}
          isResending={params?.row?.adminEmails?.length !== 0}
          pccId={params.row.id}
          refetch={refetch}
          emails={params?.row?.adminEmails?.map((itm) => itm.email)}
        />
      </Box>
    );
  };

  const careHomeColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "S.No",
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "careHomeName",
      headerName: "Care Home Name",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderCareHome,
    },
    {
      field: "city",
      headerName: "City",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "prov",
      headerName: "Prov./St.",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "country",
      headerName: "Country",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "invite",
      headerName: "Invite",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderInvite,
    },
    {
      field: "status",
      headerName: "Data",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderStatus,
    },
  ];

  return (
    <>
      {isLoggedIn ? (
        <Box sx={styles.wrapper}>
          {!!modalData && (
            <PopUp
              open={openModal}
              handleClose={handleClose}
              heading={modalData.heading}
              actionLeft={modalData.actionLeft}
              actionRight={modalData.actionRight}
              handleRightBtnClick={handleRightBtnClick}
              loading={isLoading}
            >
              <SimplePopUp
                description={modalData.props.description}
                note={modalData.props.note}
              />
            </PopUp>
          )}
          <Box sx={styles.head}>
            <Box sx={styles.heading}>Manage Homes</Box>
            <Box sx={styles.SearchBar}>
              <SearchBar getText={getSearchText} />
            </Box>
            <AddHome refetch={refetch} />
          </Box>
          <DataGrid
            rows={careHomeData || []}
            columns={careHomeColumn}
            disableSelectionOnClick
            onPageChange={(pageNo) => setPage(pageNo + 1)}
            rowsPerPageOptions={[10]}
            pageSize={10}
            rowCount={careHomes?.data?.metadata?.total || filteredData.length}
            loading={false}
            componentsProps={{
              toolbar: { showQuickFilter: true },
            }}
            emptyTable="No homes added yet."
          />
        </Box>
      ) : (
        <Box sx={styles.emptyBg} />
      )}
    </>
  );
};

export default ManageHomeDefault;
