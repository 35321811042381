import { FieldErrors } from "react-hook-form";

export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";

export const userSessionActive = (value: {
  accessToken: string;
  refreshToken: string;
}) => {
  localStorage.setItem(ACCESS_TOKEN, value.accessToken);
  localStorage.setItem(REFRESH_TOKEN, value.refreshToken);
};

export const userSessionInactive = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

export const dataTransformer = (data) =>
  data
    ? data?.map(({ address, pccId, name, isActive, adminEmails }) => ({
        id: pccId,
        careHomeName: name,
        city: address?.city,
        prov: address?.province,
        country: address?.country,
        invite: "Resend Invite",
        status: isActive,
        adminEmails,
      }))
    : [];

export const announcementDataTransformer = (data) =>
  data
    ? data?.map((item) => ({
        ...item,
        announcements: item?.title || "",
        description: item?.description || "",
        tags: item?.tags || [],
        date: toLocalDate(item?.createdAt || ""),
        time: getTime(item?.createdAt || ""),
      }))
    : [];

export const announcementRowDataTransformer = (data) => ({
  ...data,
  title: data?.title || "",
  description: data?.description || "",
  tags:
    data?.tags.map((item) => ({
      label: item,
      value: item,
    })) || "",
});

export const eventDataTransformer = (data) =>
  data
    ? data?.map((item) => ({
        ...item,
        events: item?.name,
        action: false,
        date: toLocalDate(item?.startDate),
        time: getTime(item?.startDate),
      }))
    : [];

export const eventRowDataTransformer = (data) => ({
  venue: data?.venue || "",
  eventName: data?.name || "",
  startDate: toLocalDate(data?.startDate) || "",
  endDate: toLocalDate(data?.endDate) || "",
  startTime: getTime(data?.startDate) || "",
  endTime: getTime(data?.endDate) || "",
  description: data?.description || "",
});

export const toLocalDate = (date, type = "long") => {
  if (!date) return;

  const localDate = new Date(date)?.toLocaleString("en-CA")?.split("-");

  const month = {
    long: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    short: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  };

  return date
    ? `${month[type][+localDate[1] - 1]} ${localDate[2].split(",")[0]}, ${
        localDate[0]
      }`
    : "";
};

export const getTime = (initialDate) => {
  if (!initialDate) return;

  const date = new Date(initialDate)?.toLocaleString("en-CA");
  if (!date || initialDate === null) return "";

  const isSafari = () => {
    const userAgent = window.navigator.userAgent;
    return userAgent.includes("Safari") && !userAgent.includes("Chrome");
  };
  const getMeridiem = {
    "p.m": "PM",
    "a.m": "AM",
  };
  const [, timeString] = date.split(", ");
  const [hourString, minuteString, rest] = timeString.split(":");
  let hour = parseInt(hourString, 10);
  const minute = parseInt(minuteString, 10);
  const meridiemValue = rest?.split(" ")[1]?.slice(0, 3);

  const meridiem = isSafari()
    ? getMeridiem[meridiemValue?.toLowerCase()?.split("").join(".")]
    : getMeridiem[meridiemValue];
  hour = hour === 0 ? 12 : hour;
  const hour12 = hour > 12 ? hour - 12 : hour;

  return `${hour12.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")} ${meridiem}`;
};

export const toGMT = (date, time) => {
  if (date === null || time === null) return;

  const timeComponents = time?.trim()?.split(":");
  const hour = parseInt(timeComponents?.[0]);
  const minute = parseInt(timeComponents?.[1]?.split(" ")?.[0]);
  const isPm = timeComponents?.[1]?.split(" ")?.[1] === "PM";

  const finalTime = [
    isPm ? (hour === 12 ? 12 : hour + 12) : hour === 12 ? 0 : hour,
    minute,
  ];

  const dateComponents = date
    ?.trim()
    ?.split(" ")
    ?.map((item) => (item?.includes(",") ? item?.slice(0, -1) : item));

  const monthMap = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  const year = parseInt(dateComponents[2]);
  const month = monthMap[dateComponents[0].slice(0, 3)];
  const day = parseInt(dateComponents[1]);
  const finalDate = [year, month, day] as const;
  const utcDate = new Date(Date.UTC(...finalDate, ...finalTime, 0, 0));
  const offsetInMinutes = new Date(
    Date.UTC(...finalDate, ...finalTime, 0, 0),
  ).getTimezoneOffset();

  const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
  const gmtTime = new Date(utcDate.getTime() + offsetInMilliseconds);

  return gmtTime;
};

export const isAllDay = (from, to) => {
  const millisecondToHr = 2.77 * 10 ** -7;

  let fromDate = new Date(from);
  let toDate = new Date(to);

  const isDateEqual = fromDate?.getDate() === toDate?.getDate();

  let result = toDate?.getTime() - fromDate?.getTime();
  let hourDiff = result * millisecondToHr;

  return hourDiff > 23.9 ? isDateEqual : false;
};

export const appointmentDataTransformer = (data) =>
  data
    ? data?.map((item) => {
        const dataManager = (d1, d2) => d1 === d2;

        const startTime = getTime(item?.startTime);
        const endTime = getTime(item?.endTime);

        const startDate = toLocalDate(item?.startTime);
        const endDate = toLocalDate(item?.endTime);

        return {
          ...item,
          appointment: item?.title,
          residentName: `${item?.resident?.firstName} ${item?.resident?.lastName}`,
          visitorName: `${item?.appointer?.firstName} ${item?.appointer?.lastName}(${item?.noOfVisitors})`,
          action: true,
          dateTime: dataManager(startDate, endDate)
            ? `${startDate} ${startTime.split(" ")[0]} - ${endTime}`
            : `${startDate} ${startTime} - ${endDate} ${endTime}`,
        };
      })
    : [];

export const compareTime = (to, from) => {
  const [hour1, minute1, period1] = to?.match(/\d+|AM|PM/g) || "";
  const [hour2, minute2, period2] = from?.match(/\d+|AM|PM/g) || "";

  const convertTo24HourFormat = (hour, period) => {
    let convertedHour = Number(hour);
    if (period === "PM" && hour !== "12") {
      convertedHour += 12;
    } else if (period === "AM" && hour === "12") {
      convertedHour = 0;
    }
    return convertedHour;
  };

  const convertedHour1 = convertTo24HourFormat(hour1, period1);
  const convertedHour2 = convertTo24HourFormat(hour2, period2);

  if (convertedHour1 < convertedHour2) {
    return false;
  } else if (convertedHour1 > convertedHour2) {
    return true;
  } else if (minute1 <= minute2) {
    return false;
  } else if (minute1 > minute2) {
    return true;
  } else {
    return true;
  }
};

export const getDateDifference = (date1, date2) => {
  const diffInMilliseconds = Math.abs(date2 - date1);
  const diffInSeconds = diffInMilliseconds / 1000;
  const diffInMinutes = diffInSeconds / 60;
  const diffInHours = diffInMinutes / 60;
  const diffInDays = diffInHours / 24;

  return diffInDays;
};

export const getError = (name: string, errors: FieldErrors) => {
  if (!Object.keys(errors).length) return undefined;
  return name
    .split(".")
    .map((item) => item.replaceAll("[", "").replaceAll("]", ""))
    .reduce((prev, curr) => (prev ? prev[curr] : prev), errors);
};
