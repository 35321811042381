import dataProvider from "dataProvider";
import {
  AllAppointments,
  ForgotPassword,
  GetMembers,
  MarkSlots,
  MemberStatusToggle,
  ProfileUpdate,
  ResetPasswordType,
  ResidentInfo,
  ResidentInvite,
  ResidentStatusToggle,
  SendInvite,
  SignInData,
  SignOutData,
  SlotsAnalytics,
  StatusToggle,
  UnavailableSlots,
  UpdateSlots,
} from "types/api";
import { getTrimmedData } from "utils/helper";

let fetcher = dataProvider("adminInstance");

export const signIn = async (data: SignInData) => {
  return fetcher.post("/user/sign-in", data);
};

export const forgotPassword = async (data: ForgotPassword) => {
  return fetcher.post("/user/forgot-password", data);
};

export const resetPassword = async (data: ResetPasswordType) => {
  return fetcher.put("/user/reset-password", data);
};

export const signOut = async (data: SignOutData) => {
  return fetcher.post("/user/sign-out", data);
};

export const getCareHome = async ({
  page,
  search,
}: {
  page: number;
  search: string;
}) => {
  return fetcher.get(
    `care-home?page=${page}&perPage=10 ${search ? `&search=${search}` : ``}`,
  );
};

export const getCareHomeByPcc = async (pccId: string, orgId: string) => {
  return fetcher.get(`pcc/${pccId}?orgId=${orgId}`);
};

export const getResidence = async (pccId: string, orgId: string) => {
  return fetcher.get(`/pcc/${pccId}/resident?orgId=${orgId}`);
};

export const markResidentNotNew = async (id: string) => {
  return fetcher.put(`residents/${id}/markAsNotNew`, {});
};

export const getCareHomeByPccLocal = async (pccId: string) => {
  return fetcher.get(`/care-home/${pccId}`);
};

export const sendInvite = async ({ pccId, data }: SendInvite) => {
  return fetcher.post(`/invite/admins/send-invite`, {
    ...data,
    careHomeId: pccId,
  });
};

export const statusToggle = async (data: StatusToggle) => {
  return fetcher.put(`care-home/${data.id}/status`, { isActive: !data.status });
};

export const validateToken = async ({ token }: { token: string }) => {
  return fetcher.post(`user/validate-reset-token`, { token });
};

export const addCareHome = async (pccId: string, orgId: string) => {
  return fetcher.post(`care-home/${pccId}?orgId=${orgId}`, {});
};

export const getAllResidents = async ({
  pccId,
  page,
  search,
}: {
  pccId: string;
  page: number;
  search: string;
}) => {
  return fetcher.get(
    `residents?careHomeId=${pccId}&perPage=10&page=${page}${
      !!search ? `&search=${search}` : ``
    }`,
  );
};

export const getResident = async ({ pccId, gerryId }: ResidentInfo) => {
  return fetcher.get(`residents/${gerryId}`);
};

export const getAllAdmins = async (pccId: string) => {
  return fetcher.get(`care-home/${pccId}/admins`);
};

export const getResidentInfo = async (pccId: string) => {
  return fetcher.get(`care-home/${pccId}/residents/status`);
};

export const getCareHomeInfo = async (pccId: string) => {
  return fetcher.get(`care-home/${pccId}/additional-info`);
};

export const updateResidentStatus = async ({
  pccId,
  id,
  isActive,
}: ResidentStatusToggle) => {
  return fetcher.put(`residents/${id}/status`, {
    isActive: !isActive,
  });
};

export const getMember = async ({
  gerryId,
  type,
  page = 1,
  sortBy = "firstName",
  order = 0,
  prePage = 1,
}: GetMembers) => {
  return fetcher.get(
    `members?residentId=${gerryId}&type=${
      type === "primary"
    }&page=${page}&perPage=${prePage}&sortBy=${sortBy}&order=${order}`,
  );
};

export const sendInviteResident = async (data: ResidentInvite) => {
  return fetcher.post(`/invite/member`, data);
};

export const updateMemberStatus = async ({
  pccId,
  gerryId,
  id,
  isActive,
}: MemberStatusToggle) => {
  return fetcher.put(`members/${id}/status`, {
    status: !isActive,
  });
};

export const getAllAnnouncement = async ({
  pccId,
  page,
  sortBy = "announcements",
  order = 0,
}: {
  pccId: string;
  page: number;
  sortBy?: string;
  order?: number;
}) => {
  return fetcher.get(
    `/announcements?careHomeId=${pccId}&page=${page}&perPage=10&sort_by=${sortBy}&order=${order}`,
  );
};

export const getAllEvents = async ({
  pccId,
  page,
  sortBy = "venue",
  order = 0,
}: {
  pccId: string;
  page: number;
  sortBy?: string;
  order?: number;
}) => {
  return fetcher.get(
    `/events?careHomeId=${pccId}&page=${page}&perPage=10&sort_by=${sortBy}&order=${order}`,
  );
};

export const addNewAnnouncement = async (data) => {
  return fetcher.post(`announcements`, data);
};

export const updateAnnouncementById = async ({
  announcementId,
  updatedData,
}: {
  announcementId: string;
  updatedData: any;
}) => {
  return fetcher.put(`announcements/${announcementId}`, updatedData);
};

export const deleteAnnouncementId = async ({
  announcementId,
}: {
  announcementId: string;
}) => {
  return fetcher.delete(`announcements/${announcementId}`);
};

export const getEventById = async ({ eventId }: { eventId: string }) => {
  return fetcher.get(`events/${eventId}`);
};

export const addEvent = async (data) => {
  return fetcher.post(`events`, getTrimmedData(data));
};

export const updateEvent = async ({
  eventId,
  updatedData,
}: {
  eventId: string;
  updatedData: any;
}) => {
  return fetcher.put(`events/${eventId}`, getTrimmedData(updatedData));
};

export const deleteEventById = async ({ eventId }: { eventId: string }) => {
  return fetcher.delete(`events/${eventId}`);
};

export const updateProfile = async ({ id, data }: ProfileUpdate) => {
  return fetcher.put(`user/${id}`, {
    ...data,
  });
};

export const changePassword = async (data: { newPassword: string }) => {
  return fetcher.post("user/create-new-password", data);
};

export const getAllAppointments = async ({
  pccId,
  page,
  from,
  to,
  residentId,
  search,
}: AllAppointments) => {
  return fetcher.get(
    `/appointments?careHomeId=${pccId}&page=${page}&perPage=10${
      residentId ? `&residentId=${residentId}` : ``
    }${to ? `&to=${to}` : ``}${from ? `&from=${from}` : ``}${
      search ? `&search=${search}` : ``
    }`,
  );
};

export const deleteAppointmentById = async ({
  appointmentId,
  reason,
}: {
  appointmentId: string;
  reason: string;
}) => {
  return fetcher.delete(`appointments/${appointmentId}`, {}, { reason });
};

export const getUnavailableSlots = async ({
  pccId,
  from,
  to,
}: UnavailableSlots) => {
  return fetcher.get(
    `/appointments/unavailable-slots?careHomeId=${pccId}&to=${to}&from=${from}`,
  );
};

export const getSlotsAnalytics = async ({
  pccId,
  month,
  year,
}: SlotsAnalytics) => {
  return fetcher.get(
    `/appointments/unavailable-slots/analytics?careHomeId=${pccId}&month=${month}&year=${year}&timeZone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }`,
  );
};

export const updateUnavailableSlot = async ({
  id,
  reason,
  startTime,
  endTime,
}: UpdateSlots) => {
  return fetcher.put(`/appointments/unavailable-slots/${id}`, {
    reason,
    startTime,
    endTime,
  });
};

export const markSlot = async ({
  careHomeId,
  reason,
  startTime,
  endTime,
}: MarkSlots) => {
  return fetcher.post("/appointments/unavailable-slots", {
    careHomeId,
    reason,
    startTime,
    endTime,
  });
};

export const getReflections = async ({
  from,
  to,
}: {
  from: string;
  to: string;
}) => {
  return fetcher.get(`/reports/reflections?from=${from}&to=${to}`);
};

export const getContactUs = async ({
  from,
  to,
}: {
  from: string;
  to: string;
}) => {
  return fetcher.get(`/reports/contact-us?from=${from}&to=${to}`);
};

export const getFamilyMember = async ({
  from,
  to,
}: {
  from: string;
  to: string;
}) => {
  return fetcher.get(`/reports/family-member?from=${from}&to=${to}`);
};

export const markAvailable = async ({ id }: { id: string }) => {
  return fetcher.delete(`/appointments/unavailable-slots/${id}`, {});
};

export const uploadCareHomeWithoutPcc = async (data) => {
  return fetcher.post("/care-home", data);
};

export const getLatLong = async (zip: string) => {
  return fetcher.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
  );
};

export const getTimeZone = async (latLongData: string) => {
  return fetcher.get(
    `https://maps.googleapis.com/maps/api/timezone/json?location=${latLongData}&timestamp=${Math.floor(
      Date.now() / 1000,
    )}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
  );
};

export const addResident = async (data: {
  careHomeId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  isActive: boolean;
  members: {
    firstName: string;
    lastName: string;
    email?: string;
  }[];
}) => {
  return fetcher.post("/residents", data);
};

export const getNotVerifiedResidents = async ({
  page,
  careHomeId,
  search,
}: {
  page: number;
  careHomeId: string;
  search: string;
}) => {
  return fetcher.get(
    `residents?perPage=10&page=${page}&careHomeId=${careHomeId}&isVerified=false${
      !!search ? `&search=${search}` : ``
    }`,
  );
};

export const getEmailVerified = async (email: string) => {
  return fetcher.get(`user/email-unique?email=${email}`);
};

export const markAllResidentsVerified = async (careHomeId: string) => {
  return fetcher.patch(
    `residents/mark-resident-verified?careHomeId=${careHomeId}`,
    { data: undefined },
  );
};

export const getAllMembersData = async (residentId: string) => {
  return fetcher.get(`members?page=1&residentId=${residentId}`);
};

export const updateResident = async (data: {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
  gender?: string;
}) => {
  const { id, ...restData } = data;
  return fetcher.patch(`residents/${data?.id}`, restData);
};

export const updateMembers = async (data: {
  id: string;
  firstName: string;
  lastName: string;
}) => {
  const { id, ...restData } = data;
  return fetcher.patch(`members/${id}`, restData);
};

export const uploadFile = async (data) => {
  const formData = new FormData();
  formData.append("residents", data?.file[0]);

  return await fetcher.post(
    `/residents/upload-residents?careHomeId=${data?.careHomeId}`,
    formData,
    {},
    data?.onProgress,
  );
};

export const addMember = async (data: {
  residentId?: string;
  isPrimary?: boolean;
  firstName: string;
  lastName: string;
  email?: string;
}) => {
  return fetcher.post("/members", data);
};

export const deleteMember = async (memberId: string) => {
  return fetcher.delete(`/members/${memberId}`);
};

export const changePrimaryMember = async (data: {
  residentId: string;
  newPrimaryId: string;
}) => {
  return fetcher.patch(`/members/change-primary-member`, data);
};

export const fetchResidentMember = async (residentId: string) => {
  return fetcher.post(`/members/pcc?residentId=${residentId}`, {});
};
