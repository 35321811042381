import MarkUnavailable from "./MarkUnavailable";
import Cancel from "./Cancel";

const FormTypeMapping = {
  MarkUnavailable,
  Cancel,
} as const;

type FormTypes = keyof typeof FormTypeMapping;

type FormOwnProps<T extends FormTypes> = {
  as?: T;
};

type FormProps<T extends FormTypes> = FormOwnProps<T> &
  React.ComponentProps<typeof FormTypeMapping[T]>;

const defaultFormType = "MarkUnavailable";

const Form = <T extends FormTypes = typeof defaultFormType>({
  as,
  ...rest
}: FormProps<T>) => {
  const FormType =
    (as && FormTypeMapping[as]) ?? FormTypeMapping[defaultFormType];
  return <FormType {...rest} />;
};

export default Form;
