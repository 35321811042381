import { Box } from "@mui/material";
import Button from "components/common/Button";
import { Input, Password } from "components/common/FormComponents";
import errorMessage from "constants/errorMessage";
import { route } from "constants/routes";
import _ from "lodash";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { loginUser } from "redux/actions";
import { setSignInEmail } from "redux/slices/user";
import { useAppSelector } from "redux/store";
import { SignInData } from "types/api";
import { signIn } from "utils/api/user";
import { getTrimmedData } from "utils/helper";
import regex from "utils/regex";
import styles from "./styles";

export type SignInFormData = {
  email: string;
  password: string;
};

const SignIn = () => {
  const { signInEmail } = useAppSelector((state) => state.user);

  const { control, formState, handleSubmit, watch, setError } =
    useForm<SignInFormData>({
      defaultValues: {
        email: useMemo(() => signInEmail, [signInEmail]),
        password: "",
      },
      mode: "all",
    });

  const { isValid } = formState;

  const history = useHistory();
  const dispatch = useDispatch();

  const adminLogin = useMutation("signIn", (data: SignInData) => signIn(data), {
    onSuccess: (res) => {
      dispatch(loginUser(res.data.data));
      history.replace("/");
    },
    onError: (err) => {
      if (err["response"].data.errorCode === "MYG_ERR_LOC002") {
        setError("email", { message: "" });
        setError("password", {
          message: err["response"].data.message,
        });
      } else {
        setError("email", { message: "" });
        setError("password", {
          message: errorMessage.inValidCredentials,
        });
      }
      dispatch(loginUser(""));
    },
  });

  const onSubmit = (data: SignInFormData) => {
    adminLogin.mutate(getTrimmedData(data));
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.signIn}>Sign In</Box>
      <Box sx={styles.heading}>
        Welcome! Please Sign In with your username and password.
      </Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="email"
          label="Username"
          control={control}
          errors={formState.errors}
          customStyles={styles.input}
          rules={{
            required: errorMessage.required,
            pattern: {
              value: regex.email,
              message: errorMessage.invalidEmail,
            },
          }}
        />
        <Password
          name="password"
          label="Password"
          errors={formState.errors}
          control={control}
          customStyles={styles.input}
          rules={{
            required: errorMessage.required,
          }}
        />
        <Button
          type="submit"
          label="Sign In"
          disabled={!(isValid && _.isEmpty(formState.errors))}
          customStyles={styles.signInBtn}
        />
      </Box>
      <Button
        as="FormButton"
        label="Forgot Password?"
        onClick={() => {
          dispatch(setSignInEmail(watch("email")));
          history.push(route.forgotPassword.path);
        }}
      />
    </Box>
  );
};

export default SignIn;
