import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Divider } from "@mui/material";
import Button from "components/common/Button";
import { Date as DateComp, Time } from "components/common/FormComponents";
import CheckBox from "components/common/FormComponents/Checkbox";
import PopUp from "components/common/PopUp";
import { ModalData } from "components/feature/ManageHome";
import errorMessage from "constants/errorMessage";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Calendar } from "react-multi-date-picker";
import { useMutation, useQuery } from "react-query";
import { useAppSelector } from "redux/store";
import {
  getSlotsAnalytics,
  getUnavailableSlots,
  markSlot,
  updateUnavailableSlot,
  markAvailable,
} from "utils/api/user";
import { getTime, isAllDay, toGMT, toLocalDate } from "utils/common";
import { FormType } from "../..";
import styles from "./styles";

export type MarkUnavailableProps = {
  submitBtnLabel: string;
  rowData: any;
  setForm: React.Dispatch<React.SetStateAction<FormType>>;
  setLocalForm?: any;
  localForm?: any;
  setSlotsCount?: any;
  slotCount?: any;
};

export type MarkUnavailableData = {
  formDate: string;
  toDate: string;
  formTime: string;
  toTime: string;
};

const CustomButton = ({ direction, handleClick, disabled }: any) => {
  return (
    <>
      {direction !== "right" ? (
        <ArrowBackIosIcon
          sx={styles.arrowStyles}
          onClick={handleClick}
          fontSize="small"
        />
      ) : (
        <ArrowForwardIosIcon
          sx={styles.arrowStyles}
          onClick={handleClick}
          fontSize="small"
        />
      )}
    </>
  );
};

const MarkUnavailable = ({
  submitBtnLabel,
  rowData,
  setForm,
  localForm,
  setLocalForm,
  setSlotsCount,
  slotCount,
}: MarkUnavailableProps) => {
  const [openModal, setModal] = useState<boolean>(false);
  const [checked, setChecked] = useState(false);
  const [id, setId] = useState("");
  const [calendarVal, setCalendarVal] = useState(new Date());

  const currentDate = `${new Date()}`.split(" ");
  const todayDate = `${currentDate[1]} ${currentDate[2]}, ${currentDate[3]}`;

  const [monthYear, setMonthYear] = useState<{
    month: string | number | null;
    year: string | number | null;
  }>({ month: currentDate[1], year: currentDate[3] });

  const [calendarManager, setCalendarManager] = useState({
    [monthYear.year]: {
      [monthYear.month]: {
        1: [],
        2: [],
      },
    },
  });

  const [from, setFrom] = useState(toGMT(todayDate, "12:00 AM").toISOString());
  const [to, setTo] = useState(toGMT(todayDate, "11:59 PM").toISOString());

  const {
    pccInfo: { pccId },
  } = useAppSelector((state) => state);

  const startTime = "12:00 AM";
  const endTime = "11:59 PM";

  const { data: slotsInfo, refetch: refetchSlot } = useQuery(
    ["getUnavailableSlot", pccId, from, to, monthYear],
    () => getUnavailableSlots({ pccId, from, to }),
    {
      onSuccess: (data) => {
        setSlotsCount(data?.data?.data?.length);
      },
    },
  );

  const { data: analystInfo, refetch: refetchAnalystInfo } = useQuery(
    ["getSlotAnalytics", monthYear, pccId],
    () =>
      getSlotsAnalytics({
        pccId,
        ...monthYear,
      }),
    {},
  );

  let tempCalInfo = {
    [monthYear.year]: {
      [monthYear.month]: {
        1: [],
        2: [],
      },
    },
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    tempCalInfo = {
      [monthYear.year]: {
        [monthYear.month]: {
          1: [],
          2: [],
        },
      },
    };

    analystInfo?.data?.data?.forEach((item) => {
      item.status === "FULL"
        ? tempCalInfo[monthYear.year]?.[monthYear.month]?.[2].push(item.day)
        : item.status === "PARTIAL" &&
          tempCalInfo[monthYear.year]?.[monthYear.month]?.[1].push(item.day);
    });
    setCalendarManager(tempCalInfo);
  }, [monthYear, analystInfo]);

  const { mutate: updateSlot } = useMutation(
    "updateSlots",
    (data: {
      id: string;
      reason?: string;
      startTime: string;
      endTime: string;
    }) => updateUnavailableSlot(data),
    {
      onSuccess: () => {
        refetchAnalystInfo();
        refetchSlot();
        setLocalForm(false);
        setId("");
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setError("formTime", { message: "" });
          setError("toTime", { message: err["response"].data.message });
        }
      },
      retry: 1,
    },
  );

  const { mutate: markSlots } = useMutation(
    "markSlot",
    (data: {
      careHomeId: string;
      reason?: string;
      startTime: string;
      endTime: string;
    }) => markSlot(data),
    {
      onSuccess: () => {
        refetchAnalystInfo();
        refetchSlot();
        setLocalForm(false);
        setId("");
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setError("formTime", { message: "" });
          setError("toTime", { message: err["response"].data.message });
        }
      },
      retry: 1,
    },
  );

  const { mutate: markSlotAvailable } = useMutation(
    "markSlot",
    (data: { id: string }) => markAvailable(data),
    {
      onSuccess: () => {
        refetchAnalystInfo();
        refetchSlot();
        setLocalForm(false);
        setId("");
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setError("formTime", { message: "" });
          setError("toTime", { message: err["response"].data.message });
        }
      },
      retry: 1,
    },
  );

  useEffect(() => {
    const date = new Date()?.toISOString()?.split("-");

    setMonthYear({
      month: +date[1],
      year: +date[0],
    });
  }, []);

  const {
    control,
    watch,
    setValue,
    setError,
    getValues,
    clearErrors,
    reset,
    formState: { errors, isValid },
  } = useForm<MarkUnavailableData>({
    mode: "onChange",
    defaultValues: { formDate: "", toDate: "", formTime: "", toTime: "" },
  });

  useEffect(() => {
    if (checked) {
      setValue("formTime", "12:00 AM");
      setValue("toTime", "11:59 PM");
    }
  }, [checked, setValue]);

  useEffect(() => {
    reset({
      formDate: todayDate,
      toDate: todayDate,
      formTime: "12:00 AM",
      toTime: "11:59 PM",
    });
  }, []);

  useEffect(() => {
    if (slotsInfo?.data?.data?.length === 0) {
      setLocalForm(true);
    } else {
      setLocalForm(false);
    }
  }, [slotsInfo?.data?.data?.length, monthYear, slotsInfo, setLocalForm]);

  useEffect(() => {
    if (!localForm) {
      setId("");
    }
  }, [localForm]);

  const handleClose = () => {
    setModalData(null);
    setModal(false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleEdit = (id) => {
    setId(id);
    const currentItemData = slotsInfo?.data?.data?.find(
      (item) => item?.id === id,
    );
    reset({
      formDate: toLocalDate(currentItemData?.startTime),
      toDate: toLocalDate(currentItemData?.endTime),
      formTime: getTime(currentItemData?.startTime),
      toTime: getTime(currentItemData?.endTime),
    });
    setLocalForm(true);
  };

  const cancelModal: ModalData = {
    heading: "Cancel Appointment",
    actionLeft: {
      label: "Cancel",
      onClick: handleClose,
    },
    actionRight: {
      label: "Yes",
      type: "submit",
    },
  };

  const [modalData, setModalData] = useState(cancelModal);

  const slotsDataTransformer = (data) =>
    data
      ? data?.map((item) => ({
          id: item?.id,
          from: item?.startTime,
          to: item?.endTime,
        }))
      : [];

  return (
    <Box>
      <PopUp
        open={!!openModal}
        handleClose={handleClose}
        heading={modalData?.heading}
        actionLeft={modalData?.actionLeft}
        actionRight={modalData?.actionRight}
        loading={false}
        customStyles={{
          button: styles.popoverCustomButton,
        }}
      >
        {openModal && <>Some modal</>}
      </PopUp>

      <form>
        <Box sx={styles.submitWrapper}>
          {slotsInfo?.data?.data?.length !== 0 && localForm && id !== "" && (
            <Button
              label="Mark Available"
              sx={styles.submitBtn}
              variant="contained"
              icon={false}
              onClick={() => {
                markSlotAvailable({ id });
              }}
            />
          )}
          <Button
            label={localForm ? "Save" : "Mark Unavailable"}
            sx={styles.submitBtn}
            variant="contained"
            icon={false}
            disabled={localForm ? !isValid : false}
            onClick={() => {
              const { formDate, formTime, toDate, toTime } = getValues();
              if (localForm) {
                if (id !== "") {
                  updateSlot({
                    id,
                    endTime: toGMT(toDate, toTime).toISOString(),
                    startTime: toGMT(formDate, formTime).toISOString(),
                  });
                } else {
                  markSlots({
                    careHomeId: pccId,
                    endTime: toGMT(toDate, toTime).toISOString(),
                    startTime: toGMT(formDate, formTime).toISOString(),
                  });
                }
              } else {
                setLocalForm(true);
              }
            }}
          />
        </Box>
        <Box sx={styles.wrapper}>
          <Box sx={styles.calendarWrapper}>
            <Calendar
              onMonthChange={(data) => {
                setMonthYear({
                  month: data?.month?.index + 1,
                  year: data?.year,
                });
              }}
              onYearChange={(data) => {
                setMonthYear({ month: data?.month?.index, year: data?.year });
              }}
              mapDays={({ date, today }: any) => {
                const { month, year } = date;

                const commonStyles = {
                  borderRadius: 0,
                  margin: "-3px",
                };
                if (
                  calendarManager?.[monthYear.year]?.[
                    monthYear.month
                  ]?.[1]?.includes(date.day)
                ) {
                  return {
                    style: {
                      backgroundColor: "rgb(204,204,204)",
                      background:
                        "linear-gradient(138deg, rgba(204,204,204,1) 50%, rgba(255,255,255,0.9051995798319328) 50%",
                      ...commonStyles,
                    },
                  };
                }
                if (
                  calendarManager?.[monthYear.year]?.[
                    monthYear.month
                  ]?.[2]?.includes(date.day)
                ) {
                  return {
                    style: {
                      backgroundColor: "#cccccc",
                      ...commonStyles,
                    },
                  };
                }
              }}
              format="MM/DD/YYYY HH:mm:ss"
              renderButton={<CustomButton />}
              onChange={(data) => {
                const reqDate = data[(data as [])?.length - 1] || data;
                const formattedDate = `${reqDate?.month?.name} ${reqDate?.day}, ${reqDate?.year}`;

                setChecked(false);
                setTo(toGMT(formattedDate, "11:59 PM").toISOString());
                setFrom(toGMT(formattedDate, "12:00 AM").toISOString());

                reset({
                  formDate: formattedDate,
                  toDate: formattedDate,
                  formTime: startTime,
                  toTime: endTime,
                });
              }}
              value={calendarVal}
              minDate={new Date()}
            />
          </Box>
          <Box sx={styles.formWrapper}>
            {localForm ? (
              <Box sx={styles.fieldWrapper}>
                <Box sx={styles.dateWrapper}>
                  <DateComp
                    name="formDate"
                    control={control}
                    type="date"
                    label="From"
                    labelPos
                    className="date"
                    minDate={watch().formDate}
                    errors={errors}
                    fullWidth
                    setValue={setValue}
                    rules={{ required: errorMessage.required }}
                    customStyles={{
                      input: styles.dateSelector,
                    }}
                  />
                  <DateComp
                    name="toDate"
                    control={control}
                    type="date"
                    label="To"
                    labelPos
                    className="date"
                    minDate={watch().toDate}
                    errors={errors}
                    rules={{ required: errorMessage.required }}
                    fullWidth
                    setValue={setValue}
                    customStyles={{
                      input: styles.dateSelector,
                    }}
                  />
                </Box>
                <Divider sx={styles.dividerV1} color="#82b7f3" />
                <CheckBox
                  label="All Day"
                  checked={checked}
                  handleChange={handleCheckboxChange}
                />
                <Divider sx={styles.dividerV2} color="#82b7f3" />
                <Time
                  name="formTime"
                  label="From"
                  control={control}
                  labelPos
                  getValues={getValues}
                  setValue={setValue}
                  clearErrors={clearErrors}
                />
                <Time
                  name="toTime"
                  label="To"
                  control={control}
                  labelPos
                  getValues={getValues}
                  setValue={setValue}
                  clearErrors={clearErrors}
                />
              </Box>
            ) : (
              <Box sx={styles.unAvailableSlotWrapper}>
                All Block off time:
                <Divider sx={styles.dividerV1} color="#82b7f3" />
                <Box sx={styles.listWrapper}>
                  {slotsInfo?.data?.data?.length ? (
                    slotsDataTransformer(slotsInfo?.data?.data)?.map(
                      ({ id, to, from }, idx) => {
                        const allDay = isAllDay(from, to);

                        const fromToTime = allDay
                          ? `All day`
                          : `${getTime(from)} - ${getTime(to)}`;
                        return (
                          <React.Fragment key={id}>
                            <Box sx={styles.dataAndTimeWrapper}>
                              <Box sx={styles.typo}>
                                {`${toLocalDate(from)} - ${fromToTime}`}
                              </Box>
                              <Box
                                sx={styles.editWrapper}
                                onClick={() => handleEdit(id)}
                              >
                                Edit
                              </Box>
                            </Box>
                            {idx !== slotsInfo?.data?.data?.length - 1 && (
                              <Divider sx={styles.dividerV5} color="#82b7f3" />
                            )}
                          </React.Fragment>
                        );
                      },
                    )
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "15px",
                      }}
                    >
                      No Block off time
                    </Box>
                  )}
                </Box>
                <Divider sx={styles.dividerV4} color="#82b7f3" />
              </Box>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default MarkUnavailable;
