import { Box, FormHelperText } from "@mui/material";
import { MenuItem, TextField, TextFieldProps } from "@mui/material/";
import { SxProps } from "@mui/system";
import {
  Controller,
  FieldErrors,
  FieldPathValue,
  FieldValues,
  Path,
  UseControllerProps,
} from "react-hook-form";
import { getError } from "utils/common";
import styles from "./styles";
import { InputLabel } from "@mui/material";

type SelectProps<T> = UseControllerProps<T> &
  TextFieldProps & {
    errors?: FieldErrors;
    options: { label: string; value: string }[];
    customStyle?: { [key: string]: SxProps };
    disabled?: boolean;
    defaultValue?: FieldPathValue<FieldValues, any> | string;
  };

const Select = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth = true,
  errors,
  customStyle,
  variant = "outlined",
  options,
  defaultValue = "",
  disabled,
  rules,
  ...rest
}: SelectProps<T>) => {
  const error = getError(name, errors);

  return (
    <Box>
      <InputLabel
        shrink
        className="label"
        disabled={disabled}
        sx={{ ...styles.label, ...customStyle?.input } as SxProps}
        required={!!rules?.required}
        htmlFor={name}
      >
        {label}
      </InputLabel>

      <Controller
        render={({ field }) => (
          <Box sx={{ ...styles.wrapper, ...customStyle?.wrapper } as SxProps}>
            <TextField
              sx={{ ...customStyle?.input }}
              select
              variant={variant}
              fullWidth={fullWidth}
              value={field.value}
              onChange={field.onChange}
              inputRef={field.ref}
              onBlur={field.onBlur}
              disabled={disabled}
              {...rest}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
        name={name as Path<T>}
        control={control}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && (
        <FormHelperText sx={styles.error}>{error.message}</FormHelperText>
      )}
    </Box>
  );
};
export default Select;
