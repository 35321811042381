import { Styles } from "types/common";

const styles: Styles = {
  mainWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "132px",
    "& .MuiInputBase-root": {
      minWidth: "248px",
    },
  },

  wrapper: {
    // position: "relative",
    display: "flex",
    padding: "0 30px",
    flexDirection: "column",
    margin: "0 auto",
    justifyContent: "space-between",
    width: "583px",
  },

  profile: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "custom.text.secondary",
    marginBottom: "40px",
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },

  fieldWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "18px",
  },

  role: {
    width: "100%",
  },

  input: {
    "& .label": {
      position: "absolute",
    },
  },

  changePassword: {
    textDecoration: "underline",
    fontSize: "14px",
    fontWeight: "bold",
    color: "primary.main",
    cursor: "pointer",
  },

  edit: {
    display: "flex",
    justifyContent: "center",
    width: "120px",
    margin: "0 auto",
    height: "40px",
    marginTop: "62px",
    backgroundColor: "primary.dark",
  },

  editButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "62px",
    columnGap: "15px",
  },

  cancelButton: {
    width: "120px",
    height: "40px",
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "custom.background.darkGray",
    color: "custom.text.secondary",
    "&:hover": {
      backgroundColor: "white",
    },
  },

  saveButton: {
    width: "120px",
    height: "40px",
    backgroundColor: "primary.dark",
  },
};

export default styles;
