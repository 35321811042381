import { Styles } from "types/common";

const styles: Styles = {
  viewAllWrapper: {
    background: "white",
    color: "custom.text.secondary",
    padding: "10px",
  },

  container: {
    height: "216px",
    overflowY: "auto",
    width: "98%",
    padding: "0 0 0 8px",
  },

  viewItemHead: {
    flexBasis: "40%",
    fontWeight: "bold",
    fontSize: "15px",
  },

  viewAll: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    lineHeight: "48px",
    fontSize: "14px",
    fontWeight: "500",
  },

  viewItem: {
    flexBasis: "40%",
    maxHeight: "48px",
    maxWidth: "175px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  emailTypo: {
    maxHeight: "48px",
    maxWidth: "175px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  noData: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default styles;
