import { FormControlProps, InputBaseProps } from "@mui/material";
import { Input } from "components/common/FormComponents";
import { FieldErrors, FieldValues, UseControllerProps } from "react-hook-form";
import styles from "./styles";

type SimpleInputProps<T> = UseControllerProps<T> &
  FormControlProps &
  InputBaseProps & {
    label: string;
    placeholder: string;
    errors?: FieldErrors;
    isValid?: boolean;
    showAdornment?: boolean;
    autoFocus?: boolean;
    textArea?: boolean;
  };

const SimpleInput = <T extends FieldValues>({
  label,
  placeholder,
  name,
  control,
  errors,
  rules,
  autoFocus = true,
  isValid = false,
  showAdornment = false,
  textArea = false,
  ...rest
}: SimpleInputProps<T>) => {
  return (
    <Input
      name={name}
      label={label}
      placeholder={placeholder}
      control={control}
      errors={errors}
      fullWidth
      showAdornment={showAdornment}
      isValid={isValid}
      customStyles={{
        ...styles.input,
        "& .MuiInputBase-root": {
          width: "100%",
          marginTop: "4px",
          height: textArea ? "auto" : "40px",
        },
      }}
      adornmentPos="end"
      autoFocus={autoFocus}
      rules={rules}
      {...rest}
    />
  );
};

export default SimpleInput;
