import { SxProps } from "@mui/material";

const commonBtnStyles: SxProps = {
  fontSize: "14px",
  height: "40px",
  backgroundColor: "primary.dark",
  "&:disabled": {
    backgroundColor: "primary.light",
    color: "white",
  },
  minWidth: "184px",
};

const styles: { [key: string]: SxProps } = {
  heading: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#333333",
    margin: "24px 0 18px",
  },

  subHeadingWrapper: {
    display: "flex",
    gap: "14px",
    alignItem: "center",
  },

  bulletWrapper: {
    fontSize: "16px",
    fontWeight: 700,
    color: "white",
    height: "26px",
    width: "26px",
    borderRadius: "100px",
    backgroundColor: "#056EE6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  subHeading: {
    fontWeight: 500,
    fontSize: "15px",
    color: "black",
  },

  dividerStyles: {
    marginBottom: "10px",
  },

  dividerStylesV2: {
    margin: "28px 0 15px 0",
  },

  fieldsPrimaryWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "14px",
    "& .MuiInputBase-root": {
      marginTop: "0",
      fontWeight: "400 !important",
    },
    gap: "10px",
  },

  fieldWrapper: {
    width: "24%",
  },

  emailWrapper: {
    width: "49.5%",
    marginRight: "3px",
  },

  buttonStyles: {
    ...commonBtnStyles,
    position: "absolute",
    top: "260px",
    right: "30px",
  },

  subHeadingV2: {
    fontWeight: 500,
    fontSize: "15px",
    color: "black",
    marginTop: "28px",
    width: "500px",
  },

  delBtn: {
    cursor: "pointer",
    height: "14px",
    weight: "14px",
    marginTop: "20px",
  },

  familyMemberWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  },

  familyMemberAddBtn: {
    fontSize: "15px",
    fontWeight: 500,
    textDecoration: "underline",
    display: "flex",
    flexBasis: "100%",
    justifyContent: "flex-end",
    cursor: "pointer",
  },

  delBtnV2: {
    position: "absolute",
    cursor: "pointer",
    right: 0,
    top: "-5px",
  },

  footerBtnWrapper: {
    display: "flex",
    marginTop: "24px",
    width: "100%",
    justifyContent: "center",
    gap: "8px",
  },

  cancelBtnStyles: {
    ...commonBtnStyles,
    backgroundColor: "white",
    color: "black",
    minWidth: "120px",
    margin: "0",
  },

  saveBtnStyles: {
    ...commonBtnStyles,
    minWidth: "120px",
  },

  dividerStylesV3: {
    margin: "12px 0 0 0",
  },

  backWrapper: {
    position: "relative",
  },

  backArrow: {
    marginRight: "10px",
    cursor: "pointer",
  },

  backStyles: {
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    cursor: "pointer",
    color: "custom.background.dropdownCta",
    top: "10px",
    left: "10px",
    "&:after": {
      top: "20px",
      content: '""',
      display: "block",
      margin: "auto",
      height: "1.5px",
      width: "50px",
      background: "#056EE6",
      position: "absolute",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
      marginRight: "0",
    },
  },

  formWrapper: {
    padding: "30px",
    backgroundColor: "#F3F5F8",
    borderRadius: "5px",
  },

  dobWrapper: {
    fontSize: "15px",
    fontWeight: 700,
    display: "flex",
  },

  star: {
    marginLeft: "6px",
    marginTop: "-2px",
    color: "#c0291d",
  },

  infoWrapper: { marginTop: "52px" },

  backBtnWrapper: { margin: "0 30px" },

  secondarySectionWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0",
    gap: "10px",
  },

  addResidentsHeading: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#333333",
  },

  searchWrapper: {
    marginTop: "-20px",
    width: "66%",
  },

  anotherResidentBtnStyles: {
    fontSize: "12px",
    height: "28px",
    backgroundColor: "primary.dark",
    "&:disabled": {
      backgroundColor: "primary.light",
      color: "white",
    },
    p: "6px 0",
    minWidth: "152px",
  },

  familyDelBtn: {
    cursor: "pointer",
    height: "15.6px",
    weight: "18px",
    mt: "20px",
  },

  updateIconsWrapper: {
    display: "flex",
    alignItems: "center",
    pl: "12px",
    gap: "12px",
  },
};

export default styles;
