import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputBase,
  InputBaseProps,
  InputLabel,
} from "@mui/material";
import { SxProps } from "@mui/system";
import {
  Controller,
  FieldErrors,
  FieldValues,
  UseControllerProps,
  UseFormClearErrors,
} from "react-hook-form";
import { getError } from "utils/common";
import Calendar from "./Calendar";
import MaskedInput from "./MaskedInput";
import styles from "./styles";
import {
  MINIMUM_DATE,
  changeToDateFormat,
  changeToMaskedFormat,
  dateToString,
  getMaxDate,
  isLower,
  validateDate,
} from "./utils";

type InputProps<T> = UseControllerProps<T> &
  FormControlProps &
  InputBaseProps & {
    label: string;
    errors?: FieldErrors;
    additionalInfo?: string;
    maxDate?: Date;
    minDate?: Date;
    minAge?: number;
    customStyles?: SxProps;
    view?: boolean;
    loading?: boolean;
    disabled?: boolean;
    clearErrors: UseFormClearErrors<T>;
  };

const Input = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth = true,
  errors,
  rules,
  customStyles,
  additionalInfo,
  minDate = new Date(MINIMUM_DATE),
  minAge = 0,
  inputProps,
  variant = "standard",
  className,
  view = false,
  loading = false,
  disabled = false,
  clearErrors,
  ...rest
}: InputProps<T>) => {
  const error = getError(name, errors);
  const maxDate = getMaxDate(minAge);

  return (
    <Controller
      render={({ field }) => (
        <FormControl
          sx={customStyles}
          fullWidth={fullWidth}
          variant={variant}
          className={className}
        >
          <>
            <InputLabel
              shrink
              sx={{ ...styles.label }}
              className="label"
              required={!!rules?.required}
              htmlFor={`${name}`}
            >
              {label}
            </InputLabel>
            <InputBase
              id={`${name}`}
              value={changeToMaskedFormat(field.value)}
              onChange={(event) => {
                const val = changeToDateFormat(event.target.value);
                field.onChange(val);
                clearErrors(name);
              }}
              onBlur={(event) => {
                if (
                  validateDate(event.target.value) &&
                  !isLower(event.target.value, dateToString(maxDate))
                ) {
                  const val = dateToString(maxDate);
                  field.onChange(val);
                }
              }}
              inputProps={{
                ...inputProps,
                minDate,
                maxDate,
                date: new Date(field.value),
              }}
              error={!!error}
              inputComponent={MaskedInput as any}
              disabled={disabled}
              endAdornment={
                <Calendar
                  minDate={minDate}
                  disabled={disabled}
                  maxDate={maxDate}
                  field={field}
                />
              }
              {...rest}
            />
          </>

          {error && !view && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
      name={name}
      control={control}
      rules={rules}
      {...rest}
    />
  );
};
export default Input;
