import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Divider } from "@mui/material";
import Button from "components/common/Button";
import Info from "components/common/Info";
import PopUp from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import SearchBar from "components/common/SearchField";
import { route } from "constants/routes";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import { useAppSelector } from "redux/store";
import {
  addResident,
  getNotVerifiedResidents,
  markAllResidentsVerified,
} from "utils/api/user";
import { getGMTDate, getMaskedDate } from "utils/helper";
import Invite from "../ManageHome/Invite";
import { InfoModal } from "../ManageResidents";
import Resident from "./Resident";
import ResidentTable from "./ResidentTable";
import styles from "./styles";

export type ResidentTableProps = {
  careHomeId?: string;
  dateOfBirth: string;
  firstName: string;
  gender?: string;
  lastName: string;
  gerryId: string;
  residentName: string;
  id: string;
  isActive: boolean;
  primaryContact: string;
  primaryMember: {
    contactId?: string;
    dateOfBirth?: string;
    email: string;
    firstName: string;
    gender?: string;
    lastName: string;
    invitationToken?: string | null;
    onBoarded?: boolean;
  }[];
}[];

export type ResidentDetails = {
  residents: {
    firstName: string;
    lastName: string;
    gender: string;
    dob: string;
    id: string;
    primaryContact: {
      firstName: string;
      lastName: string;
      email: string;
      id: string;
      isPrimary?: boolean;
    };
    familyMembers: {
      firstName: string;
      lastName: string;
      email: string;
      id: string;
      isPrimary?: boolean;
    }[];
  };
};

export const defaultValue: ResidentDetails["residents"] = {
  firstName: "",
  lastName: "",
  gender: "",
  dob: "",
  id: "",
  primaryContact: {
    firstName: "",
    lastName: "",
    email: "",
    id: "",
    isPrimary: true,
  },
  familyMembers: [
    {
      firstName: "",
      lastName: "",
      email: "",
      id: "",
      isPrimary: false,
    },
  ],
};

const Form = () => {
  const [openModal, setModal] = useState<"cancel" | "invite" | "">("");
  const [formVisibility, setFormVisibility] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [residentTable, setResidentTable] = useState<ResidentTableProps>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const {
    user: { globalInfoData, mrModalView, careHomeRowData },
  } = useAppSelector((state) => state);

  let refBtn = useRef(null);

  let clickBtn = () => {
    refBtn.current.click();
  };

  const cancelAddResident: any = {
    heading: "Are you sure you wish to cancel adding residents?",
    props: {
      description:
        "If you proceed, the details you have entered will not be saved.",
    },
    actionLeft: {
      label: "Cancel",
      onClick: () => console.log("actionLeft"),
    },
    actionRight: {
      label: "Yes",
      type: "submit",
    },
  };

  const history = useHistory();

  const modalData = cancelAddResident;
  const [totalData, setTotalData] = useState(0);

  const {
    control,
    formState: { errors, isValid },
    watch,
    handleSubmit,
    setValue,
    reset,
    setError,
    resetField,
    clearErrors,
  } = useForm<ResidentDetails>({
    mode: "onChange",
    defaultValues: {
      residents: defaultValue,
    },
  });

  const {
    data: getResidentsData,
    refetch,
    isLoading,
  } = useQuery(
    ["getResidents", search, page],
    () => getNotVerifiedResidents({ page, careHomeId, search }),
    {
      onSuccess: (res) => {
        setTotalData(res?.data?.metadata?.total);
      },
      onError: () => {},
      retry: 1,
    },
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (residentTable?.length > 0) {
      setFormVisibility(false);
    } else {
      setFormVisibility(true);
    }
  }, [residentTable]);

  useEffect(() => {
    if (getResidentsData) {
      setResidentTable(
        getResidentsData?.data?.data.map((item) => {
          return {
            ...item,
            residentName: `${item?.firstName} ${item?.lastName}`,
            primaryContact: item?.primaryMember?.[0]?.firstName
              ? `${item?.primaryMember?.[0]?.firstName} ${item?.primaryMember?.[0]?.lastName}`
              : "-",
          };
        }),
      );
    }
  }, [getResidentsData]);

  const careHomeId = careHomeRowData?.id;

  const { mutate: markResidentsVerifiedMutation } = useMutation(
    "markResidentsVerified",
    () => markAllResidentsVerified(careHomeId),
    {
      onSuccess: () => {
        history.push(route.manageResidents.path);
      },
      onError: () => {},
      retry: 1,
    },
  );

  const addResidentDataTransform = async (formData) => {
    return {
      careHomeId: careHomeId,
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      dateOfBirth: getGMTDate(formData.dob),

      gender: formData?.gender,
      isActive: true,

      members: [formData?.primaryContact, ...formData?.familyMembers]
        .filter((member) => {
          return member.firstName && member.lastName;
        })
        .map((member, index) => {
          const updatedMember = {
            ...member,
            isPrimary: index === 0,
          };
          //check if email is a string do not include it in the object
          if (member.email === "") {
            delete updatedMember.email;
          }
          return updatedMember;
        }),
    };
  };

  const { mutate: addResidentMutation } = useMutation(
    "addResident",
    (data: {
      careHomeId: string;
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      gender: string;
      isActive: boolean;

      members: {
        firstName: string;
        lastName: string;
        email: string;
      }[];
    }) => addResident(data),
    {
      onSuccess: async (data) => {
        const dob = new Date(data?.data?.data?.dateOfBirth).toLocaleDateString(
          "en-US",
          {
            timeZone: "GMT",
            year: "numeric",
            month: "long",
            day: "numeric",
          },
        );

        setResidentTable((arr) => [
          {
            id: `${data?.data?.data?.id}`,
            firstName: `${data?.data?.data?.firstName}`,
            lastName: `${data?.data?.data?.lastName}`,
            gender: `${data?.data?.data?.gender}`,
            dateOfBirth: getMaskedDate(dob),
            residentName: `${data?.data?.data?.firstName} ${data?.data?.data?.lastName}`,
            gerryId: `${data?.data?.data?.gerryId}`,
            isActive: data?.data?.data?.isActive,
            primaryContact: `${data?.data?.data?.primaryMember?.[0]?.firstName} ${data?.data?.data?.primaryMember?.[0]?.lastName}`,
            primaryMember: [
              {
                firstName: `${data?.data?.data?.primaryMember?.[0]?.firstName}`,
                lastName: `${data?.data?.data?.primaryMember?.[0]?.lastName}`,
                email: `${data?.data?.data?.primaryMember?.[0]?.email}`,
              },
            ],
          },
          ...arr,
        ]);
        setFormVisibility(false);
      },
      onError: (err) => {
        setError("residents.firstName", {
          message: Array.isArray(err["response"].data.message[0])
            ? err["response"].data.message[0]
            : err["response"].data.message,
        });
      },
    },
  );

  const onSubmit = async (data) => {
    const transformedData = await addResidentDataTransform(data?.residents);
    addResidentMutation(transformedData);
    reset({
      residents: defaultValue,
    });
    clickBtn();
  };

  const handleRightBtnClick = () => {
    console.log("actionRight");
  };

  const handleClose = () => {
    setModal("");
  };

  const getSearchText = (text: string) => {
    const txt = text.trim().toLowerCase();
    setSearch(txt);
  };
  return (
    <>
      {openModal === "cancel" && (
        <PopUp
          open={!!openModal}
          handleClose={handleClose}
          heading={modalData.heading}
          actionLeft={modalData.actionLeft}
          actionRight={{
            ...modalData.actionRight,
          }}
          handleRightBtnClick={handleRightBtnClick}
        >
          {openModal === "cancel" && (
            <SimplePopUp description={modalData?.props?.description} />
          )}
        </PopUp>
      )}
      {openModal === "invite" && (
        <Invite
          pccId={careHomeId}
          open={open}
          setOpen={setOpen}
          reset={reset}
          mutateFun={markResidentsVerifiedMutation}
          isResending={false}
          leftBtnLabel="I’ll do it later"
          handleLeftBtnClose={() => history.push(route.home.path)}
        />
      )}
      <Box sx={styles.backBtnWrapper}>
        <Box sx={styles.infoWrappers}>
          <Info
            content={globalInfoData?.content}
            heading={globalInfoData?.heading}
            mrViewAllModal={mrModalView as InfoModal}
          />
        </Box>

        <Box sx={styles.backWrapper}>
          <Box
            sx={styles.backStyles}
            onClick={() => history.push(route.manageResidents.path)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                history.push(route.manageResidents.path);
              }
            }}
            tabIndex={0}
          >
            <ArrowBackIosIcon sx={styles.backArrow} />
            <Box>Back</Box>
          </Box>
        </Box>

        <Box sx={styles.secondarySectionWrapper}>
          <Box sx={styles.addResidentsHeading}>Add Residents</Box>
          <Box sx={styles.searchWrapper}>
            <SearchBar getText={getSearchText} />
          </Box>
          <Button
            label="Add Another Resident"
            sx={styles.anotherResidentBtnStyles}
            variant="contained"
            disabled={formVisibility === false ? false : true}
            onClick={() => {
              setFormVisibility(true);
              reset({
                residents: defaultValue,
              });
            }}
          />
        </Box>
        {formVisibility && !isLoading && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Resident
              control={control}
              errors={errors}
              setValue={setValue}
              disabled={!isValid}
              reset={reset}
              watch={watch}
              setError={setError}
              resetField={resetField}
              clearErrors={clearErrors}
            />
          </form>
        )}
        {residentTable?.length > 0 && (
          <ResidentTable
            residentTable={residentTable}
            totalData={totalData}
            setPage={setPage}
            parentRefetch={refetch}
          />
        )}
      </Box>
      <Divider sx={styles.dividerStylesV3} color="#82B7F3" />
      <Box sx={styles.footerBtnWrapper}>
        <Button
          label="Continue"
          sx={styles.saveBtnStyles}
          variant="contained"
          disabled={residentTable.length > 0 ? false : true}
          onClick={() => {
            globalInfoData?.isInvited
              ? markResidentsVerifiedMutation()
              : setModal("invite");
            setOpen(true);
          }}
        />
      </Box>
    </>
  );
};

export default Form;
