import { Box, Popover } from "@mui/material";
import PopUp, { Action } from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import { route } from "constants/routes";
import useToggleState from "hooks/useToggleState";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { logoutAction } from "redux/actions";
import { userSessionInactive } from "utils/common";
import styles, { MenuItem, NavItem } from "./styles";

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
};

type InfoModal = {
  props: {
    description: string;
  };
} & ModalData;
const Header = () => {
  const location = useLocation();
  const history = useHistory();
  const [state, toggleState, setState] = useToggleState(false);
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  const logoutModal: InfoModal = {
    heading: "Log Out",

    props: {
      description: "Are you sure you want to Log Out?",
    },

    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      label: "Yes",
    },
  };

  const isTermsAndCond = location.pathname === "/terms-and-conditions";

  const [modalData] = useState(logoutModal);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenModal(false);
    setAnchorEl(null);
    toggleState();
  };

  const handleLogout = () => {
    userSessionInactive();
    dispatch(logoutAction({ isLoggedIn: false }));
    history.replace(route.signIn.path);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setAnchorEl(null);
    setState(false);
  }, [location.pathname, setState]);

  return (
    <Box sx={styles.wrapper}>
      {!!modalData && (
        <PopUp
          open={!!openModal}
          handleClose={handleClose}
          heading={modalData.heading}
          actionLeft={modalData.actionLeft}
          actionRight={modalData.actionRight}
          handleRightBtnClick={handleLogout}
        >
          <SimplePopUp description={modalData.props.description} />
        </PopUp>
      )}
      <Box sx={styles.dFlex}>
        <Box
          component="img"
          sx={styles.logo}
          src="/images/gerryLogo.png"
          alt="Gerry Logo"
          tabIndex={0}
          onClick={() => history.replace(route.home.path)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              history.replace(route.home.path);
            }
          }}
        />
        {!isTermsAndCond && (
          <Box sx={styles.dFlex}>
            <Link to={route.home.path}>
              <Box sx={styles.dFlex}>
                <NavItem matchRoute={location.pathname === route.home.path}>
                  <Box
                    component="img"
                    sx={styles.icon}
                    src="/icons/manage-home.svg"
                    alt="manage home"
                  />

                  <Box sx={styles.home}>Manage Homes</Box>
                </NavItem>
              </Box>
            </Link>
            <MenuItem
              tabIndex={0}
              isOpen={state}
              matchRoute={location.pathname === route.userProfile.path}
              ref={ref}
              onClick={(e) => {
                handleClick(e);
                toggleState();
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleKeyPress(e);
                  toggleState();
                }
              }}
            >
              <Box
                className="icon"
                component="img"
                sx={styles.icon}
                src="/icons/profile.svg"
                alt="profile"
              />
              <Box className="profile" sx={styles.profile}>
                <Box className="profileOpt" onClick={toggleState}>
                  Profile
                </Box>
              </Box>
            </MenuItem>
            <Box sx={styles.popover}>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                  sx: {
                    ...styles.paperStyles,
                  },
                }}
              >
                <Box sx={styles.dropdown}>
                  <Box
                    sx={styles.dropdownProfile}
                    tabIndex={0}
                    onClick={() => {
                      history.push(route.userProfile.path);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        history.push(route.userProfile.path);
                      }
                    }}
                  >
                    My Profile
                  </Box>
                  <Box sx={styles.divider} />
                  <Box
                    sx={styles.dropdownLogout}
                    onClick={() => setOpenModal(true)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setOpenModal(true);
                      }
                    }}
                    tabIndex={0}
                  >
                    Log Out
                  </Box>
                </Box>
              </Popover>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Header;
