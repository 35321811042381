import { ToggleButtonGroup, ToggleButton, SxProps } from "@mui/material";
import { ToggleBtn } from "./styles";

type ToggleProps = {
  onChange?: (val: boolean) => void;
  value: boolean;
  customStyles?: {
    [key: string]: SxProps;
  };
  disabled?: boolean;
};

const Toggle = ({
  onChange,
  value,
  customStyles,
  disabled = false,
}: ToggleProps) => {
  return (
    <ToggleBtn active={value}>
      <ToggleButtonGroup
        sx={{ ...customStyles?.active }}
        color="primary"
        exclusive
        onChange={(_, v) => {
          if (v !== null) {
            onChange(v);
          }
        }}
      >
        <ToggleButton value={true} disabled={disabled} className="activeBtn">
          On
        </ToggleButton>
        <ToggleButton value={false} disabled={disabled} className="inactiveBtn">
          Off
        </ToggleButton>
      </ToggleButtonGroup>
    </ToggleBtn>
  );
};

export default Toggle;
