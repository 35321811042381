import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "260px",
    "& .MuiFormHelperText-root": {
      bottom: "-40px",
    },
  },

  resetPassword: {
    fontWeight: "bold",
    whiteSpace: "nowrap",
    fontSize: {
      xl: "30px",
      lg: "30px",
      md: "30px",
      sm: "30px",
      xs: "25px",
    },
    textAlign: {
      xl: "left",
      lg: "left",
      md: "left",
      sm: "left",
      xs: "center",
    },
  },

  heading: {
    marginTop: "16px",
    fontSize: "15px",
    fontWeight: "500",
    textAlign: {
      xl: "left",
      lg: "left",
      md: "left",
      sm: "left",
      xs: "center",
    },
  },

  input: {
    marginTop: "22px",
  },

  continueBtn: {
    marginTop: "50px",
    marginBottom: "42px",
    backgroundColor: "primary.dark",
  },
};

export default styles;
