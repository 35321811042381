import { Box, InputBaseProps } from "@mui/material";
import { AxiosResponse } from "axios";
import { Input, Phone } from "components/common/FormComponents";
import PostalCode from "components/common/FormComponents/PostalCode";
import { InfoPopUp } from "components/common/PopUp/Modal";
import errorMessage from "constants/errorMessage";
import React, { useEffect, useState } from "react";
import {
  Control,
  FormState,
  UseFormResetField,
  UseFormWatch,
} from "react-hook-form";
import { useQuery } from "react-query";
import { setTimeZoneId, setTimeZoneToDefault } from "redux/slices/timZone";
import { useAppDispatch } from "redux/store";
import { getLatLong, getTimeZone } from "utils/api/user";
import regex from "utils/regex";
import { CareHomeProps } from "../..";
import styles from "./styles";

type CareHomeAddressProps = InputBaseProps & {
  control: Control<CareHomeProps, any>;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  formState: FormState<CareHomeProps>;
  watch: UseFormWatch<any>;
  resetField?: UseFormResetField<any>;
};

const CareHomeAddress = ({
  setDisable,
  control,
  formState,
  watch,
  resetField,
}: CareHomeAddressProps) => {
  const [zipData, setZipData] = useState<string>("");
  const [latLongData, setLatLongData] = useState<string>("");

  const { errors, isValid } = formState;

  useEffect(() => {
    setDisable(!isValid);
  }, [isValid, setDisable]);

  const { contactNumber: contactRegex, postalCode: zipRegex } = regex;

  const dispatch = useAppDispatch();

  const { refetch: getTimeZoneQuery } = useQuery(
    ["timeZoneData", latLongData],
    () => {
      return getTimeZone(latLongData);
    },
    {
      onSuccess: (data) => {
        const timeZoneId = data?.data?.timeZoneId;
        dispatch(setTimeZoneId(timeZoneId));
      },
      onError: () => {
        dispatch(setTimeZoneToDefault());
      },
      enabled: !!latLongData,
      retry: 1,
    },
  );

  useQuery(
    ["getLatLongData", zipData],
    () => {
      return getLatLong(zipData);
    },
    {
      onSuccess: (res: AxiosResponse) => {
        if (res?.data?.results?.length === 0) {
          dispatch(setTimeZoneToDefault());
        } else {
          const latitude = Math.round(
            res?.data?.results?.[0]?.geometry?.location?.lat,
          );

          const longitude = Math.round(
            res?.data?.results?.[0]?.geometry?.location?.lng,
          );

          setLatLongData(`${latitude},${longitude}`);
          getTimeZoneQuery();
        }
      },
      onError: () => {
        dispatch(setTimeZoneToDefault());
      },
      enabled: !!zipData,
      retry: 1,
    },
  );

  return (
    <InfoPopUp
      customStyles={{
        container: styles.infoPopupCont,
        viewAllWrapper: styles.infoPopupView,
      }}
    >
      <Box sx={styles.wrapper}>
        <Box sx={styles.fieldPrimaryWrapper}>
          <Box sx={styles.fieldSecondaryWrapperV1}>
            <Input
              control={control}
              label="Street Address"
              name="addressLine1"
              errors={errors}
              fullWidth={false}
              autoFocus={true}
              rules={{
                required: errorMessage.required,
              }}
            />
          </Box>
          <Box sx={styles.fieldSecondaryWrapperV1}>
            <Input
              control={control}
              fullWidth={false}
              label="Suite"
              name="addressLine2"
              errors={errors}
            />
          </Box>
          <Box sx={styles.fieldSecondaryWrapperV2}>
            <Input
              control={control}
              label="City"
              name="city"
              errors={errors}
              rules={{
                required: errorMessage.required,
              }}
            />
          </Box>
          <Box sx={styles.fieldSecondaryWrapperV2}>
            <Input
              control={control}
              label="Province/ State"
              name="province"
              errors={errors}
              rules={{
                required: errorMessage.required,
              }}
            />
          </Box>
          <Box sx={styles.fieldSecondaryWrapperV2}>
            <Input
              control={control}
              label="Country"
              name="country"
              errors={errors}
              rules={{
                required: errorMessage.required,
              }}
            />
          </Box>

          <Box sx={styles.fieldSecondaryWrapperV2}>
            <PostalCode
              name="postalCode"
              label="Zip/ Postal Code"
              control={control}
              errors={errors}
              customStyles={{ ...styles.postalCode }}
              rules={{
                pattern: {
                  value: zipRegex,
                  message: errorMessage.postalCode.invalidValue,
                },

                required: errorMessage.required,
              }}
              onBlur={() => setZipData(watch("postalCode").replace(/\s/g, ""))}
              resetField={resetField}
            />
          </Box>
          <Box sx={styles.contactFieldWrapper}>
            <Input
              control={control}
              label="Phone Number"
              name="countryCode"
              readOnly
              errors={errors}
              customStyle={{
                wrapper: {
                  width: "10%",
                },
              }}
            />

            <Phone
              name="phoneNumber"
              label=""
              control={control}
              errors={errors}
              customStyles={{ ...styles.inputContact }}
              rules={{
                pattern: {
                  value: contactRegex,
                  message: errorMessage.invalidContact,
                },
              }}
              resetField={resetField}
            />
          </Box>
          <Box
            sx={{
              ...styles.helperText,
              mt: errors.phoneNumber ? "18px" : "2px",
            }}
          >
            App user will reach out via this number
          </Box>
        </Box>
      </Box>
    </InfoPopUp>
  );
};

export default CareHomeAddress;
