import { Box } from "@mui/material";
import Button from "components/common/Button";
import Password from "components/common/FormComponents/Password";
import PopUp, { Action } from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import errorMessage from "constants/errorMessage";
import useBtnDisableToggler from "hooks/useBtnDisableToggler";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { changePassword } from "utils/api/user";
import regex from "utils/regex";
import styles from "./styles";

type UpdatePasswordFormData = {
  newPassword: string;
  confirmPassword: string;
};

type ChangePasswordProps = {
  showProfile: () => void;
};

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
};

type InfoModal = {
  props: {
    description: string;
  };
} & ModalData;

const ChangePassword = ({ showProfile }: ChangePasswordProps) => {
  const { control, formState, setError, getValues, clearErrors, watch } =
    useForm<UpdatePasswordFormData>({
      defaultValues: {
        newPassword: "",
        confirmPassword: "",
      },
      criteriaMode: "all",
      mode: "onTouched",
    });

  const { password: passwordRegex } = regex;
  const passwordError = formState.errors.confirmPassword;
  const [openModal, setOpenModal] = useState(false);
  const [isDisabled] = useBtnDisableToggler({ formState, watch });

  const { mutate } = useMutation(
    ["changePass"],
    (data: { newPassword: string }) => changePassword(data),
  );

  const changepwd: InfoModal = {
    heading: "Password Updated",

    props: {
      description: "Your password has been successfully changed.",
    },

    actionLeft: {
      hidden: true,
    },
    actionRight: {
      label: "Okay",
    },
  };

  const modalData = changepwd;

  useEffect(() => {
    if (passwordError) setError("newPassword", {});
    else clearErrors("newPassword");
  }, [passwordError, setError, clearErrors]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleModal = () => {
    setOpenModal(false);
    showProfile();
  };

  const handleSubmit = () => {
    const { newPassword } = getValues();
    mutate({ newPassword });
    setOpenModal(true);
  };

  return (
    <Box sx={styles.wrapper}>
      {!!modalData && (
        <PopUp
          open={!!openModal}
          handleClose={handleClose}
          heading={modalData.heading}
          actionLeft={modalData.actionLeft}
          actionRight={modalData.actionRight}
          handleRightBtnClick={handleModal}
        >
          <SimplePopUp description={modalData.props.description} />
        </PopUp>
      )}
      <Box sx={styles.resetPassword}>Change Password</Box>

      <Password
        name="newPassword"
        label="New Password"
        errors={formState.errors}
        control={control}
        rules={{
          required: true,
        }}
        customStyles={styles.input}
      />

      <Password
        name="confirmPassword"
        label="Re-enter your new password"
        errors={formState.errors}
        control={control}
        customStyles={styles.input}
        showHelperText={true}
        getValues={getValues}
        rules={{
          required: errorMessage.required,
          validate: {
            isMatch: (value) =>
              value === getValues("newPassword") ||
              errorMessage.invalidPassword,
            isLength: (value: string) =>
              (value && value.length >= 8) || errorMessage.password,
            isUpper: (value) =>
              (value && passwordRegex.isUpper.test(value.toString())) ||
              errorMessage.password,
            isLower: (value) =>
              (value && passwordRegex.isLower.test(value.toString())) ||
              errorMessage.password,
            isNum: (value) =>
              (value && passwordRegex.isNum.test(value.toString())) ||
              errorMessage.password,
            hasSpecialCharacter: (value) =>
              (value &&
                passwordRegex.hasSpecialCharacter.test(value.toString())) ||
              errorMessage.password,
          },
        }}
      />

      <Box sx={styles.editButtonWrapper}>
        <Button
          label="Cancel"
          customStyles={styles.cancelButton}
          onClick={() => {
            showProfile();
          }}
        />

        <Button
          label="Save"
          customStyles={styles.saveButton}
          onClick={handleSubmit}
          disabled={isDisabled}
        />
      </Box>
    </Box>
  );
};

export default ChangePassword;
