import { Styles } from "types/common";

const styles: Styles = {
  input: {
    position: "relative",
    "& .label": {
      fontSize: "15px",
      fontWeight: "500",
      color: "black",
      marginTop: "30px",
    },
    "& .MuiInputBase-root": {
      width: "100%",
      marginTop: "4px",
    },
  },

  btn: {
    position: "absolute",
    right: "68px",
    top: "148px",
    width: "64px",
    background: "none",
    color: "primary.dark",
    "&:hover": {
      background: "none",
      boxShadow: "none",
    },
    "&:disabled": {
      background: "none",
      color: "primary.light",
    },
  },

  emails: {
    display: "inline-block",
    backgroundColor: "custom.background.lightGray",
    marginRight: "8px",
    padding: "2px 4px",
    borderRadius: "5px",
    fontSize: "12px",
  },

  icon: {
    cursor: "pointer",
    paddingLeft: "3px",
  },

  emailWrapper: {
    maxHeight: "50px",
    overflowY: "auto",
    marginTop: "20px",
    marginBottom: "16px",
  },

  progressWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
};

export default styles;
