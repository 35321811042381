import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    "& .MuiInputBase-root": {
      width: "50%",
      height: "28px",
    },
  },
};

export default styles;
