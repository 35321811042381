import { Styles } from "types/common";

const styles: Styles = {
  inputWrapper: {
    margin: "20px 0",
    padding: "0",

    "& .label": {
      fontSize: "14px",
      fontWeight: "bold",
      minWidth: "110px",
      color: "custom.text.main",
      position: "static",
    },

    "& .labelPos": {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },

    "& .MuiAutocomplete-root": {
      flexBasis: "100%",
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: "0px 8px !important",
    },

    "& .MuiAutocomplete-popupIndicator": {
      marginRight: "10px",
    },

    "& .MuiInput-root": {
      "&:before, :after, :hover:not(.Mui-disabled):before": {
        borderBottomColor: "black",
        borderBottomWidth: "1px",
        borderRadius: "4px",
      },
    },

    "& .MuiChip-root": {
      fontSize: "12px",
      fontWeight: "500",
      borderRadius: "5px",
      backgroundColor: "custom.background.lightGray",
      color: "custom.text.secondary",

      "& img": {
        paddingRight: "5px",
      },
    },

    " & .MuiInput-underline": {
      "&:hover ": {
        borderBottom: "none",
      },
      "&:before": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
      "& .MuiOutlinedInput-root": {
        "& .fieldset": {
          borderColor: "none",
          border: "none",
        },
      },
    },
  },

  paper: {
    color: "#333",
    fontSize: "12px",
    fontWeight: "500",
    "& ul": {
      padding: "0px 8px",
      overflow: "auto",
      margin: "15px 19px 15px 0",
    },
    "& li": {
      borderBottomColor: "custom.misc.secondary",
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
    },
  },

  input: {
    borderColor: "custom.background.gray",
  },

  error: {
    color: "error.main",
    fontSize: "12px",
    padding: "0px",
    marginLeft: "110px",
  },
};

export default styles;
