import { Styles } from "types/common";
import { styled } from "@mui/system";
import { Theme } from "@mui/material";

const styles: Styles = {
  btn: {
    fontSize: "12px",
  },
};

type ToggleBtnProps = {
  active: boolean;
  theme: Theme;
};

export const ToggleBtn = styled("div", {
  shouldForwardProp: (prop) => prop !== "active",
})(
  ({
    active,
    theme: {
      palette: { success, error, custom },
    },
  }: ToggleBtnProps) => ({
    "& .MuiToggleButtonGroup-root": {
      height: "28px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "130px",
      borderRadius: "2px",
      backgroundColor: active ? success.light : error.light,
      "& .MuiToggleButtonGroup-grouped": {
        fontSize: "12px",
        height: "22px",
        width: "60px",
        borderWidth: "0",
        borderRadius: "2px",
        fontWeight: "bold",
        "&.activeBtn": {
          color: active ? custom.text.main : custom.text.light,
          backgroundColor: active ? "white" : "transparent",
          marginRight: 4,
          "&:hover": {
            backgroundColor: active
              ? custom.background.gray
              : custom.background.lightGray,
          },
        },
        "&.inactiveBtn": {
          color: active ? custom.text.light : custom.text.main,
          backgroundColor: active ? "transparent" : "white",
          "&:hover": {
            backgroundColor: active
              ? custom.background.lightGray
              : custom.background.gray,
          },
        },
      },
    },
  }),
);

export default styles;
