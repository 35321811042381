import { Box, Divider, SxProps } from "@mui/material";
import RadioInput from "components/common/FormComponents/Radio";
import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { getMember } from "utils/api/user";
import { radioFormStyle, styles } from "./styles";

type SecondaryPopUpType = {
  data?: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  }[];
  changeToPrimary: string;
  setChangeToPrimary: React.Dispatch<React.SetStateAction<string>>;
  customStyles?: { [key: string]: SxProps };
  totalData: number;
  contactId: string;
};

const FamilyRadioPopUp = ({
  data,
  changeToPrimary,
  totalData,
  setChangeToPrimary,
  contactId,
  customStyles,
}: SecondaryPopUpType) => {
  const scrollableDivRef = useRef(null);

  const [popUpData, setPopUpData] = useState(data);
  const [page, setPage] = useState<number>(1);

  useQuery(
    ["getFamily", contactId, page],
    () =>
      getMember({
        gerryId: contactId,
        type: "family",
        page,
        prePage: 8,
      }),
    {
      onSuccess: (res) => {
        setPopUpData((prevData) => [...prevData, ...res?.data?.data]);
      },
      onError: (err) => {},
    },
  );

  useEffect(() => {
    const scrollableDiv = scrollableDivRef?.current;

    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = scrollableDiv;
      const buffer = 10;

      scrollTop + clientHeight + buffer >= scrollHeight &&
        totalData !== popUpData.length &&
        setPage((pageInstance) => pageInstance + 1);
    };

    const debouncedHandleScroll = debounce(handleScroll, 200);

    scrollableDiv.addEventListener("scroll", debouncedHandleScroll);

    return () =>
      scrollableDiv?.removeEventListener("scroll", debouncedHandleScroll);
  }, [totalData, setPage, popUpData.length]);

  return (
    <>
      <Divider sx={styles.dividerStyles} color="#82B7F3" />
      <Box
        sx={
          {
            ...styles.viewAllWrapper,
            ...customStyles?.viewAllWrapper,
          } as SxProps
        }
      >
        {!!data && (
          <Box
            ref={scrollableDivRef}
            sx={
              {
                ...styles.container,
                ...(data?.length === 0 && styles.noData),
                height: "242px",
              } as SxProps
            }
          >
            {popUpData.length === 0 ? (
              <Box sx={styles.noData}>No family members added</Box>
            ) : (
              <>
                <Box
                  sx={{
                    ...radioFormStyle.viewAll,
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={radioFormStyle.viewItemHead}>Name</Box>
                  <Box sx={radioFormStyle.viewItemHead}>Email</Box>
                </Box>
                {popUpData.map((item, idx) => (
                  <RadioInput
                    checked={changeToPrimary === item.id}
                    handleChange={(e) => setChangeToPrimary(e.target.value)}
                    value={item.id}
                    key={`${item.id} ${idx}`}
                  >
                    <Box
                      sx={{
                        ...radioFormStyle.viewAll,
                        justifyContent: "start",
                        gap: "52px",
                        width: "100%",
                        m: "0",
                      }}
                    >
                      <Box
                        sx={radioFormStyle.viewItem}
                      >{`${item.firstName} ${item.lastName}`}</Box>
                      <Box sx={radioFormStyle.emailTypo}>
                        {item?.email ? item.email : "-"}
                      </Box>
                    </Box>
                  </RadioInput>
                ))}
              </>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default FamilyRadioPopUp;
