import { Styles } from "types/common";

const styles: Styles = {
  calendarIcon: {
    position: "relative",
    height: "14px",
    color: "custom.label",
  },

  calendarWrapper: {
    position: "absolute",
    top: "-36px",
    left: "14px",

    "&>div": {
      fontSize: "12px",
      color: "custom.text.secondary",
      // boxShadow: "10px 6px 9px -7px rgba(0,0,0,0.49)",
      boxShadow:
        "0px 6.169811248779297px 6.169811248779297px 0px rgba(0, 0, 0, 0.25)",
    },

    "& .react-datepicker": {
      fontSize: "11px",
      fontFamily: "inherit",
      border: "none",
    },

    "& .react-datepicker__header": {
      marginBottom: "0px",
      backgroundColor: "white",
      borderColor: "white",
      paddingBottom: "0",
    },

    "& .react-datepicker__day-names": {
      margin: "0",
      padding: "0",
      color: "custom.text.secondary",
      fontWeight: 500,
    },

    "& .react-datepicker__navigation-icon--next ": {
      color: "black",
    },

    "& .react-datepicker__day": {
      borderWidth: "1px",
      borderColor: "custom.misc.antiFlashWhite",
      borderStyle: "solid",
      fontWeight: 500,
      width: "48px",
      lineHeight: "40px",
      m: 0,
      borderRadius: 0,
      backgroundColor: "transparent",
    },

    "& .react-datepicker__day--disabled": {
      color: "custom.misc.gray",
    },

    "& .react-datepicker__month": {
      marginBottom: "8px",
    },

    "& .react-datepicker__day-name": {
      margin: "0 11px",
      fontWeight: 600,
    },

    "& .react-datepicker__day--in-range": {
      color: "custom.text.main",
      backgroundColor: "custom.misc.water",
    },

    "& .react-datepicker__day--range-end": {
      color: "white",
      backgroundColor: "custom.misc.newBlue",
    },

    "& .react-datepicker__day--range-start": {
      color: "white",
      backgroundColor: "custom.misc.newBlue",
    },

    "& .react-datepicker__day--selected": {
      color: "white",
      backgroundColor: "custom.misc.newBlue",
    },

    "& .react-datepicker__header__dropdown": {
      mb: "8px",
    },
  },
};
export default styles;
