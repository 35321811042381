import { Box } from "@mui/material";
import styles from "./styles";

type SecondaryPopUpType = {
  name: string;
  address: string;
  data: {
    residents: string;
    dob: string;
    primaryContact: string;
  }[];
};

const SecondaryInfo = ({ name, address, data }: SecondaryPopUpType) => {
  return (
    <Box>
      <Box className="homeName" sx={styles.name}>
        {name}
      </Box>
      <Box className="address" sx={styles.address}>
        {address}
      </Box>
      <Box sx={styles.viewAllWrapper}>
        <Box sx={styles.viewAll}>
          <Box sx={styles.viewItemHead}>Residents</Box>
          <Box sx={styles.viewItemHead}>Date of Birth</Box>
          <Box sx={styles.viewItemHead}>Primary Contact</Box>
        </Box>
        {data.map((item, idx) => (
          <Box sx={styles.viewAll} key={idx}>
            <Box sx={styles.viewItem}>{item.residents}</Box>
            <Box sx={styles.viewItem}>{item.dob}</Box>
            <Box>{item.primaryContact}</Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SecondaryInfo;
