import { Styles } from "types/common";

const styles: Styles = {
  inputAdornment: {
    position: "absolute",
    right: 2,
  },

  iconBtn: {
    padding: 0,
  },

  icon: {
    height: "20px",
    width: "26px",
  },

  customError: {
    color: "black",
    fontSize: "11px",
    display: "flex",
    gap: "2px",
    flexWrap: "wrap",
  },
};

export default styles;
