import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    "& a": {
      textDecoration: "underline",
    },
    "& td": {
      textAlign: "center",
      padding: "5.5px",
      border: "none",
      borderRadius: "5px",
    },
    display: "flex",
    height: "calc(100vh - 90px)",
    flexBasis: "100%",
    flexDirection: "column",
  },

  download: {
    color: "primary.main",
    textDecoration: "underline",
    cursor: "pointer",
  },
  calenderWrapper: {
    marginTop: "-20px",
  },
};

export default styles;
