import { Styles } from "types/common";

const styles: Styles = {
  input: {
    "& .label": {
      fontSize: "15px",
      fontWeight: "500",
      color: "black",
      marginTop: "30px",
    },
    "& .MuiInputBase-root": {
      width: "100%",
      marginTop: "4px",
    },
    marginBottom: "26px",
  },
};

export default styles;
