import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "primary.main",
  },

  labelPos: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    "& label": {
      width: "110px",
    },
  },

  inputWrapper: {
    margin: "10px 0 25px 0",
    padding: "27px 40px 70px 40px",
    backgroundColor: "custom.background.gray",
  },

  input: {
    marginBottom: "25px",
  },

  textArea: {
    width: "100%",
  },

  announcementsBtnWrapper: { display: "flex", flexBasis: "100%" },

  submitWrapper: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "end",
  },

  submitBtn: {
    fontSize: "14px",
    margin: "10px 0",
    height: "28px",
    backgroundColor: "primary.dark",
    "&:disabled": {
      backgroundColor: "primary.light",
      color: "white",
    },
    minWidth: "180px",
  },
};

export default styles;
