import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  maskedFormat: string;
};

const MaskedInput = forwardRef<HTMLElement, CustomProps>((props, ref) => {
  const { onChange, maskedFormat, ...rest } = props;
  return (
    <IMaskInput
      mask={maskedFormat}
      definitions={{
        "#": /[1-9]/,
      }}
      lazy={false}
      ref={ref}
      onAccept={(value: any) =>
        onChange({ target: { name: props.name, value } })
      }
      overwrite
      {...rest}
    />
  );
});

export default MaskedInput;
