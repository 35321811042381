import DropDown from "components/common/DropDown";
import { FileUpload } from "components/common/FormComponents";
import FileUploadProgress from "components/common/FormComponents/FileUploadProgress";
import PopUp from "components/common/PopUp";
import { InfoPopUp } from "components/common/PopUp/Modal";
import { ModalData } from "components/feature/ManageHome";
import { route } from "constants/routes";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { useAppSelector } from "redux/store";
import { uploadFile } from "utils/api/user";
import { uploaderStyles } from "./styles";

type AddResidentProps = {
  setAddResidentView?: React.Dispatch<React.SetStateAction<boolean>>;
};

type Method = "manually" | "importFile" | undefined;
const AddResident = ({ setAddResidentView }: AddResidentProps) => {
  const { control, setValue, clearErrors, setError, getValues } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      importFile: [],
    },
  });

  const [inputMethod, setInputMethod] = useState<Method>();
  const [file, setFile] = useState<File[]>([]);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [backendValidation, setBackendValidation] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(1);
  const [backendErrors, setBackendErrors] = useState<
    {
      row: number;
      error: string;
    }[]
  >([]);

  const {
    user: { careHomeRowData },
  } = useAppSelector((state) => state);

  const careHomeId = careHomeRowData?.id;

  const importFileContent: ModalData = useMemo(
    () => ({
      heading: "",
      actionLeft: {
        hidden: true,
      },
      actionRight: {
        label: "Upload",
        type: "submit",
      },
    }),
    [],
  );

  const [modalData, setModalData] = useState<ModalData>(importFileContent);

  const { mutate: uploadFileMutate } = useMutation(
    ["uploadFile"],
    (data: {
      file: File[];
      careHomeId: string;
      onProgress: (progress: number) => void;
    }) => uploadFile(data),
    {
      onSuccess: () => {
        history.push(route.addResident.path);
      },
      onError: (err) => {
        if (err["response"].data.message) {
          setBackendErrors(err["response"].data.message);
        }
        setShowProgressBar(false);
        setBackendValidation(true);
      },
    },
  );

  const onProgress = (progressInstance) => {
    setProgress(progressInstance);
  };

  const handleRightBtnClick = () => {
    if (inputMethod !== "importFile") {
      return;
    }

    if (backendValidation) {
      handleClose();
    } else if (showProgressBar) {
      handleClose();
    } else {
      setShowProgressBar(true);
      uploadFileMutate({
        file: file,
        careHomeId: careHomeId,
        onProgress: onProgress,
      });
    }
  };

  const handleClose = () => {
    setShowProgressBar(false);
    setInputMethod(undefined);
    setModalData(importFileContent);
    setBackendValidation(false);
    clearErrors("importFile");
    setFile([]);
  };

  const history = useHistory();

  useEffect(() => {
    if (backendValidation) {
      setModalData({
        heading: "",
        actionLeft: {
          hidden: true,
        },
        actionRight: {
          label: "Okay",
          type: "submit",
        },
      });
    } else if (showProgressBar) {
      setModalData({
        heading: "",
        actionLeft: {
          hidden: true,
        },
        actionRight: {
          label: "Cancel",
          type: "submit",
        },
      });
    } else setModalData(importFileContent);
  }, [showProgressBar, backendValidation, importFileContent]);

  return (
    <>
      <DropDown
        title="Add Residents"
        id="addResident"
        setOpenModal={setInputMethod}
        menuItems={[
          {
            label: "Manual",
            onClick: () => {
              setInputMethod("manually");
              history.push(route.addResident.path);
            },
          },
          {
            label: "Import file",
            onClick: () => {
              setInputMethod("importFile");
            },
          },
        ]}
      />
      <PopUp
        open={!!inputMethod}
        handleClose={handleClose}
        heading={modalData.heading}
        actionLeft={modalData.actionLeft}
        actionRight={{
          ...modalData.actionRight,
          disabled: file?.length > 0 ? false : true,
        }}
        handleRightBtnClick={handleRightBtnClick}
        customStyles={{
          wrapper: { height: "554px" },
          button: { bottom: "0" },
        }}
      >
        {inputMethod === "importFile" && (
          <InfoPopUp
            customStyles={{
              container: {
                padding: "0",
                width: "100%",
                height: "100%",
                overflow: "hidden",
              },
              viewAllWrapper: {
                background: "#F3F5F8",
                padding: "0",
              },
            }}
          >
            {showProgressBar ? (
              <FileUploadProgress
                setShowProgressBar={setShowProgressBar}
                progress={progress}
              />
            ) : (
              <FileUpload
                control={control}
                name="importFile"
                label=""
                multiple={false}
                maxLimit={1}
                setValue={setValue}
                getValues={getValues}
                clearErrors={clearErrors}
                setError={setError}
                file={file}
                setFileParent={setFile}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, sheet/xlsx"
                fileDropperLabel={
                  backendValidation
                    ? "Please complete the missing required fields in your file to proceed. Here are the specific details that need to be fixed."
                    : "Select a CSV or Excel to import or drag and drop it here"
                }
                errorsAtTop
                topErrorMsg="You can only upload a csv or .xlsx file"
                customStyle={uploaderStyles}
                backendValidation={backendValidation}
                backendErrors={backendErrors}
              />
            )}
          </InfoPopUp>
        )}
      </PopUp>
    </>
  );
};

export default AddResident;
