import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    "& .labelPos": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
      "& .label": {
        fontSize: "14px",
        minWidth: "110px",
        position: "static",
      },
    },
  },
};

export default styles;
