import { route } from "constants/routes";
import { SidebarMenu } from "types/common";

export const sidebarMenu: SidebarMenu[] = [
  {
    icon: "/icons/manage-resident.svg",
    name: "Manage Residents",
    route: route.manageResidents.path,
  },
  {
    icon: "/icons/reporting.svg",
    name: "Reporting",
    route: route.reporting.path,
  },
  {
    icon: "/icons/events.svg",
    name: "Events",
    route: route.events.path,
  },
  {
    icon: "/icons/announcements.svg",
    name: "Announcements",
    route: route.announcements.path,
  },
  {
    icon: "/icons/appointments.svg",
    name: "Appointments",
    route: route.appointments.path,
  },
];
