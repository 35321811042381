import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    padding: "17px 24px",
    backgroundColor: "#F3F5F8",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "flex-start",
    gap: "25px",
    height: "415px",
    marginTop: "18px",
  },

  arrowStyles: { cursor: "pointer", marginLeft: "13px" },

  submitWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    marginTop: "-10px",
  },

  popoverCustomButton: { marginTop: "0px" },

  submitBtn: {
    fontSize: "14px",
    minWidth: "150px",
    margin: "8px 0",
    height: "28px",
    backgroundColor: "primary.dark",
    marginRight: "10px",
    "&:disabled": {
      backgroundColor: "primary.light",
      color: "white",
    },
  },

  formWrapper: { width: "100%" },

  fieldWrapper: { marginTop: "45px" },

  dividerV1: { margin: "22px 0px" },

  dividerV2: { margin: "18px 0px 22px 0px" },

  dividerV3: { margin: "25px 0  18px 0" },

  dividerV4: { marginTop: "18px" },

  dividerV5: { margin: "18px 0px" },

  dateSelector: { height: "28px" },

  unAvailableSlotWrapper: { marginTop: "17px" },

  dataAndTimeWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  typo: { fontWeight: 500, fontSize: "15px" },

  editWrapper: {
    color: "primary.main",
    fontSize: "12px",
    fontWeight: 500,
    textDecoration: "underline",
    cursor: "pointer",
    marginRight: "15px",
  },

  listWrapper: {
    "&::-webkit-scrollbar ": {
      margin: "15px",
      width: "5px",
      borderRadius: "100px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "white",
      borderRadius: "100px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#8099b6",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8099b6",
      borderRadius: "100px",
      borderWidth: "2px",
      maxHeight: "30px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      zoom: 1,
    },
    overflowY: "scroll",
    maxHeight: "210px",
    padding: "0 13px",
  },

  dateWrapper: {
    display: "flex",
    gap: "10px",
    ".labelPos": {
      display: "flex",
      flexDirection: "column",
      alignItems: "baseline !important",
      gap: "9px",
    },
  },

  calendarWrapper: {
    minWidth: "334px",
    minHeight: "327px",
    maxHeight: "327px",
    position: "relative",

    "& .rmdp-shadow": {
      boxShadow: " 0 6.2px 6.2px 0 rgba(0, 0, 0, 0.25)",
    },

    "& .rmdp-wrapper": {
      borderRadius: "0",
    },

    "& .rmdp-header": {
      fontSize: "17px",
      marginTop: "0",
      textDecoration: "underline",
      // cursor: "pointer",
    },

    "& .rmdp-week-day": {
      color: "black",
      fontWeight: 600,
      fontSize: "12px",
    },

    "& .rmdp-calendar": {
      width: "334px",
    },

    "& .rmdp-day-picker": {
      justifyContent: "center",
      padding: "13px 13px 8px 13px",
    },

    "& .rmdp-day": {
      fontSize: "12px",
      border: "1px solid",
      borderColor: "#f3f3f3",
      height: "45px",
      width: "45px",
      fontWeight: 500,
    },

    "& .rmdp-today": {
      backgroundColor: "#056ee6 !important",
      borderRadius: "0px",
      background: "none !important",
      color: "black",
    },

    "& .rmdp-selected": {
      background: "#056ee6 !important",
      borderRadius: "0px",
      color: "white !important",
    },

    "& .rmdp-selected span:not(.highlight) ": {
      background: "#056ee6 !important",
      boxShadow: "none !important",
      color: "#fff !important",
      margin: "-3px",
    },

    "& .rmdp-today span:not(.highlight) ": {
      backgroundColor: "white",
      boxShadow: "none !important",
      borderRadius: "0px",
      color: "black",
      margin: "-3px",
    },

    "& .rmdp-week": {
      padding: "0 9px",
    },

    "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover": {
      borderRadius: "0px !important",
      backgroundColor: " #056ee6 !important",
      color: "#fff !important",
      margin: "-3px",
      background: "#056ee6 !important",
    },
  },
};

export default styles;
