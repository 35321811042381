import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    padding: "20px 8px",
    "& .MuiInputBase-root": {
      flexBasis: "1",
      width: "100% !important",
      marginTop: "0px",
      height: "28px",
    },
    height: "calc(100vh - 95px)",
  },

  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    marginBottom: "15px",
  },

  SearchBar: {
    flexBasis: "60%",
    alignSelf: "center",
  },

  heading: {
    fontSize: "20px",
    color: "custom.text.secondary",
    fontWeight: "bold",
  },

  addBtn: {
    height: "28px",
    minWidth: "150px",
  },

  status: {
    display: "flex",
    margin: "0 auto",
    justifyContent: "center",
    alignItems: "center",
  },

  tableLink: {
    color: "primary.main",
    cursor: "pointer",
    textDecoration: "underline",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis !important",
    overflow: "hidden",
  },

  input: {
    position: "relative",
    "& .label": {
      fontSize: "15px",
      fontWeight: "500",
      color: "black",
      marginTop: "30px",
    },
    "& .MuiInputBase-root": {
      width: "100%",
      marginTop: "4px",
    },
  },

  btn: {
    position: "absolute",
    right: "65px",
    top: "147px",
    width: "80px",
    background: "none",
    color: "primary.main",
    "&:hover": {
      background: "none",
      boxShadow: "none",
    },
  },

  emails: {
    display: "inline-block",
    backgroundColor: "custom.background.lightGray",
    marginRight: "8px",
    padding: "2px 4px",
    borderRadius: "5px",
    fontSize: "12px",
  },

  icon: {
    cursor: "pointer",
    paddingLeft: "3px",
  },

  emailWrapper: {
    maxHeight: "50px",
    overflowY: "auto",
    marginTop: "20px",
    marginBottom: "16px",
  },

  emptyBg: {
    width: "100%",
    height: "calc(100vh - 90px)",
    backgroundColor: "custom.background.lightGray",
  },
};

export default styles;
