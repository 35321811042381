import { Box } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { AxiosResponse } from "axios";
import DataGrid from "components/common/DataGrid";
import PopUp from "components/common/PopUp";
import { InfoPopUp } from "components/common/PopUp/Modal";
import Toggle from "components/common/Toggle";
import { ModalData } from "components/feature/ManageHome";
import { useEffect, useState } from "react";
import { Path, useForm } from "react-hook-form";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQuery,
} from "react-query";
import {
  addMember,
  deleteMember,
  getAllMembersData,
  updateMembers,
  updateResident,
} from "utils/api/user";
import { getGMTDate, getMaskedDate } from "utils/helper";
import {
  ResidentDetails,
  ResidentTableProps as TableDataProps,
  defaultValue,
} from "..";
import ResidentDetail from "../Resident/Forms/ResidentDetail";
import styles from "./styles";

type ResidentTableProps = {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  residentTable: TableDataProps;
  totalData: number;
  parentRefetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<QueryObserverResult<AxiosResponse<any>, unknown>>;
};

const ResidentTable = ({
  setPage,
  residentTable,
  totalData,
  parentRefetch,
}: ResidentTableProps) => {
  const [openModal, setModal] = useState<boolean>(false);
  const [familyMemberData, setFamilyMemberData] = useState([]);
  const [primaryMemberData, setPrimaryMemberData] = useState([]);

  const [id, setId] = useState<string>("");

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    setError,
    reset,
    getValues,
    clearErrors,
  } = useForm<ResidentDetails>({
    mode: "onChange",
    defaultValues: {
      residents: defaultValue,
    },
  });

  const [initialForm, setInitialForm] = useState<ResidentDetails>({
    residents: defaultValue,
  });

  const renderStatus = (params: GridCellParams) => {
    return (
      <Box sx={styles.actionIcon}>
        <Toggle
          value={params.row.isActive}
          onChange={() => console.log("toggle")}
        />
      </Box>
    );
  };

  const { refetch } = useQuery(
    ["familyMembers", id],
    () => getAllMembersData(id),
    {
      enabled: !!id,
      onSuccess: (data) => {
        setPrimaryMemberData(
          data?.data?.data?.filter((member) => member.isPrimary === true),
        );
        setFamilyMemberData(
          data?.data?.data?.filter((member) => member.isPrimary === false),
        );
      },
      retry: 1,
    },
  );

  useEffect(() => {
    const data = {
      residents: {
        primaryContact: {
          email: primaryMemberData?.[0]?.email,
          firstName: primaryMemberData?.[0]?.firstName,
          lastName: primaryMemberData?.[0]?.lastName,
          id: primaryMemberData?.[0]?.id,
        },
        familyMembers: familyMemberData,
      },
    };
    reset(data);
    setInitialForm((prev) => ({
      ...prev,
      residents: {
        ...prev.residents,
        ...data.residents,
      },
    }));
  }, [primaryMemberData, familyMemberData, reset]);

  const renderAction = (params: GridCellParams) => {
    const details = params?.row;

    return (
      <Box
        onClick={() => {
          setId(details?.id);
          refetch();
          const data = {
            residents: {
              id: details?.id,
              firstName: details?.firstName || "",
              lastName: details?.lastName || "",
              gender: details?.gender?.toLowerCase() || "",
              dob: getMaskedDate(details.dateOfBirth) || "",
            },
          };
          reset(data);
          setInitialForm((prev) => ({
            residents: {
              ...prev.residents,
              ...data.residents,
            },
          }));

          setModal(true);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setModal(true);
          }
        }}
        sx={styles.editStyles}
        tabIndex={0}
      >
        Edit
      </Box>
    );
  };

  const residentColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "S.No",
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "residentName",
      headerName: "Resident Name",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "gerryId",
      headerName: "Gerry ID",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Data",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderStatus,
    },
    {
      field: "primaryContact",
      headerName: "Primary Contact",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderAction,
    },
  ];

  const modalData: ModalData = {
    heading: "",
    actionLeft: {
      hidden: true,
    },
    actionRight: {
      label: "Save",
      type: "submit",
      actionCustomStyles: {
        button: styles.actionRight,
      },
    },
  };

  const handleClose = () => {
    setModal(undefined);
    reset({ residents: defaultValue });
  };

  const { mutate: editMembers } = useMutation(
    ["updateMember"],
    (data: {
      memberId?: string;
      membersData: {
        firstName: string;
        lastName: string;
        gender?: string;
        email?: string;
        dateOfBirth?: string;
        residentId?: string;
        isPrimary?: boolean;
      };
      isResident?: boolean;
      formName: string;
    }) => {
      if (data.isResident)
        return updateResident({ ...data.membersData, id: data.memberId });
      if (data.memberId) {
        return updateMembers({ ...data.membersData, id: data.memberId });
      } else {
        return addMember({ ...data.membersData });
      }
    },
    {
      onSuccess: () => {
        handleClose();
        parentRefetch();
      },
      onError: (err, { formName }) => {
        setError(formName as Path<ResidentDetails>, {
          message:
            err["response"].data.message[0].charAt(0).toUpperCase() +
            err["response"].data.message[0].slice(1),
        });
      },
    },
  );

  const { mutate: deleteMembersMutate } = useMutation(
    ["deleteMembers"],
    (data: { formName: string; memberId?: string }) => {
      return deleteMember(data?.memberId);
    },
    {
      onSuccess: () => {
        handleClose();
        parentRefetch();
      },
      onError: (err, { formName }) => {
        setError(formName as Path<ResidentDetails>, {
          message:
            err["response"].data.message[0].charAt(0).toUpperCase() +
            err["response"].data.message[0].slice(1),
        });
      },
    },
  );

  const compareCall = (initialData, finalData) => {
    const residentKeys = ["firstName", "lastName", "dob", "gender"];

    if (compareMember(initialData, finalData, residentKeys)) {
      editMembers({
        memberId: initialData["id"],
        formName: "residents.firstName",
        isResident: true,
        membersData: {
          firstName: finalData["firstName"],
          lastName: finalData["lastName"],
          gender: finalData["gender"],
          dateOfBirth: getGMTDate(finalData["dob"]),
        },
      });
    }

    //--- same for primary
    if (compareMember(initialData?.primaryContact, finalData?.primaryContact)) {
      editMembers({
        memberId: finalData?.primaryContact["id"],
        formName: "residents.primaryContact.firstName",
        isResident: false,
        membersData: {
          firstName: finalData?.primaryContact["firstName"],
          lastName: finalData?.primaryContact["lastName"],
          email: finalData?.primaryContact["email"],
        },
      });
    }

    // familyMember
    const finalFamilyMember = finalData.familyMembers;
    const initialFamilyMember = initialData.familyMembers;

    const length =
      finalFamilyMember?.length > initialFamilyMember?.length
        ? finalFamilyMember?.length
        : initialFamilyMember?.length;

    let iM = 0;
    let fM = 0;
    // for (let i = 0; i < length; i++) {

    if (
      finalFamilyMember?.length === 1 &&
      finalFamilyMember[0]?.firstName === "" &&
      finalFamilyMember[0]?.lastName === ""
    ) {
      handleClose();
      return;
    }

    while (iM < length || fM < length) {
      const fData = finalFamilyMember[fM];
      const iData = initialFamilyMember[iM];

      if (fData?.id === iData?.id) {
        if (compareMember(iData, fData)) {
          let obj = fData;
          if (obj.email === "") {
            delete obj.email;
          }
          editMembers({
            memberId: fData.id,
            formName: `residents.familyMembers.${fM}.firstName`,
            membersData: obj,
          });
        }

        iM++;
        fM++;
      } else if ((fData?.firstName || fData?.lastName) && !fData.id) {
        let obj = { ...fData, residentId: id };
        if (obj.email === "") {
          delete obj.email;
        }

        //new data
        editMembers({
          formName: `residents.familyMembers.${fM}.firstName`,
          membersData: obj,
        });
        fM++;
      } else {
        // delete
        deleteMembersMutate({
          formName: `residents.familyMembers.${fM}.firstName`,
          memberId: iData.id,
        });
        iM++;
      }
    }

    handleClose();
  };

  const compareMember = (
    initialMember,
    finalMember,
    keys = ["firstName", "lastName", "email"],
  ) => {
    const isUpdated = keys.reduce((prev, curr) => {
      return prev || initialMember[curr] !== finalMember[curr];
    }, false);

    return isUpdated;
  };

  const handleRightBtnClick = () => {
    compareCall(initialForm?.residents, getValues()?.residents);
  };

  return (
    <>
      <PopUp
        open={!!openModal}
        handleClose={handleClose}
        heading={modalData.heading}
        actionLeft={modalData.actionLeft}
        actionRight={{
          ...modalData.actionRight,
          disabled: false,
        }}
        handleRightBtnClick={handleRightBtnClick}
        customStyles={{
          wrapper: styles.popupWrapper,
        }}
      >
        {openModal && (
          <InfoPopUp
            customStyles={{
              container: styles.infoContainer,
              viewAllWrapper: styles.viewAllWrapper,
            }}
          >
            <ResidentDetail
              control={control}
              errors={errors}
              setValue={setValue}
              masterLabel="Edit details"
              showButton={false}
              watch={watch}
              setError={setError}
              clearErrors={clearErrors}
              customStyles={{
                customFormWrapper: styles.customFormWrapper,
                customEmailWrapper: styles.customEmailWrapper,
                customFieldWrapper: styles.customFieldWrapper,
              }}
            />
          </InfoPopUp>
        )}
      </PopUp>
      <DataGrid
        rows={residentTable}
        columns={residentColumn}
        disableSelectionOnClick
        onPageChange={(pageNo) => setPage(pageNo + 1)}
        rowsPerPageOptions={[10]}
        pageSize={10}
        rowCount={totalData}
        loading={false}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        emptyTable="No residents added."
      />
    </>
  );
};

export default ResidentTable;
