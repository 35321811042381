import { Box, Divider } from "@mui/material";
import Button from "components/common/Button";
import { MaskedDate } from "components/common/FormComponents";
import Input from "components/common/FormComponents/Input";
import Select from "components/common/FormComponents/Select";
import errorMessage from "constants/errorMessage";
import { useState } from "react";
import { useQuery } from "react-query";
import { getEmailVerified } from "utils/api/user";
import regex from "utils/regex";
import { ClearBtn } from "../..";
import styles from "../../../styles";
import FamilyFields from "../FamilyMember";

const ResidentDetail = ({
  control,
  watch,
  errors,
  setValue,
  disabled,
  setFormVisibility,
  customStyles,
  masterLabel = "Resident’s details",
  showButton = true,
  setError,
  clearErrors,
  formForFamily = false,
  resetField,
}: any) => {
  const [email, setEmail] = useState<{ fieldName: string; email: string }>({
    fieldName: "",
    email: "",
  });
  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" },
    { label: "Prefer not to answer", value: "prefer not to answer" },
  ];

  useQuery(
    ["getEmailVerification", email?.email],
    () => getEmailVerified(email?.email),
    {
      enabled: !!email?.email,
      onSuccess: (data) => {
        if (data?.data?.data?.isUnique === false) {
          setError(`${email.fieldName}`, {
            message: errorMessage.emailExist,
          });
        }
      },
      onError: () => {},
    },
  );

  return (
    <Box
      sx={{ ...styles.formWrapper, ...customStyles?.customFormWrapper }}
      key="resident-addition-form"
    >
      <Box sx={styles.subHeadingWrapper}>
        <Box sx={styles.subHeading}>{masterLabel}</Box>
      </Box>
      <Divider sx={styles.dividerStyles} color="#82B7F3" />
      <Box sx={styles.fieldsPrimaryWrapper}>
        <Box
          sx={{ ...styles.fieldWrapper, ...customStyles?.customFieldWrapper }}
        >
          <Input
            control={control}
            label="Resident First Name"
            name={`residents.firstName`}
            autoFocus={true}
            errors={errors}
            rules={
              !formForFamily && {
                required: errorMessage.required,
              }
            }
            isAlpha
            disabled={formForFamily}
          />
        </Box>
        <Box
          sx={{ ...styles.fieldWrapper, ...customStyles?.customFieldWrapper }}
        >
          <Input
            control={control}
            label="Resident Last Name"
            name={`residents.lastName`}
            errors={errors}
            rules={
              !formForFamily && {
                required: errorMessage.required,
              }
            }
            isAlpha
            disabled={formForFamily}
          />
        </Box>
        <Box
          sx={{ ...styles.fieldWrapper, ...customStyles?.customFieldWrapper }}
        >
          <Select
            control={control}
            label="Gender"
            name={`residents.gender`}
            options={genderOptions}
            errors={errors}
            rules={
              !formForFamily && {
                required: errorMessage.required,
              }
            }
            disabled={formForFamily}
          />
        </Box>
        <Box
          sx={{ ...styles.fieldWrapper, ...customStyles?.customFieldWrapper }}
        >
          <MaskedDate
            name="residents.dob"
            label="Date of birth"
            control={control}
            className="input-wrapper"
            errors={errors}
            disabled={formForFamily}
            rules={
              !formForFamily && {
                required: errorMessage.required,
              }
            }
            clearErrors={clearErrors}
          />
        </Box>
        <ClearBtn
          onClick={() => {
            resetField("residents.firstName");
            resetField("residents.lastName");
            resetField("residents.gender");
            resetField("residents.dob");
          }}
        />
      </Box>
      <Box sx={styles.subHeadingV2}>Primary Contact details</Box>
      <Divider sx={styles.dividerStyles} color="#82B7F3" />
      <Box sx={styles.fieldsPrimaryWrapper}>
        <Box
          sx={{ ...styles.fieldWrapper, ...customStyles?.customFieldWrapper }}
        >
          <Input
            control={control}
            label="First Name"
            name={`residents.primaryContact.firstName`}
            errors={errors}
            rules={
              !formForFamily && {
                required: errorMessage.required,
              }
            }
            isAlpha
            disabled={formForFamily}
          />
        </Box>
        <Box
          sx={{ ...styles.fieldWrapper, ...customStyles?.customFieldWrapper }}
        >
          <Input
            control={control}
            label="Last Name"
            name={`residents.primaryContact.lastName`}
            errors={errors}
            rules={
              !formForFamily && {
                required: errorMessage.required,
              }
            }
            isAlpha
            disabled={formForFamily}
          />
        </Box>
        <Box
          sx={{ ...styles.emailWrapper, ...customStyles?.customEmailWrapper }}
        >
          <Input
            control={control}
            label="Email"
            name={`residents.primaryContact.email`}
            errors={errors}
            rules={{
              pattern: {
                value: regex.email,
                message: errorMessage.invalidEmail,
              },
            }}
            onBlur={() =>
              setEmail({
                fieldName: `residents.primaryContact.email`,
                email: watch(`residents.primaryContact.email`),
              })
            }
            disabled={formForFamily}
          />
        </Box>
        <ClearBtn
          onClick={() => {
            resetField("residents.primaryContact.firstName");
            resetField("residents.primaryContact.lastName");
            resetField("residents.primaryContact.email");
          }}
        />
      </Box>
      <FamilyFields
        control={control}
        errors={errors}
        customStyles={{
          ...customStyles,
          customEmailWrapper: {
            width: "47%",
            ...customStyles?.customEmailWrapper,
          },
        }}
        setValue={setValue}
        watch={watch}
        setEmail={setEmail}
      />
      <Divider sx={styles.dividerStylesV2} color="#82B7F3" />
      {showButton && (
        <Button
          label="Save"
          sx={styles.saveBtnStyles}
          variant="contained"
          disabled={disabled}
          type="submit"
          onClick={() => {
            setFormVisibility ?? setFormVisibility(false);
          }}
        />
      )}
    </Box>
  );
};

export default ResidentDetail;
