import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import styles from "./styles";

type FileUploadProgressProps = {
  setShowProgressBar: React.Dispatch<React.SetStateAction<boolean>>;
  progress?: number;
};

const FileUploadProgress = ({
  setShowProgressBar,
  progress = 10,
}: FileUploadProgressProps) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  return (
    <Box sx={{ height: "305px", pt: "65px" }}>
      <Box sx={styles.progressWrapper}>
        <Box sx={styles.progressText}>
          <Box>Uploading...</Box>
          <CloseIcon
            sx={styles.closeIcon}
            onClick={() => setShowProgressBar(false)}
          />
        </Box>
        <Box sx={styles.progressContainer}>
          <Box sx={styles.fileIcon}>
            <Box component="img" src="/icons/files.svg" alt="file icon" />
          </Box>
          <Box sx={styles.progressStatus}>
            <Box sx={styles.progressText}>file name</Box>
            <BorderLinearProgress variant="determinate" value={progress} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FileUploadProgress;
