import { Styles } from "types/common";
import { styled } from "@mui/system";

const styles: Styles = {
  wrapper: {
    position: "fixed",
    top: "0",
    right: "0",
    left: "0",
    fontSize: "14px",
    padding: "12px 36px",
    backgroundColor: "custom.background.main",
    zIndex: "100",
    "& a": {
      textDecoration: "none",
    },
  },

  dFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  logo: {
    width: "88px",
  },

  icon: {
    width: "16px",
    marginBottom: "4px",
  },

  home: {
    paddingLeft: "10px",
    marginBottom: "4px",
  },

  dropdown: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "12px",
    "&.MuiBox-root": {
      width: "140px",
      backgroundColor: "custom.background.secondary",
    },
    "& a": {
      textDecoration: "none",
      color: "custom.text.secondary",
    },
  },

  dropdownProfile: {
    margin: "28px 47px 0px 24px",
    cursor: "pointer",
    "&:hover": {
      color: "primary.main",
    },
    "&:focus-visible": {
      color: "primary.main",
    },
  },

  dropdownLogout: {
    margin: "0px 59px 28px 24px",
    cursor: "pointer",
    "&:hover": {
      color: "primary.main",
    },
    "&:focus-visible": {
      color: "primary.main",
    },
  },

  divider: {
    margin: "25px 15px",
    width: "70%",
    border: "1px solid #82B7F3",
  },

  paperStyles: {
    top: "46px !important",
    left: "calc(100% - 166px)!important",
  },
};

type NavItemProps = {
  matchRoute: boolean;
};

export const NavItem = styled("div", {
  shouldForwardProp: (prop) => prop !== "matchRoute",
})(({ matchRoute }: NavItemProps) =>
  ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomStyle: "solid",
    borderBottomColor: matchRoute ? "primary.main" : "custom.background.main",
    borderBottomWidth: "2px",
    color: "custom.text.secondary",
    cursor: "pointer",
    // filter: matchRoute
    //   ? "invert(50%) sepia(63%) saturate(3616%) hue-rotate(163deg) brightness(97%) contrast(101%)"
    //   : "none",

    "& a": {
      textDecoration: "none",
    },
    // "&:hover": {
    //   color: "primary.main",
    //   filter:
    //     "invert(50%) sepia(63%) saturate(3616%) hue-rotate(163deg) brightness(97%) contrast(101%)",
    // },
  }),
);

type MenuItemStyleProps = {
  isOpen: boolean;
  matchRoute: boolean;
};

export const MenuItem = styled("div", {
  shouldForwardProp: (prop) => prop !== "isOpen" && prop !== "matchRoute",
})(({ isOpen, matchRoute }: MenuItemStyleProps) =>
  ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomStyle: "solid",
    borderBottomWidth: "2px",
    marginLeft: "28px",
    borderBottomColor:
      isOpen || matchRoute ? "primary.main" : "custom.background.main",
    cursor: "pointer",
    "& .icon": {
      width: "16px",
      // filter:
      //   isOpen || matchRoute
      //     ? "invert(29%) sepia(41%) saturate(4510%) hue-rotate(202deg) brightness(93%) contrast(96%)"
      //     : "none",
    },
    "& .profile": {
      paddingLeft: "10px",
      marginBottom: "4px",
    },
    "& .profileOpt": {
      cursor: "pointer",
      color: isOpen || matchRoute ? "primary.main" : "custom.text.secondary",
    },
    // "&:hover": {
    //   color: "primary.main",
    //   filter:
    //     "invert(50%) sepia(63%) saturate(3616%) hue-rotate(163deg) brightness(97%) contrast(101%)",
    // },
  }),
);

export default styles;
