import { Styles } from "types/common";

const styles: Styles = {
  infoPopupCont: { height: "352px", padding: "0", width: "100%" },

  infoPopupView: { background: "#F3F5F8", padding: "0" },

  wrapper: {
    "& .MuiInputBase-root": {
      marginTop: "0",
      fontWeight: "400 !important",
      width: "240px",
    },
  },

  fieldPrimaryWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },

  fieldSecondaryWrapperV1: {
    width: "240px",
    "& .MuiFormControl-root": {
      display: "flex !important",
    },
  },

  fieldSecondaryWrapperV2: {
    width: "240px",
    marginTop: "18px",
    display: "flex",
    alignItems: "flex-end",
  },

  helperText: {
    fontSize: "12px",
    color: "black",
    fontWeight: 500,
    marginTop: "2px",
  },

  contactFieldWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    gap: "14px",
    mt: "18px",
    "& .MuiInputBase-root": {
      width: "100%",
    },
  },

  inputContact: {
    "& .MuiInputBase-root": {
      borderColor: "custom.text.darkGray",
    },
    "& .MuiInputBase-input": {
      MozAppearance: "textfield",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        MozAppearance: "textfield",
        "&::-moz-focus-inner": {
          borderStyle: "none",
        },
      },
    },
    // "& .MuiFormHelperText-root": {
    //   bottom: "-32px",
    // },
  },

  postalCode: {
    "& .MuiInputBase-root": {
      borderColor: "custom.text.darkGray",
    },
    "& .MuiInputBase-input": {
      MozAppearance: "textfield",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        MozAppearance: "textfield",
        "&::-moz-focus-inner": {
          borderStyle: "none",
        },
      },
    },
    "& .MuiInputLabel-root": {
      mb: "1.5px",
    },
  },
};

export default styles;
