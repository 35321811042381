import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import Button from "components/common/Button";
import DataGrid from "components/common/DataGrid";
import Info from "components/common/Info";
import PopUp, { Action } from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import EventForm from "components/feature/Events/Form";
import { errorModal } from "constants/dummyData";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { setEventData, setEventRowData } from "redux/slices/user";
import { useAppDispatch, useAppSelector } from "redux/store";
import { deleteEventById, getAllEvents } from "utils/api/user";
import { eventDataTransformer, eventRowDataTransformer } from "utils/common";
import { InfoModal } from "../ManageResidents";
import styles from "./styles";

type EventFormData = {
  eventName: string;
  venue: string;
  description: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
};

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
};

type DeleteEvent = {
  props: {
    description: string;
  };
} & ModalData;

export type FormType = "add" | "edit" | null;

const EventsDefault = () => {
  const [form, setForm] = useState<FormType>();
  const [formData, setFormData] = useState<EventFormData>();
  const [openModal, setModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  // const [viewOnly, setViewOnly] = useState<boolean>(form === "edit");
  const [totalData, setTotalData] = useState(0);

  const dispatch = useAppDispatch();

  const {
    pccInfo: { pccId },
    user: {
      eventData: storedEventData,
      eventRowData,
      globalInfoData,
      mrModalView,
    },
  } = useAppSelector((state) => state);

  const { refetch: refetchAllEvents } = useQuery(
    ["getAllEvents", page, pccId, storedEventData],
    () => getAllEvents({ pccId, page }),
    {
      onSuccess: (data) => {
        setTotalData(data?.data?.metadata?.total);
        dispatch(setEventData(eventDataTransformer(data?.data?.data)));
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setModal(true);
        }
      },
      retry: 1,
    },
  );

  const { mutate: eventDelete, isLoading } = useMutation(
    "deleteEvent",
    () => deleteEventById({ eventId: eventRowData?.id }),
    {
      onSuccess: () => {
        refetchAllEvents();
        setModal(false);
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setModal(true);
        }
      },
      retry: 1,
    },
  );

  const deleteEvent: DeleteEvent = {
    heading: "Delete Event",
    props: {
      description: "Are you sure you want to delete this event?",
    },
    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      label: "Yes",
    },
  };

  const handleRightBtnClick = () => {
    eventDelete();
  };

  // useEffect(() => {
  //   form === "edit" ? setViewOnly(false) : setViewOnly(true);
  // }, [form]);

  useEffect(() => {
    refetchAllEvents();
  }, []);

  const [modalData, setModalData] = useState(deleteEvent);
  const handleEvent = (data: EventFormData) => data;

  const onClickAdd = () => {
    setForm("add");
    setFormData({
      eventName: "",
      venue: "",
      description: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
    });
  };

  const onClickEdit = (data) => {
    setForm("edit");
    setFormData(eventRowDataTransformer(data) as EventFormData);
  };

  const handleClose = () => {
    setModal(false);
    setModalData(deleteEvent);
  };

  const handleDel = () => {
    setModal(true);
  };

  useEffect(() => {
    if (storedEventData?.length === 0) {
      refetchAllEvents();
    }
  }, [storedEventData?.length, refetchAllEvents]);

  const renderEvent = (params: GridCellParams) => {
    return (
      <Box
        sx={styles.tableLink}
        onClick={() => {
          dispatch(setEventRowData(params.row));
          onClickEdit(params.row);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            dispatch(setEventRowData(params.row));
            onClickEdit(params.row);
          }
        }}
        tabIndex={0}
      >
        {params.row.events}
      </Box>
    );
  };

  const renderAction = (params: GridCellParams) => {
    return (
      <Box
        sx={styles.del}
        component="img"
        src="/icons/del-bin.svg"
        alt="del-icon"
        onClick={() => {
          handleDel();
          dispatch(setEventRowData(params.row));
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            dispatch(setEventRowData(params.row));
            setModal(true);
          }
        }}
        tabIndex={0}
      />
    );
  };

  const eventColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "S.No",
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "events",
      headerName: "Events",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderEvent,
    },
    {
      field: "venue",
      headerName: "Venue",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "time",
      headerName: "Time",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderAction,
    },
  ];

  return (
    <Box sx={styles.wrapper}>
      {!!modalData && (
        <PopUp
          open={!!openModal}
          handleClose={handleClose}
          heading={modalData.heading}
          actionLeft={modalData.actionLeft}
          actionRight={modalData.actionRight}
          handleRightBtnClick={handleRightBtnClick}
          loading={isLoading}
        >
          <SimplePopUp description={modalData.props.description} />
        </PopUp>
      )}
      <Info
        content={globalInfoData?.content}
        heading={globalInfoData?.heading}
        mrViewAllModal={mrModalView as InfoModal}
        showToggle={false}
      />

      {!form ? (
        <Box sx={styles.tableWrapper}>
          <Box sx={styles.btnWrapper}>
            <Button
              label="Add Event"
              icon
              sx={styles.btn}
              onClick={() => onClickAdd()}
            >
              <AddIcon fontSize="small" sx={styles.icon} />
            </Button>
          </Box>

          <DataGrid
            rows={eventDataTransformer(storedEventData) || []}
            columns={eventColumn}
            disableSelectionOnClick
            onPageChange={(pageNo) => setPage(pageNo + 1)}
            rowsPerPageOptions={[5]}
            pageSize={10}
            rowCount={totalData}
            loading={false}
            componentsProps={{
              toolbar: { showQuickFilter: true },
            }}
            emptyTable="No Events added yet."
          />
        </Box>
      ) : (
        <Box sx={styles.formBtnWrapper}>
          <Box sx={styles.backWrapper}>
            <Box
              sx={styles.backStyles}
              onClick={() => setForm(null)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setForm(null);
                }
              }}
              tabIndex={0}
            >
              <ArrowBackIosIcon sx={styles.backArrow} />
              <Box>Back</Box>
            </Box>
          </Box>
          <EventForm
            btnLabel={form === "add" ? "" : "Edit Event"}
            submitBtnLabel={form === "add" ? "Post Event" : "Save Event"}
            viewOnly={form === "edit"}
            data={formData}
            onSubmitForm={handleEvent}
            refetchAllEvents={refetchAllEvents}
            form={form}
            setForm={setForm}
          />
        </Box>
      )}
    </Box>
  );
};

export default EventsDefault;
