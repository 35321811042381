import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React from "react";

const CheckBox = ({
  label,
  checked,
  handleChange,
}: {
  label: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Checkbox
        sx={{
          marginLeft: "-12px",
          svg: { fontSize: "20px", color: checked ? "#50AC48" : "black" },
        }}
        checked={checked}
        onChange={handleChange}
      />
      <Box sx={{ fontSize: "14px", fontWeight: 700 }}>{label}</Box>
    </Box>
  );
};

export default CheckBox;
