import AnnoucementsDefault from "components/feature/Announcements";

const Announcements = () => {
  return (
    <>
      <AnnoucementsDefault />
    </>
  );
};

export default Announcements;
