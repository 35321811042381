import { Styles } from "types/common";

const styles: Styles = {
  viewAllWrapper: {
    height: "216px",
    overflowY: "auto",
    background: "white",
    color: "custom.text.secondary",
    paddingLeft: "8px",
    paddingBottom: "20px",
  },

  viewItemHead: {
    fontWeight: "bold",
    fontSize: "15px",
    flexBasis: "30%",
  },

  viewAll: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    lineHeight: "48px",
    fontSize: "14px",
    fontWeight: "500",
  },

  viewItem: {
    flexBasis: "30%",
  },

  name: {
    marginTop: "36px",
  },

  address: {
    marginBottom: "4px",
  },
};

export default styles;
