import { createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "redux/slices/signOut";
import { logoutUser } from "redux/thunks";

interface UserDetails {
  id: string;
  isActive: true;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  customRole?: string;
  address: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    province: string;
    country: string;
    pincode: string;
  };
  phoneNumber: string;
  email: string;
  careHomes: [
    {
      careHomeId: string;
      isActive: boolean;
      role: {
        id: string;
        name: string;
      };
    },
  ];
}

type AnnouncementData = {
  id: string;
  careHomeId: string;
  title: string;
  description: string;
  tags: string[];
  isPublished: boolean;
  publishedAt: Date;
  expireAt: string;
  announcedBy: string;
  careHome: {
    id: string;
    isActive: boolean;
    pccCode: string;
    name: string;
    phoneNumber: string;
  };
  announceBy: {
    id: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    email: string;
  };
};

type EventData = {
  name: string;
  id: string;
  venue: string;
  type: string | null;
  description: string;
  startDate: string;
  endDate: string;
  careHome: {
    id: string;
    isActive: boolean;
    pccCode: string;
    name: string;
    phoneNumber: string;
  };
  organizedBy: {
    id: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    email: string;
  };
};

type CalenderDate = { id: string; fromDate: string; toDate: string };

interface IUser {
  isLoggedIn: boolean;
  userDetails: UserDetails;
  careHomeRowData: { status: boolean; careHomeName: string; id?: string };
  residentsRowData: { gerryId: string; status: boolean };
  careHomeData: [];
  eventData: EventData[];
  eventRowData: any;
  signInEmail: string;
  globalInfoData: {
    heading: string;
    status?: boolean;
    content: {
      label: string;
      value: string;
    }[];
    isInvited: boolean;
    source: string;
  };
  mrModalView: {
    data: {
      name: string;
      email: string;
    }[];
  };
  announcementData: AnnouncementData[];
  announcementRowData: { id: string; user: any };
  calenderData: CalenderDate[];
  careHomeTimeZone: string;
}

const initialState: IUser = {
  isLoggedIn: false,
  userDetails: {
    id: "",
    isActive: true,
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      province: "",
      country: "",
      pincode: "",
    },
    email: "",
    phoneNumber: "",
    careHomes: [
      {
        careHomeId: "",
        isActive: false,
        role: {
          id: "",
          name: "",
        },
      },
    ],
  },
  careHomeRowData: { status: false, careHomeName: "" },
  residentsRowData: { gerryId: "", status: false },
  careHomeData: [],
  globalInfoData: {
    heading: "",
    content: [
      {
        label: "",
        value: "",
      },
    ],
    source: "",
    isInvited: false,
  },
  mrModalView: {
    data: [
      {
        name: "",
        email: "",
      },
    ],
  },
  eventData: [
    {
      id: "",
      type: null,
      name: "",
      description: "",
      venue: "",
      startDate: "",
      endDate: null,
      careHome: {
        id: "",
        isActive: true,
        pccCode: "",
        name: "",
        phoneNumber: "",
      },
      organizedBy: {
        id: "",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        email: "",
      },
    },
  ],
  eventRowData: { id: "", user: {} },
  signInEmail: "",
  announcementData: [
    {
      id: "",
      careHomeId: "",
      title: "",
      description: "",
      tags: [],
      isPublished: true,
      publishedAt: null,
      expireAt: "",
      announcedBy: "",
      careHome: {
        id: "",
        isActive: true,
        pccCode: "",
        name: "",
        phoneNumber: "",
      },
      announceBy: {
        id: "",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        email: "",
      },
    },
  ],
  announcementRowData: { id: "", user: {} },
  calenderData: [
    { id: "0", fromDate: "", toDate: "" },
    { id: "1", fromDate: "", toDate: "" },
    { id: "2", fromDate: "", toDate: "" },
  ],
  careHomeTimeZone: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.isLoggedIn = true;
      state.userDetails = action.payload;
    },
    setUser: (state, action) => {
      state.userDetails = action.payload;
    },
    setCareHomeRowData: (state, action) => {
      state.careHomeRowData = action.payload;
    },
    setResidentsRowData: (state, action) => {
      state.residentsRowData = action.payload;
    },
    setSignInEmail: (state, action) => {
      state.signInEmail = action.payload;
    },
    setCareHomeData: (state, action) => {
      state.careHomeData = action.payload;
    },
    setAnnouncementData: (state, action) => {
      state.announcementData = action.payload;
    },
    setAnnouncementRowData: (state, action) => {
      state.announcementRowData = action.payload;
    },

    setEventData: (state, action) => {
      state.eventData = action.payload;
    },
    setEventRowData: (state, action) => {
      state.eventRowData = action.payload;
    },
    setGlobalInfoData: (state, action) => {
      state.globalInfoData = action.payload;
    },
    setMrModalView: (state, action) => {
      state.mrModalView = action.payload;
    },
    setCalenderData: (state, action) => {
      state.calenderData = action.payload;
    },
    setCareHomeTimeZone: (state, action) => {
      state.careHomeTimeZone = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser.fulfilled, (state, _action) => {
      state.isLoggedIn = false;
      state.userDetails = initialState.userDetails;
    });

    builder.addCase(logoutAction, (state, _action) => {
      state.isLoggedIn = false;
      state.userDetails = initialState.userDetails;
      state.careHomeRowData = initialState.careHomeRowData;
      state.residentsRowData = initialState.residentsRowData;
      state.signInEmail = initialState.signInEmail;
      state.careHomeData = initialState.careHomeData;
      state.careHomeRowData = initialState.careHomeRowData;
      state.eventData = initialState.eventData;
      state.eventRowData = initialState.eventRowData;
      state.announcementData = initialState.announcementData;
      state.announcementRowData = initialState.announcementRowData;
      state.globalInfoData = initialState.globalInfoData;
      state.mrModalView = initialState.mrModalView;
      state.calenderData = initialState.calenderData;
    });
  },
});

export const {
  loginUser,
  setCareHomeRowData,
  setResidentsRowData,
  setSignInEmail,
  setCareHomeData,
  setAnnouncementData,
  setAnnouncementRowData,
  setEventData,
  setEventRowData,
  setGlobalInfoData,
  setMrModalView,
  setCalenderData,
  setUser,
  setCareHomeTimeZone,
} = userSlice.actions;

export default userSlice.reducer;
