import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputBase,
  InputLabel,
  SxProps,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import styles, { Period } from "./styles";

type TimeState = {
  hour: string;
  min: string;
  period: string;
};

type TimeProps<T> = UseControllerProps<T> &
  FormControlProps & {
    label?: string;
    setValue: UseFormSetValue<T>;
    getValues?: UseFormGetValues<T>;
    clearErrors: UseFormClearErrors<T>;
    className?: string;
    disabled?: boolean;
    labelPos: boolean;
    readOnly?: boolean;
    customStyles?: { [key: string]: SxProps };
  };

type FieldName = "hour" | "min" | "period";
const Time = <T extends FieldValues>({
  className,
  label,
  control,
  name,
  rules,
  disabled = false,
  readOnly = false,
  labelPos = false,
  setValue,
  getValues,
  clearErrors,
  customStyles,
}: TimeProps<T>) => {
  const timeVal = getValues(name);
  const [time, setTime] = useState<TimeState>({
    hour: "",
    min: "",
    period: "",
  });

  useEffect(() => {
    if (timeVal?.length) {
      setTime({
        hour: timeVal.split(":")[0],
        min: timeVal.split(":")[1].split(" ")[0],
        period: timeVal.split(":")[1].split(" ")[1],
      });
    }
  }, [timeVal]);

  const handleHour = (val: string, maxVal: string) => {
    if (Number(val) >= 0 && Number(val) <= Number(maxVal) && val.length <= 2) {
      setTime({ ...time, hour: val });
    }
  };

  const handleMin = (val: string, maxVal: string) => {
    if (Number(val) >= 0 && Number(val) <= Number(maxVal) && val.length <= 2) {
      setTime({ ...time, min: val });
    }
  };

  const handlePeriod = (val: string) => {
    setTime({ ...time, period: val });
  };

  const handleOnBlur = (val: number | string, fieldName: FieldName) => {
    if (val.toString().length < 2 && val >= 0) {
      setTime({ ...time, [fieldName]: `0${val}` });
    }
  };

  useEffect(() => {
    if (Object.values(time).every((val) => val !== "")) {
      clearErrors(name);
      setValue(
        name,
        `${time.hour}:${time.min} ${time.period}` as
        PathValue<T, Path<T>>
        ,
        {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        },
      );
    }
  }, [clearErrors, name, setValue, time]);

  return (
    <Box sx={{ ...styles.wrapper, ...customStyles?.wrapper } as SxProps}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ formState: { errors } }) => (
          <Box className={labelPos && "labelInline"}>
            <InputLabel
              shrink={disabled}
              className="label"
              disabled={disabled}
              required={!!rules?.required}
            >
              {label}
            </InputLabel>
            <Box sx={styles.input}>
              <FormControl>
                <InputBase
                  name="hour"
                  placeholder="00"
                  className="inputField"
                  type="number"
                  value={time.hour}
                  disabled={disabled}
                  readOnly={readOnly}
                  sx={styles.input}
                  style={{ background: readOnly ? "none" : "white" }}
                  onBlur={(e) => handleOnBlur(e.target.value, "hour")}
                  error={!!errors[name]}
                  onChange={(e) => {
                    !readOnly && handleHour(e.target.value, "12");
                  }}
                  autoComplete="off"
                />
              </FormControl>

              <Box sx={styles.separator}>:</Box>
              <FormControl>
                <InputBase
                  name="min"
                  className="inputField"
                  placeholder="00"
                  type="number"
                  disabled={disabled}
                  readOnly={readOnly}
                  value={time.min}
                  error={!!errors[name]}
                  sx={styles.input}
                  style={{ background: readOnly ? "none" : "white" }}
                  onBlur={(e) => handleOnBlur(e.target.value, "min")}
                  onChange={(e) => !readOnly && handleMin(e.target.value, "59")}
                  autoComplete="off"
                />
              </FormControl>
              {(timeVal?.split(" ")[1] === "AM" || !readOnly) && (
                <FormControl>
                  <Period
                    name="period"
                    placeholder="AM"
                    value="AM"
                    disabled={disabled}
                    readOnly={true}
                    error={!!errors[name]}
                    timePeriod={
                      time.period === "AM" || timeVal?.split(" ")[1] === "AM"
                    }
                    onClick={() => {
                      !readOnly && handlePeriod("AM");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        !readOnly && handlePeriod("AM");
                      }
                    }}
                  />
                </FormControl>
              )}
              {(timeVal?.split(" ")[1] === "PM" || !readOnly) && (
                <FormControl>
                  <Period
                    name="period"
                    placeholder="PM"
                    value="PM"
                    disabled={disabled}
                    readOnly={true}
                    error={!!errors[name]}
                    timePeriod={
                      time.period === "PM" || timeVal?.split(" ")[1] === "PM"
                    }
                    onClick={() => {
                      !readOnly && handlePeriod("PM");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        !readOnly && handlePeriod("PM");
                      }
                    }}
                  />
                </FormControl>
              )}
            </Box>
            {errors[name] && (
              <FormHelperText sx={styles.error}>
                {errors[name].message}
              </FormHelperText>
            )}
          </Box>
        )}
      />
    </Box>
  );
};

export default Time;
