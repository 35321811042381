import { Box, InputBaseProps } from "@mui/material";
import { SxProps } from "@mui/system";
import { Input } from "components/common/FormComponents";
import errorMessage from "constants/errorMessage";
import { useState } from "react";
import {
  Control,
  FormState,
  UseFormSetError,
  UseFormWatch,
} from "react-hook-form";
import { useQuery } from "react-query";
import { getEmailVerified } from "utils/api/user";
import regex from "utils/regex";
import styles from "./styles";

type AddNewPrimaryContactProps = InputBaseProps & {
  control: Control<{ firstName: string; lastName: string; email: string }, any>;
  formState: FormState<{
    firstName: string;
    lastName: string;
    email: string;
  }>;
  customStyles?: SxProps;
  setError: UseFormSetError<any>;
  watch: UseFormWatch<any>;
  setIsEmailUnique: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddNewPrimaryContact = ({
  control,
  formState,
  setError,
  watch,
  setIsEmailUnique,
}: AddNewPrimaryContactProps) => {
  const { errors } = formState;

  const [email, setEmail] = useState<{ fieldName: string; email: string }>({
    fieldName: "",
    email: "",
  });

  useQuery(
    ["getEmailVerification", email?.email],
    () => getEmailVerified(email?.email),
    {
      enabled: !!email?.email,
      onSuccess: (data) => {
        if (data?.data?.data?.isUnique === false) {
          setError(`${email.fieldName}`, {
            message: errorMessage.emailExist,
          });
        } else {
          setIsEmailUnique(true);
        }
      },
      onError: () => {},
    },
  );

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.fieldPrimaryWrapper}>
        <Box sx={styles.fieldSecondaryWrapperV1}>
          <Input
            control={control}
            label="First Name"
            name="firstName"
            errors={errors}
            isAlpha
            rules={{
              required: errorMessage.required,
            }}
          />
        </Box>
        <Box sx={styles.fieldSecondaryWrapperV1}>
          <Input
            control={control}
            label="Last Name"
            name="lastName"
            errors={errors}
            isAlpha
            rules={{
              required: errorMessage.required,
            }}
          />
        </Box>
      </Box>

      <Box sx={{ ...styles.fieldPrimaryWrapper, display: "block", mt: "20px" }}>
        <Box sx={styles.emailWrapper}>
          <Input
            control={control}
            label="Email"
            name="email"
            errors={errors}
            rules={{
              pattern: {
                value: regex.email,
                message: errorMessage.invalidEmail,
              },
              required: errorMessage.required,
            }}
            onBlur={() =>
              setEmail({
                fieldName: "email",
                email: watch("email"),
              })
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddNewPrimaryContact;
