import { createSlice } from "@reduxjs/toolkit";

interface timeZoneProps {
  timeZoneId: string;
}

const initialState: timeZoneProps = {
  timeZoneId: "America/Toronto",
};

const timeZoneSlice = createSlice({
  name: "timeZone",
  initialState,
  reducers: {
    setTimeZoneId: (state, action) => {
      state.timeZoneId = action.payload;
    },

    setTimeZoneToDefault: (state) => {
      state.timeZoneId = initialState?.timeZoneId;
    },
  },
});

export const { setTimeZoneId, setTimeZoneToDefault } = timeZoneSlice.actions;

export default timeZoneSlice.reducer;
