import { Box } from "@mui/material";
import { AxiosResponse } from "axios";
import PopUp, { Action } from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { setTimeZoneToDefault } from "redux/slices/timZone";
import { useAppDispatch, useAppSelector } from "redux/store";

import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
} from "react-query";
import AddNewHomeForm from "./Forms/AddNewHome";
import CareHomeAddress from "./Forms/CareHomeAddress";

import { uploadCareHomeWithoutPcc } from "utils/api/user";

export type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
  props?: any;
};

export type AddHomeFormData = {
  pccId: string;
};

export type CareHomeProps = {
  name: string;
  firstName: string;
  lastName: string;
  countryCode?: string;
  mainPhoneNumber: string;
  city: string;
  country: string;
  province: string;
  addressLine1: string;
  phoneNumber: string;
  addressLine2: string;
  postalCode: string;
};

export type ManuallyProps = {
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<QueryObserverResult<AxiosResponse<any>, unknown>>;
  setDropDown: any;
};

type Modal = "addManually" | "careHomeAddress" | "careHomeAdded" | undefined;

const Manually = ({ refetch, setDropDown }: ManuallyProps) => {
  const [openModal, setModal] = useState<Modal>("addManually");
  const [disable, setDisable] = useState<boolean>(true);

  const { timeZoneId } = useAppSelector((state) => state.timZone);

  const dispatch = useAppDispatch();

  const { control, formState, getValues, watch, resetField } =
    useForm<CareHomeProps>({
      mode: "onChange",
      defaultValues: {
        name: "",
        firstName: "",
        lastName: "",
        countryCode: "+1",
        mainPhoneNumber: "",
        city: "",
        country: "",
        province: "",
        addressLine1: "",
        phoneNumber: "",
        addressLine2: "",
        postalCode: "",
      },
    });

  const careHomeAddedContent: ModalData = {
    heading: "Care home address",
    props: {
      description:
        "Please ensure to include the details of the resident at a later stage.",
    },
    actionLeft: {
      hidden: true,
    },
    actionRight: {
      label: "Save",
      type: "submit",
    },
  };

  const addNewHomeContent: ModalData = {
    heading: "Add New Home",
    actionLeft: {
      hidden: true,
    },
    actionRight: {
      label: "Continue",
      type: "submit",
    },
  };

  const [modalData, setModalData] = useState(addNewHomeContent);

  const handleClose = () => {
    setModal(undefined);
    setModalData(addNewHomeContent);
    setDropDown("");
  };

  const transformData = async (formData) => ({
    name: formData.name,
    firstName: formData.firstName,
    lastName: formData.lastName,
    mainPhoneNumber: `${formData.countryCode} ${formData.mainPhoneNumber}`,
    addressLine1: formData.addressLine1,
    addressLine2: formData.addressLine2,
    city: formData.city,
    province: formData.province,
    country: formData?.country,
    postalCode: formData?.postalCode,
    timeZone: timeZoneId,
    phoneNumber: formData?.phoneNumber,
  });

  const { mutate: addCareHomeWithoutPcc } = useMutation(
    "addCareHomeWithoutPcc",
    (data: CareHomeProps) => uploadCareHomeWithoutPcc(data),
    {
      onSuccess: (e) => {
        dispatch(setTimeZoneToDefault());
      },
      onError: () => {},
    },
  );

  const handleRightBtnClick = async () => {
    switch (openModal) {
      case "addManually": {
        setModal("careHomeAddress");
        setModalData(careHomeAddedContent);
        break;
      }

      case "careHomeAddress": {
        setModalData(careHomeAddedContent);
        const transformedData = await transformData(getValues());
        addCareHomeWithoutPcc(transformedData);
        setModal("careHomeAdded");
        refetch();
        break;
      }

      case "careHomeAdded": {
        handleClose();
        refetch();
        break;
      }

      default: {
        break;
      }
    }
  };

  return (
    <Box component="form">
      <PopUp
        open={!!openModal}
        handleClose={handleClose}
        heading={modalData.heading}
        actionLeft={modalData.actionLeft}
        actionRight={{
          ...modalData.actionRight,
          disabled: disable,
        }}
        handleRightBtnClick={handleRightBtnClick}
        customStyles={
          openModal === "addManually" ? { wrapper: { minHeight: "554px" } } : {}
        }
      >
        {openModal === "addManually" && (
          <AddNewHomeForm
            setDisable={setDisable}
            control={control}
            formState={formState}
            resetField={resetField}
          />
        )}
        {openModal === "careHomeAddress" && (
          <CareHomeAddress
            setDisable={setDisable}
            control={control}
            formState={formState}
            watch={watch}
            resetField={resetField}
          />
        )}

        {openModal === "careHomeAdded" && (
          <SimplePopUp description={modalData?.props?.description} />
        )}
      </PopUp>
    </Box>
  );
};

export default Manually;
