import { Box, SxProps } from "@mui/material";
import Button from "components/common/Button";
import PopUp, { Action } from "components/common/PopUp";
import Toggle from "components/common/Toggle";
import {
  activateFamilyMember,
  deactivateFamilyMember,
} from "components/feature/ManageHome";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { setCareHomeRowData } from "redux/slices/user";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getCareHomeByPccLocal, statusToggle } from "utils/api/user";
import { InfoPopUp, SimplePopUp } from "../PopUp/Modal";
import styles from "./styles";

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
};

type InfoModal = {
  data: {
    name: string;
    email: string;
  }[];
} & ModalData;

type InfoProps = {
  content: {
    label: string;
    value: string;
    action?: boolean;
  }[];
  heading: string;
  handleOnClick?: () => void;
  mrViewAllModal?: InfoModal;
  customStyles?: {
    [key: string]: SxProps;
  };
  disabled?: boolean;
  fromPcc?: boolean;
  showToggle?: boolean;
};

const Info = ({
  content,
  heading,
  mrViewAllModal,
  disabled,
  fromPcc = true,
  showToggle = true,
  ...rest
}: InfoProps) => {
  const {
    pccInfo: { pccId },
    user: { careHomeRowData, globalInfoData },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState<
    "info" | "statusDeactivate" | "statusActivate" | undefined
  >(undefined);
  const [modalData, setModalData] = useState<any>(mrViewAllModal);
  const [careHomeStatus, setCareHomeStatus] = useState(careHomeRowData.status);

  const { data: careHomeData, refetch } = useQuery(
    ["getCareHome", pccId],
    () => getCareHomeByPccLocal(pccId),
    {
      onSuccess: (data) => {
        setCareHomeStatus(data?.data?.data?.isActive);
      },
      retry: 1,
    },
  );

  useEffect(() => {
    refetch();
  }, [pccId, refetch]);

  const careHomeInfo = careHomeData?.data?.data;

  const { mutate: statsUpdate } = useMutation(
    "statusToggle",
    (data: { id: string; status: boolean }) => statusToggle(data),
    {
      onSuccess: () => {
        refetch();
        dispatch(setCareHomeRowData(!careHomeInfo?.status));
        handleClose();
      },
      retry: 1,
    },
  );

  const getDescWithName = (name: string, isActive: boolean) => {
    return isActive
      ? `Do you want to deactivate '${name}'?`
      : `Do you want to activate '${name}'?`;
  };

  const handleModal = () => {
    setOpenModal("info");
    setModalData(mrViewAllModal);
  };

  const handleClose = () => {
    setOpenModal(undefined);
  };

  const handleCareHomeToggle = () => {
    const description = getDescWithName(
      careHomeInfo?.name,
      careHomeInfo?.isActive,
    );
    const data = careHomeStatus
      ? {
          ...deactivateFamilyMember,
          props: { ...deactivateFamilyMember.props, description },
        }
      : {
          ...activateFamilyMember,
          props: { ...activateFamilyMember.props, description },
        };

    setModalData(data);
    setOpenModal(careHomeStatus ? "statusDeactivate" : "statusActivate");
  };

  const handleRightBtnClick = () => {
    switch (openModal) {
      case "info":
        return handleClose();
      case "statusDeactivate":
        return statsUpdate({ id: pccId, status: careHomeStatus });
      case "statusActivate":
        return statsUpdate({ id: pccId, status: careHomeStatus });
    }
  };

  const source = globalInfoData?.source;

  return (
    <>
      {!!modalData && (
        <PopUp
          open={!!openModal}
          handleClose={handleClose}
          heading={modalData.heading}
          actionLeft={modalData.actionLeft}
          actionRight={modalData.actionRight}
          handleRightBtnClick={handleRightBtnClick}
        >
          {openModal === "info" ? (
            <InfoPopUp data={modalData.data} />
          ) : (
            <SimplePopUp
              description={modalData?.props?.description}
              note={modalData?.props?.note}
            />
          )}
        </PopUp>
      )}
      <Box sx={styles.wrapper}>
        <Box sx={styles.header}>
          <Box>{heading}</Box>
          {
            <Box sx={styles.toggleWrapper}>
              <Toggle
                onChange={handleCareHomeToggle}
                value={careHomeStatus}
                disabled={false}
                {...rest}
              />
              {source === "pcc" && "(PointClickCare)"}
            </Box>
          }
        </Box>

        {content?.map((item, idx) => (
          <Box sx={styles.sub} key={idx}>
            {item.label && <Box>{item.label}</Box>}
            <Box>
              {item.action ? (
                <Button
                  label={item?.value}
                  sx={styles.view}
                  onClick={handleModal}
                />
              ) : item?.value.includes("null") ? (
                ""
              ) : (
                <Box>{item?.value}</Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Info;
