import { Box, InputBaseProps } from "@mui/material";
import { Input, Phone } from "components/common/FormComponents";
import { InfoPopUp } from "components/common/PopUp/Modal";
import errorMessage from "constants/errorMessage";
import { useEffect } from "react";
import { Control, FormState, UseFormResetField } from "react-hook-form";
import regex from "utils/regex";
import { CareHomeProps } from "../..";
import styles from "./styles";

type AddNewHomeProps = InputBaseProps & {
  control: Control<CareHomeProps, any>;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  formState: FormState<CareHomeProps>;
  resetField?: UseFormResetField<any>;
};

const AddNewHome = ({
  setDisable,
  control,
  formState,
  resetField,
}: AddNewHomeProps) => {
  const { errors, isValid } = formState;

  const { contactNumber: contactRegex } = regex;

  useEffect(() => {
    setDisable(!isValid);
  }, [isValid, setDisable]);

  return (
    <InfoPopUp
      customStyles={{
        container: styles.infoPopupCont,
        viewAllWrapper: styles.infoPopupView,
      }}
    >
      <Box sx={styles.wrapper}>
        <Input
          control={control}
          label="Care Home Name"
          name="name"
          errors={errors}
          rules={{
            required: errorMessage.required,
          }}
          autoFocus
        />
        <Box sx={styles.helperText}>
          Enter the details of Care home Admin or point of contact at the
          residence.
        </Box>
        <Box sx={styles.fieldPrimaryWrapper}>
          <Box sx={styles.fieldSecondaryWrapperV1}>
            <Input
              control={control}
              label="First Name"
              name="firstName"
              errors={errors}
              isAlpha
            />
          </Box>
          <Box sx={styles.fieldSecondaryWrapperV1}>
            <Input
              control={control}
              label="Last Name"
              name="lastName"
              errors={errors}
              isAlpha
            />
          </Box>
        </Box>

        <Box sx={styles.fieldPrimaryWrapper}>
          <Box sx={styles.contactFieldWrapper}>
            <Input
              control={control}
              label="Phone Number"
              name="countryCode"
              readOnly
              errors={errors}
              customStyle={{
                wrapper: {
                  width: "10%",
                },
              }}
            />

            <Phone
              name="mainPhoneNumber"
              label=""
              control={control}
              errors={errors}
              customStyles={{ ...styles.inputContact }}
              rules={{
                pattern: {
                  value: contactRegex,
                  message: errorMessage.invalidContact,
                },
              }}
              resetField={resetField}
            />
          </Box>
        </Box>
        <Box
          sx={{
            ...styles.phoneHelperText,
            mt: errors.mainPhoneNumber ? "18px" : "2px",
          }}
        >
          This number is only for admin use
        </Box>
      </Box>
    </InfoPopUp>
  );
};

export default AddNewHome;
