import { AxiosResponse } from "axios";
import PopUp, { Action } from "components/common/PopUp";
import {
  SecondaryInfo,
  SimpleInput,
  SimplePopUp,
} from "components/common/PopUp/Modal";
import errorMessage from "constants/errorMessage";
import useBtnDisableToggler from "hooks/useBtnDisableToggler";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQuery,
} from "react-query";
import { addCareHome, getCareHomeByPcc, getResidence } from "utils/api/user";
import { dateInSpecifiedTimeZone } from "utils/helper";
import Invite from "../../Invite";
type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
  props?: any;
};

type ConfirmCareHome = {
  props: {
    description: string;
    name: string;
    address: string;
  };
} & ModalData;

type VerifyCareHome = {
  props: {
    name: string;
    address: string;
    data: {
      residents: string;
      dob: string;
      primaryContact: string;
    }[];
  };
} & ModalData;

export type AddHomeFormData = {
  pccId: string;
  orgId: string;
};

export type AddNewHome = {
  careHomeName: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
};

export type PccProps = {
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<QueryObserverResult<AxiosResponse<any>, unknown>>;
  setDropDown: any;
};

type Modal = "add" | "confirm" | "verify" | undefined;
type InviteModal = "invite" | "";

const Pcc = ({ refetch, setDropDown }: PccProps) => {
  const { control, trigger, getValues, watch, formState, reset, setError } =
    useForm<AddHomeFormData>({
      mode: "onChange",
      defaultValues: { pccId: "", orgId: "" },
    });

  const [isDisabled] = useBtnDisableToggler({ formState, watch });
  const [openModal, setModal] = useState<Modal>("add");
  const [inviteCareHome, setInviteCareHome] = useState<InviteModal>("");
  const [open, setOpen] = useState<boolean>(false);
  const [localPccId, setLocalPccId] = useState<string>("");

  const {
    data: careHome,
    isFetching: getCareHomeFetching,
    refetch: refetchCareHome,
  } = useQuery(
    ["getCareHomeByPccId"],
    () => getCareHomeByPcc(getValues("pccId"), getValues("orgId")),
    {
      onSuccess: (data) => {
        setModal("confirm");
        setModalData(confirmModalContent);
        // setTimeZone(data?.data?.timeZone);
      },
      onError: (err) => {
        if (err["response"].data.errorCode === "MYG_ERR_P001") {
          setError("pccId", { message: errorMessage.validPccId });
        } else if (err["response"].data.errorCode === "MYG_ERR_P004") {
          setError("orgId", { message: errorMessage.validOrgId });
        } else if (err["response"]?.data?.statusCode !== 429) {
          alert("Not enough quota limit for this request");
        } else {
          setError("pccId", { message: err["response"].data.message });
        }
      },
      retry: 1,
      enabled: false,
    },
  );

  const {
    data: resident,
    isFetching: residentFetching,
    refetch: refetchResident,
  } = useQuery(
    ["getResidence"],
    () => getResidence(getValues("pccId"), getValues("orgId")),
    {
      onSuccess: () => {
        setModal("verify");
        setModalData(verifyModalContent);
      },
      enabled: false,
      retry: 1,
    },
  );

  const { mutate: careHomeAdd, isLoading } = useMutation(
    "careHomeAddition",
    () => addCareHome(watch("pccId"), watch("orgId")),
    {
      onSuccess: () => {
        refetch();
        setInviteCareHome("invite");
        setOpen(true);
        handleClose();
        setModalData(addModalContent);
      },
      onError: (err) => {
        if (err["response"]?.data?.statusCode !== 429) {
          alert("Not enough quota limit for this request");
        }
      },
      retry: 1,
    },
  );

  const careHomeInfo = careHome?.data?.data;

  const address = [
    careHomeInfo?.addressLine1,
    careHomeInfo?.city,
    careHomeInfo?.province,
    careHomeInfo?.country,
  ]
    ?.filter((item) => item && item !== "")
    ?.join(", ");

  const handleClose = () => {
    setModal(undefined);
    setLocalPccId(watch("pccId"));
    reset();
    setModalData(addModalContent);
    setDropDown("");
  };

  const addModalContent: ModalData = {
    heading: "Add Care Home",
    actionLeft: {
      hidden: true,
    },
    actionRight: {
      label: "Submit",
      type: "submit",
      disabled: isDisabled,
    },
  };

  const confirmModalContent: ConfirmCareHome = {
    heading: "Confirm Care Home",
    props: {
      description:
        "Is this the Care Home you were trying to add to your system?",
      name: careHomeInfo?.name,
      address: `Address:${address}`,
    },
    actionLeft: {
      label: "No",
    },
    actionRight: {
      label: "Yes",
      type: "submit",
    },
  };

  const verifyModalContent: VerifyCareHome = {
    heading: "Please verify the details of the Care Home:",
    actionLeft: {
      label: "Don't Add",
    },
    actionRight: {
      label: "Confirm",
      type: "submit",
    },
    props: {
      name: careHome?.data?.data?.name,
      address: `Address:${address}`,
      data:
        resident?.data?.data?.map((val) => ({
          residents: `${val?.firstName || ""} ${val?.lastName || ""}`,
          dob: dateInSpecifiedTimeZone(val?.DOB, "GMT"),
          primaryContact: "-",
        })) || [],
    },
  };

  const [modalData, setModalData] = useState(addModalContent);

  const handleRightBtnClick = () => {
    switch (openModal) {
      case "add": {
        trigger("pccId", { shouldFocus: true }).then((res) => {
          if (res) {
            refetchCareHome();
          } else {
            return formState.errors;
          }
        });
        break;
      }
      case "confirm": {
        refetchResident();
        break;
      }
      case "verify": {
        careHomeAdd();
        break;
      }

      default: {
        break;
      }
    }
  };

  return (
    <>
      <PopUp
        open={!!openModal}
        handleClose={handleClose}
        heading={modalData.heading}
        actionLeft={modalData.actionLeft}
        actionRight={{
          ...modalData.actionRight,
          disabled: ["add", "confirm", "verify"].includes(openModal)
            ? isDisabled
            : false,
        }}
        handleRightBtnClick={handleRightBtnClick}
        loading={getCareHomeFetching || residentFetching || isLoading}
        loadingText={`${openModal === "verify" ? "Please wait a minute" : ""}`}
      >
        {openModal === "add" && (
          <>
            <SimpleInput
              name="orgId"
              label="Please enter the UUID below to add a new care home:"
              control={control}
              placeholder="Organization UUID"
              errors={formState.errors}
              showAdornment
              rules={{
                required: { value: true, message: errorMessage.required },
              }}
              isValid={!isDisabled}
              inputProps={{
                type: "text",
              }}
            />
            <SimpleInput
              name="pccId"
              label="Please enter the Facility ID below to add a new care home:"
              control={control}
              placeholder="Carehome PointClickCare ID"
              errors={formState.errors}
              showAdornment
              rules={{
                required: { value: true, message: errorMessage.required },
              }}
              autoFocus={false}
              isValid={!isDisabled}
              inputProps={{
                type: "number",
              }}
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === "E") {
                  e.preventDefault();
                }
              }}
            />
          </>
        )}
        {openModal === "confirm" && (
          <SimplePopUp
            name={confirmModalContent.props.name}
            description={confirmModalContent.props.description}
            address={confirmModalContent.props.address}
          />
        )}

        {openModal === "verify" && (
          <SecondaryInfo
            name={verifyModalContent.props.name}
            address={verifyModalContent.props.address}
            data={verifyModalContent.props.data}
          />
        )}
      </PopUp>
      {inviteCareHome === "invite" && (
        <Invite
          pccId={localPccId}
          open={open}
          setOpen={setOpen}
          reset={reset}
          refetch={refetch}
          isResending={false}
        />
      )}
    </>
  );
};

export default Pcc;
