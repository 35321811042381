import { Box } from "@mui/system";
import Button from "components/common/Button";
import { Input, Phone } from "components/common/FormComponents";
import errorMessage from "constants/errorMessage";
import { isEqual } from "lodash";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { setUser } from "redux/slices/user";
import { useAppDispatch, useAppSelector } from "redux/store";
import { updateProfile } from "utils/api/user";
import { getTrimmedData } from "utils/helper";
import regex from "utils/regex";
import styles from "./styles";

type UserProfileFormData = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  contactNumber: string;
  password: string;
};

type ProfileProps = {
  changePassword: () => void;
};

const Profile = ({ changePassword }: ProfileProps) => {
  const [submitState, setSubmitState] = useState(true);

  const dispatch = useAppDispatch();

  const {
    user: {
      userDetails: { id },
    },
  } = useAppSelector((state) => state);

  const { mutate } = useMutation(
    ["updateInfo"],
    (data: {
      id: string;
      data: {
        email: string;
        phoneNumber: string;
        firstName: string;
        lastName: string;
        customRole: string;
      };
    }) => updateProfile(data),
    {
      onSuccess: (data) => {
        dispatch(setUser(data?.data?.data));
      },
    },
  );

  const {
    user: {
      userDetails: {
        firstName,
        lastName,
        email,
        careHomes,
        customRole,
        phoneNumber: contactNumber,
      },
    },
  } = useAppSelector((state) => state);

  const role = careHomes?.[0]?.role?.name;

  const initialState = useMemo(
    () => ({
      firstName,
      lastName,
      email,
      role: customRole ?? "",
      contactNumber,
      customRole,
    }),
    [contactNumber, email, firstName, lastName, role, customRole],
  );

  const { control, formState, handleSubmit, watch, reset, getValues } =
    useForm<UserProfileFormData>(
      useMemo(
        () => ({
          defaultValues: {
            firstName,
            lastName,
            email,
            role: customRole ?? "",
            contactNumber,
          },
          criteriaMode: "all",
          mode: "onChange",
        }),
        [contactNumber, customRole, email, firstName, lastName],
      ),
    );

  // const [disableSubmitBtn] = useBtnDisableToggler({ formState, watch });

  const [isDisabled, setIsDisabled] = useState(true);

  const onSubmit = handleSubmit((data) => {
    mutate({
      id,
      data: {
        email: getValues().email,
        firstName: getValues().firstName,
        lastName: getValues().lastName,
        phoneNumber: getValues().contactNumber,
        customRole: getValues().role,
      },
    });
    setIsDisabled(true);
  });

  const handleOnchange = () =>
    !isEqual(getTrimmedData(watch()), initialState)
      ? setSubmitState(false)
      : setSubmitState(true);

  const { email: emailRegex, contactNumber: contactRegex } = regex;

  const { errors } = formState;

  return (
    <Box sx={styles.mainWrapper}>
      <Box component="form" onChange={handleOnchange} onSubmit={onSubmit}>
        <Box sx={styles.wrapper}>
          <Box sx={styles.profile}>
            My Profile
            <Box
              sx={styles.changePassword}
              onClick={() => changePassword()}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  changePassword();
                }
              }}
            >
              (Change Password)
            </Box>
          </Box>
          <Box sx={styles.fieldWrapper}>
            <Box>
              <Input
                name="firstName"
                label="First Name"
                control={control}
                errors={errors}
                customStyles={styles.input}
                disabled={isDisabled}
                isAlpha
                rules={{
                  required: errorMessage.required,
                }}
              />
            </Box>
            <Box>
              <Input
                name="lastName"
                label="Last Name"
                control={control}
                errors={errors}
                customStyles={styles.input}
                disabled={isDisabled}
                isAlpha
                rules={{
                  required: errorMessage.required,
                }}
              />
            </Box>
            <Box>
              <Input
                name="email"
                label="Email ID"
                control={control}
                errors={errors}
                customStyles={styles.input}
                disabled={true}
                rules={{
                  required: errorMessage.required,
                  pattern: {
                    value: emailRegex,
                    message: errorMessage.invalidEmail,
                  },
                }}
              />
            </Box>
            <Box>
              <Phone
                name="contactNumber"
                label="Contact Number"
                disabled={isDisabled}
                control={control}
                errors={errors}
                customStyles={styles.input}
                rules={{
                  required: errorMessage.required,
                  pattern: {
                    value: contactRegex,
                    message: errorMessage.invalidContact,
                  },
                }}
              />
            </Box>
            <Box sx={styles.role}>
              <Input
                name="role"
                label="Role"
                control={control}
                errors={errors}
                customStyles={styles.input}
                disabled={isDisabled}
                rules={{
                  required: errorMessage.required,
                }}
              />
            </Box>
          </Box>
        </Box>
        {isDisabled ? (
          <Button
            label="Edit"
            customStyles={styles.edit}
            onClick={() => setIsDisabled(false)}
          />
        ) : (
          <Box sx={styles.editButtonWrapper}>
            <Button
              label="Cancel"
              customStyles={styles.cancelButton}
              onClick={() => {
                setIsDisabled(true);
                reset(initialState);
              }}
            />
            <Button
              label="Save"
              type="submit"
              customStyles={styles.saveButton}
              disabled={submitState}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Profile;
