import { Styles } from "types/common";

export const styles: Styles = {
  dividerStyles: {
    mb: "4px",
    ml: "12px",
  },
  viewAllWrapper: {
    background: "white",
    color: "custom.text.secondary",
    padding: "10px",
    minWidth: "520px",
    height: "261px",
  },

  container: {
    overflowY: "auto",
    width: "98%",
    margin: "auto",
  },

  viewItemHead: {
    flexBasis: "40%",
    fontWeight: "bold",
    fontSize: "15px",
  },

  viewItem: {
    flexBasis: "40%",
    maxHeight: "48px",
    maxWidth: "175px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  emailTypo: {
    maxHeight: "48px",
    maxWidth: "175px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  noData: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
};

export const radioFormStyle: Styles = {
  viewItemHead: {
    flexBasis: "40%",
    fontWeight: "bold",
    fontSize: "15px",
  },

  viewAll: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "500",
    width: "64%",
    m: "18px 0 24px 56px",
  },

  viewItem: {
    flexBasis: "40%",
    maxHeight: "48px",
    maxWidth: "175px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  emailTypo: {
    flexBasis: "60%",
    maxHeight: "48px",
    maxWidth: "175px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  noData: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
};
