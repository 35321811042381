import { Box } from "@mui/material";
import styles from "./styles";

const TermsAndConditions = () => {
  return (
    <Box sx={styles.iFrame}>
      <iframe
        src="/pdf/gerryTermsAndConditions.pdf"
        title="testPdf"
        height="100%"
        width="100%"
        frameBorder="0px"
      />
    </Box>
  );
};

export default TermsAndConditions;
