import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import {
  Box,
  Pagination as MatPagination,
  PaginationItem,
  SxProps,
} from "@mui/material";
import {
  GridToolbar,
  DataGrid as MatDataGrid,
  DataGridProps as MatDataGridProps,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import styles from "./styles";

type DataGridProps = MatDataGridProps & {
  toolbar?: boolean;
  emptyTable?: string;
  customStyles?: SxProps;
  hidePagination?: boolean;
};

const SortIconAsc = () => {
  return (
    <Box sx={styles.iconStyles}>
      <ArrowDropDownRoundedIcon fontSize="large" />
    </Box>
  );
};

const SortIconDesc = () => {
  return (
    <Box sx={styles.iconStyles}>
      <ArrowDropUpRoundedIcon fontSize="large" />
    </Box>
  );
};

const CustomPagination = ({
  rowsPerPageOptions,
  rowCount,
  hideFooterPagination,
}) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Box sx={styles.paginationWrapper}>
      {!hideFooterPagination && (
        <MatPagination
          color="primary"
          count={pageCount || 1}
          sx={styles.pagination}
          page={page + 1}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
          renderItem={(item) => (
            <PaginationItem sx={styles.paginationItem} {...item} />
          )}
        />
      )}
    </Box>
  );
};

const DataGrid = ({
  rows,
  columns,
  autoHeight = true,
  checkboxSelection = false,
  disableColumnFilter = true,
  disableColumnMenu = true,
  hideFooterPagination = false,
  loading = false,
  toolbar = false,
  customStyles = {},
  rowsPerPageOptions = [5],
  rowCount = 5,
  hidePagination = false,
  emptyTable = "No Data Available",
  paginationMode = "server",
  ...rest
}: DataGridProps) => {
  return (
    <Box sx={{ ...styles.dataGridWrapper, ...customStyles } as SxProps}>
      <MatDataGrid
        sx={styles.grid}
        rows={rows}
        columns={columns}
        autoHeight={autoHeight}
        checkboxSelection={checkboxSelection}
        disableColumnFilter={disableColumnFilter}
        disableColumnMenu={disableColumnMenu}
        loading={loading}
        sortingOrder={["asc", "desc"]}
        pagination
        density="standard"
        rowsPerPageOptions={rowsPerPageOptions}
        rowCount={rowCount}
        hideFooterPagination={hideFooterPagination}
        paginationMode={paginationMode}
        components={{
          NoRowsOverlay: () => <Box sx={styles.noRows}> {emptyTable}</Box>,
          ColumnResizeIcon: () => null,
          ColumnSortedDescendingIcon: SortIconDesc,
          ColumnSortedAscendingIcon: SortIconAsc,
          Footer: () =>
            CustomPagination({
              rowsPerPageOptions,
              rowCount,
              hideFooterPagination,
            }),
          Toolbar: toolbar && GridToolbar,
        }}
        {...rest}
      />
    </Box>
  );
};

export default DataGrid;
