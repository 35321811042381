import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import user from "redux/slices/user";
import pccInfo from "./slices/pccInfo";
import timZone from "./slices/timZone";

import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user"],
};

const userConfig = {
  key: "user",
  storage: storage,
};

const pccInfoConfig = {
  key: "pccInfo",
  storage: storage,
};

const timeZoneConfig = {
  key: "timZone",
  storage: storage,
};

const rootReducer = combineReducers({
  routing: routerReducer,
  pccInfo: persistReducer(pccInfoConfig, pccInfo),
  user: persistReducer(userConfig, user),
  timZone: persistReducer(timeZoneConfig, timZone),
});

export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export default persistedReducer;
