import EventsDefault from "components/feature/Events";

const Events = () => {
  return (
    <>
      <EventsDefault />
    </>
  );
};

export default Events;
