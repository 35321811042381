import {
  Autocomplete,
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputBaseProps,
  InputLabel,
  SxProps,
  TextField,
} from "@mui/material";
import {
  Controller,
  FieldErrors,
  FieldValues,
  UseControllerProps,
  UseFormClearErrors,
} from "react-hook-form";
import styles from "./styles";

const DeleteIcon = (props) => {
  return (
    <Box
      component="img"
      src="/icons/clear-icon.svg"
      alt="clear-icon"
      {...props}
    />
  );
};

const PopupIcon = () => {
  return <Box component="img" src="/icons/popup.svg" alt="clear-icon" />;
};

type AutoCompleteProps<T> = UseControllerProps<T> &
  FormControlProps &
  InputBaseProps & {
    label: string;
    errors?: FieldErrors;
    menuOptions?: { label: string; value: string | number }[];
    labelStyle?: SxProps;
    labelPos?: boolean;
    typing: boolean;
    fullWidth?: boolean;
    readonly?: boolean;
    placeholder?: string;
    clearErrors: UseFormClearErrors<T>;
  };

const MultiSelect = <T extends FieldValues>({
  control,
  name,
  rules,
  label,
  menuOptions,
  fullWidth = true,
  typing = true,
  labelPos = true,
  readOnly = false,
  placeholder,
  clearErrors,
}: AutoCompleteProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth={fullWidth} sx={styles.inputWrapper}>
          <Box className={labelPos && "labelPos"}>
            <InputLabel
              shrink={false}
              className="label"
              disabled={typing}
              required={!!rules?.required}
              htmlFor={name}
            >
              {label}
            </InputLabel>
            <Autocomplete
              multiple
              disableClearable
              id={name}
              options={menuOptions}
              sx={{
                "& .MuiInputBase-root": {
                  background: readOnly ? "none" : "white",
                },
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              popupIcon={<PopupIcon />}
              componentsProps={{ paper: { sx: styles.paper } }}
              ChipProps={{ size: "small", deleteIcon: <DeleteIcon /> }}
              getOptionLabel={(option) => option.label}
              readOnly={typing}
              value={field.value}
              onChange={(_, v) => field.onChange(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ tcaretColor: typing ? "transparent" : "inherit" }}
                  variant="standard"
                  placeholder={placeholder}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: typing,
                  }}
                  error={!!error}
                  onClick={() => clearErrors(name)}
                />
              )}
            />

            {!!error && (
              <FormHelperText sx={styles.error}>{error.message}</FormHelperText>
            )}
          </Box>
        </FormControl>
      )}
    />
  );
};

export default MultiSelect;
