import { Box } from "@mui/material";
import Radio from "@mui/material/Radio";
import React from "react";
import styles from "./styles";

const RadioInput = ({
  checked,
  handleChange,
  value,
  children,
}: {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  children: any;
  value: string;
}) => {
  return (
    <Box sx={styles.wrapper}>
      <label
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          gap: "15px",
          // height: "100%",
        }}
      >
        <Radio
          checked={checked}
          onChange={handleChange}
          value={value}
          name="radio-buttons"
          inputProps={{ "aria-label": "B" }}
          className="radioCss"
          disableRipple={false}
        />
        <Box sx={{ width: "75%" }}>{children}</Box>
      </label>
    </Box>
  );
};

export default RadioInput;
