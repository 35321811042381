import { Box } from "@mui/material";
import { useState } from "react";
import ChangePassword from "./ChangePassword";
import Profile from "./Form";
import styles from "./styles";

const ProfileDefault = () => {
  const [changePassword, setchangePassword] = useState<boolean>(false);
  return (
    <Box sx={styles.wrapper}>
      {!changePassword ? (
        <Profile changePassword={() => setchangePassword(true)} />
      ) : (
        <ChangePassword showProfile={() => setchangePassword(false)} />
      )}
    </Box>
  );
};

export default ProfileDefault;
