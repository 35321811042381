import { Box, IconButton, Popover } from "@mui/material";
import useWindowDimensions from "hooks/useWindowDimensions";
import _ from "lodash";
import { MouseEvent, useState } from "react";
import DatePicker from "react-datepicker";
import { setCalenderData } from "redux/slices/user";
import { useAppDispatch, useAppSelector } from "redux/store";
import { formatDate } from "../../Date/dateFunction";
import styles from "./styles";

type CalendarProps = {
  setDate: (val: string) => void;
  minDate?: string;
  maxDate?: string;
  name: string;
  readOnly?: boolean;
  onClick?: any;
  disableFuture?: boolean;
};

const CalendarIcon = () => {
  return (
    <Box
      sx={styles.calendarIcon}
      component="img"
      src="/icons/calendar.svg"
      alt="calendar-icon"
    />
  );
};

const CalendarRange = ({
  name,
  setDate,
  readOnly,
  onClick,
  disableFuture,
}: CalendarProps) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [calendarAnchor, setCalendarAnchor] = useState<HTMLElement | null>(
    null,
  );

  const { calenderData } = useAppSelector((state) => state.user);

  const [{ width, height }] = useWindowDimensions();

  const calendarHeightTrigger = height < 805;

  const dateIndex = name.split("_").pop();

  const dispatch = useAppDispatch();

  const newCalenderData = [...calenderData];

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setDate(formatDate(start));
    setDate(formatDate(end));
    const replacement = {
      id: dateIndex,
      fromDate: formatDate(start),
      toDate: formatDate(end),
    };
    const index = _.findIndex(newCalenderData, { id: replacement.id });
    if (index !== -1) {
      newCalenderData[index] = replacement;
    }
    dispatch(setCalenderData(newCalenderData));
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setCalendarAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setCalendarAnchor(null);
  };

  const disableFutureDates = (date) => {
    return disableFuture ? date < new Date() : true;
  };

  const open = !!calendarAnchor;
  const id = open ? `popover_${name}` : undefined;

  if (onClick) {
    return (
      <IconButton id={name} onClick={onClick} disabled={readOnly}>
        <CalendarIcon />
      </IconButton>
    );
  }

  const calenderManager = () => {
    const widthRanges = [
      { min: 1360, max: 1450, calc: "calc(0.75% + 23px)" },
      { min: 1450, max: 1550, calc: "calc(0.85% + 30px)" },
      { min: 1609, max: 1649, calc: "calc(1.5% + 35px)" },
      { min: 1649, max: 1700, calc: "calc(1.5% + 40px)" },
      { min: 1699, max: 1750, calc: "calc(1.70% + 39px)" },
      { min: 1749, max: 1800, calc: "calc(1.80% + 43px)" },
      { min: 1799, max: 1870, calc: "calc(2% + 45px)" },
      { min: 1869, max: 1900, calc: "calc(2% + 50px)" },
      { min: 1899, max: 1950, calc: "calc(2.20% + 50px)" },
      { min: 1949, max: 2000, calc: "calc(2.20% + 55px)" },
      { min: 1999, max: 2050, calc: "calc(2.40% + 55px)" },
      { min: 2049, max: 2100, calc: "calc(2.40% + 55px)" },
      { min: 2100, max: Infinity, calc: "calc(2.60% + 55px)" },
    ];

    const range = widthRanges.find(
      ({ min, max }) => width > min && width < max,
    );

    return range ? range.calc : undefined;
  };

  return (
    <Box>
      <IconButton id={name} onClick={handleClick} disabled={readOnly}>
        <CalendarIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={calendarAnchor}
        anchorOrigin={{
          vertical: calendarHeightTrigger ? "top" : "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: calendarHeightTrigger ? "bottom" : "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            marginTop: calendarHeightTrigger ? "-5px" : "13px",
            boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25) !important",
            marginLeft: {
              xl: width < 1610 ? "calc(0.85% + 40px)" : calenderManager(),
              lg: width < 1360 ? "calc(0.6% + 1%)" : calenderManager(),
              md: "calc(0% + 1%)",
              sm: "calc(0% + 65px)",
              xs: "calc(0% + 65px)",
            },
          },
        }}
        sx={styles.calendarWrapper}
      >
        <DatePicker
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          onChange={onChange}
          inline
          monthsShown={2}
          filterDate={disableFutureDates}
        />
      </Popover>
    </Box>
  );
};

export default CalendarRange;
