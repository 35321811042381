import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import { AxiosResponse } from "axios";
import Button from "components/common/Button";
import {
  Date as DatePicker,
  Input,
  Time,
} from "components/common/FormComponents";
import TextArea from "components/common/FormComponents/TextArea";
import PopUp, { Action } from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import { errorModal } from "constants/dummyData";
import errorMessage from "constants/errorMessage";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
} from "react-query";
import { useAppSelector } from "redux/store";
import { addEvent, updateEvent } from "utils/api/user";
import { compareTime, getDateDifference, toGMT } from "utils/common";
import { FormType } from "..";
import styles from "./styles";

export type EventFormData = {
  eventName: string;
  venue: string;
  description: string;
  startTime: string;
  endTime: string;
  startDate: string;
  endDate: string;
};

type EventFormProps = {
  submitBtnLabel: string;
  btnLabel?: string;
  data?: EventFormData;
  viewOnly?: boolean;
  onSubmitForm?: (data: any) => void;
  form: FormType;
  refetchAllEvents: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<QueryObserverResult<AxiosResponse<any>, unknown>>;
  setForm: React.Dispatch<React.SetStateAction<FormType>>;
};

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
};

type InfoModal = {
  props: {
    description: string;
  };
} & ModalData;

const EventForm = ({
  btnLabel,
  data = {
    eventName: "",
    venue: "",
    description: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
  },
  viewOnly = false,
  submitBtnLabel,
  onSubmitForm,
  form,
  refetchAllEvents,
  setForm,
}: EventFormProps) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    getValues,
    clearErrors,
    formState,
  } = useForm<EventFormData>({
    mode: "onChange",
    defaultValues: data,
  });

  const { errors, isValid, isDirty } = formState;

  const {
    pccInfo: { pccId },
    user: {
      eventRowData: { id },
      userDetails: { id: organizedBy },
    },
  } = useAppSelector((state) => state);

  const { mutate: getEventUpdated, isLoading: updateEventLoading } =
    useMutation(
      "updateByEventId",
      (eventUpdatedData: { eventId: string; updatedData: any }) =>
        updateEvent(eventUpdatedData),
      {
        onSuccess: () => {
          setModal(false);
          refetchAllEvents();
          setForm(null);
        },
        onError: (err) => {
          if (err["response"].data.statusCode !== 401) {
            setModal(true);
            setModalData(errorModal);
          }
        },
        retry: 0,
      },
    );

  const { mutate: addAnEvent, isLoading: addEventLoading } = useMutation(
    "addByEventId",
    (addEventData: any) => addEvent(addEventData),
    {
      onSuccess: () => {
        refetchAllEvents();
        setModal(false);
        setForm(null);
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setModal(true);
        }
      },
      retry: 0,
    },
  );

  const from = watch("startTime");
  const to = watch("endTime");

  useEffect(() => {
    const start = new Date(watch("startDate"));
    const end = new Date(watch("endDate"));

    if (start > end) {
      setError("endDate", {
        message: "End date can't be less than start date.",
      });
    }

    const status =
      getDateDifference(start, end) === 0 && !compareTime(to, from);

    if (status) {
      setError("endTime", {
        message: "End Time Must Be After Start Time",
        type: "exceedStartTime",
      });
    }
  }, [from, setError, to, watch, watch("startDate"), watch("endDate")]);

  const editEventModal: InfoModal = {
    heading: "Edit Event",

    props: {
      description: "Are you sure you want to edit and post this event?",
    },
    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      type: "submit",
      label: "Yes",
    },
  };

  const createEventModal: InfoModal = {
    heading: "Post Event",

    props: {
      description: "Are you sure you want to create and post this event?",
    },

    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      type: "submit",
      label: "Yes",
    },
  };

  const payloadManager = (payload) => {
    const { startTime, endTime, startDate, endDate, ...rest } = payload;

    return {
      ...rest,
      startDate: toGMT(startDate, startTime).toISOString(),
      endDate: toGMT(endDate, endTime).toISOString(),
    };
  };

  const handleClose = () => {
    setModal(false);
    setModalData(viewOnly ? editEventModal : createEventModal);
  };

  const handleModal = () => {
    if (form === "add") {
      addAnEvent({
        ...payloadManager(getValues()),
        organizedBy,
        careHomeId: pccId,
      });
      setModal(false);
    } else {
      getEventUpdated({
        eventId: id,
        updatedData: payloadManager(getValues()),
      });
      setModal(false);
    }
  };

  const [modalData, setModalData] = useState(
    viewOnly ? editEventModal : createEventModal,
  );
  const [readOnly, setReadOnly] = useState<boolean>(viewOnly);
  const [openModal, setModal] = useState<boolean>(false);

  return (
    <Box sx={styles.wrapper}>
      {!!modalData && (
        <PopUp
          open={!!openModal}
          handleClose={handleClose}
          heading={modalData.heading}
          actionLeft={modalData.actionLeft}
          actionRight={modalData.actionRight}
          handleRightBtnClick={handleModal}
          loading={updateEventLoading || addEventLoading}
        >
          <SimplePopUp description={modalData.props.description} />
        </PopUp>
      )}
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Box sx={styles.submitWrapper}>
          {readOnly ? (
            <Button
              label={btnLabel}
              sx={styles.submitBtn}
              variant="contained"
              icon={!viewOnly}
              onClick={() => setReadOnly(false)}
            >
              <AddIcon fontSize="small" sx={styles.icon} />
            </Button>
          ) : (
            <>
              <Button
                label={submitBtnLabel}
                sx={styles.submitBtn}
                variant="contained"
                icon={false}
                disabled={!(isValid && isDirty)}
                type="submit"
                onClick={() => setModal(true)}
              >
                <AddIcon fontSize="small" sx={styles.icon} />
              </Button>
            </>
          )}
        </Box>
        <Box sx={styles.inputWrapper}>
          <Box sx={styles.input}>
            <Input
              control={control}
              label="Event Name"
              name="eventName"
              errors={errors}
              labelPos
              readOnly={readOnly}
              autoFocus={!readOnly}
              rules={{
                required: errorMessage.required,
                validate: (v) =>
                  v.length <= 250 ||
                  "Event name can not have more than 250 characters.",
              }}
            />
          </Box>
          <Box sx={styles.dflex}>
            <Box sx={styles.input}>
              <Input
                control={control}
                label="Venue"
                name="venue"
                readOnly={readOnly}
                labelPos
                errors={errors}
                rules={{
                  required: errorMessage.required,
                }}
              />
            </Box>
          </Box>
          <Box sx={{ ...styles.input, marginTop: "20px" }}>
            <TextArea
              name="description"
              label="Description"
              type="text"
              control={control}
              multiline
              minRows={10}
              maxRows={10}
              errors={errors}
              readOnly={readOnly}
              maxLength={500}
              labelPos
              customStyles={styles.textArea}
            />
          </Box>
          <Box sx={{ ...styles.dflex, marginTop: "20px" }}>
            <Box sx={styles.input}>
              <DatePicker
                name="startDate"
                control={control}
                type="date"
                label="Start Date"
                labelPos
                className="date"
                minDate={watch().startDate}
                maxDate={watch().endDate}
                errors={errors}
                readOnly={readOnly}
                fullWidth
                setValue={setValue}
                rules={{ required: errorMessage.required }}
                noSelected={true}
              />
            </Box>
            <Box sx={styles.input}>
              <DatePicker
                name="endDate"
                control={control}
                type="date"
                label="End Date"
                labelPos
                className="date"
                minDate={watch().startDate}
                maxDate={watch().endDate}
                readOnly={readOnly}
                errors={errors}
                fullWidth
                setValue={setValue}
                noSelected={true}
                rules={{
                  required: errorMessage.required,
                  validate: () =>
                    new Date(watch("startDate")) <=
                    new Date(watch("endDate")) ||
                    "End date can't be less than start date.",
                }}
              />
            </Box>
          </Box>
          <Box sx={{ ...styles.dflex, marginBottom: "-5px" }}>
            <Box sx={styles.input}>
              <Time
                name="startTime"
                label="Start Time"
                control={control}
                labelPos
                readOnly={readOnly}
                getValues={getValues}
                setValue={setValue}
                clearErrors={clearErrors}
                rules={{ required: errorMessage.required }}
              />
            </Box>
            <Box sx={styles.input}>
              <Time
                name="endTime"
                label="End Time"
                control={control}
                labelPos
                readOnly={readOnly}
                getValues={getValues}
                setValue={setValue}
                clearErrors={clearErrors}
                rules={{
                  required: errorMessage.required,
                  validate: () =>
                    !(
                      getDateDifference(
                        new Date(watch("startDate")),
                        new Date(watch("endDate")),
                      ) === 0 && !compareTime(to, from)
                    ) || "End Time Must Be After Start Time",
                }}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default EventForm;
