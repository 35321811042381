import { Box } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import DataGrid from "components/common/DataGrid";
import { DateRangePicker as DatePicker } from "components/common/FormComponents";
import Info from "components/common/Info";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useAppSelector } from "redux/store";
import { getContactUs, getFamilyMember, getReflections } from "utils/api/user";
import { toGMT } from "utils/common";
import { dateManager } from "utils/helper";
import * as XLSX from "xlsx";
import { InfoModal } from "../ManageResidents";
import styles from "./styles";

const reflectionHeader = {
  firstName: "First Name of the family member who filled the Reflections ",
  lastName: "Last Name of the family member who filled the Reflections",
  residentName: "Resident Name ",
  careHomeName: "Care Home Name ",
  question: "Reflection Questions",
  answer: "Reflection Responses",
  createdAt: "Date Posted",
};

const contactUsHeader = {
  careHomeName: "Care Home Name ",
  reason: "Contact us Questionnaire ",
  date: "Date of the questions submitted.",
  month: "Month of the questions submitted.",
  year: "Year of the questions submitted.",
};

const familyHeader = {
  residentName: "Resident Name",
  firstName: "First Name",
  lastName: "Last Name",
  isPrimaryMember: "Primary Member",
  isFamilyMember: "Family Member",
  gender: "Gender",
  dateOfBirth: "Date of Birth",
  email: "Email",
  city: "City",
  province: "Province",
  country: "Country",
};

const headerMap = {
  reflections: reflectionHeader,
  "family-member": familyHeader,
  "contact-us": contactUsHeader,
};

type ReportingData = {
  id: number;
  reports: string;
  fromDate: string;
  toDate: string;
  action: string;
}[];

const ReportingDefault = () => {
  const totalData = 0;

  const {
    user: { globalInfoData, mrModalView, calenderData },
  } = useAppSelector((state) => state);

  const { control, formState, setValue } = useForm();

  const { errors } = formState;

  const getData = (id: number) => {
    return {
      from:
        toGMT(calenderData?.[id]?.fromDate, "12:00 AM")?.toISOString() || "",
      to: toGMT(calenderData?.[id]?.toDate, "11:59 PM")?.toISOString() || "",
    };
  };

  const { refetch: refetchReflections } = useQuery(
    ["getReflections"],
    () => getReflections(getData(0)),
    {
      enabled: false,
      onSuccess: (data) => handleDownload(data?.data?.data, "reflections"),
      retry: 1,
    },
  );

  const { refetch: refetchContactUs } = useQuery(
    ["getContactUs"],
    () => getContactUs(getData(1)),
    {
      enabled: false,
      onSuccess: (data) =>
        handleDownload(dateManager(data?.data?.data), "contact-us"),
      retry: 1,
    },
  );

  const { refetch: refetchFamilyMember } = useQuery(
    ["getFamilyMember"],
    () => getFamilyMember(getData(2)),
    {
      enabled: false,
      onSuccess: (data) => handleDownload(data?.data?.data, "family-member"),
      retry: 1,
    },
  );

  const handleDownload = (fetchedData, fileName: string) => {
    const jsonData = [headerMap[fileName], ...fetchedData];

    const arrayOfArrayCsv = jsonData.map((row: any) => {
      return Object?.values(row)?.map((cell: any) => {
        return cell?.toString()?.replace(/"/g, "");
      });
    });
    const wb = XLSX.utils.book_new();
    const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);

    const colMap = {
      reflections: [
        { wch: 55 },
        { wch: 55 },
        { wch: 35 },
        { wch: 20 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
      ],
      "family-member": [
        { wch: 20 },
        { wch: 20 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 20 },
        { wch: 20 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
      ],
      "contact-us": [
        { wch: 20 },
        { wch: 40 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
      ],
    };

    newWs["!cols"] = colMap[fileName];

    XLSX.utils.book_append_sheet(wb, newWs, "sheet1");
    const rawExcel = XLSX.write(wb, { type: "base64" });

    const link = document.createElement("a");
    link.href =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      rawExcel;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClick = (params) => {
    const reportingEngine = {
      0: refetchReflections,
      1: refetchContactUs,
      2: refetchFamilyMember,
    };
    return reportingEngine[params]();
  };

  const renderFromDate = (params: GridCellParams) => {
    return (
      <Box sx={styles.calenderWrapper}>
        <DatePicker
          name={`fromDate_${params?.row?.id}`}
          control={control}
          type="date"
          className="date"
          errors={errors}
          setValue={setValue}
          value={calenderData?.[params?.row?.id]?.fromDate || ""}
          disableFuture={true}
        />
      </Box>
    );
  };

  const renderToDate = (params: GridCellParams) => {
    return (
      <Box sx={styles.calenderWrapper}>
        <DatePicker
          name={`toDate_${params.row.id}`}
          control={control}
          type="date"
          className="date"
          errors={errors}
          setValue={setValue}
          value={calenderData?.[params?.row?.id]?.toDate || ""}
          disableFuture={true}
          onClick={() =>
            document.getElementById(`fromDate_${params?.row?.id}`)?.click()
          }
        />
      </Box>
    );
  };

  const renderAction = (params: GridCellParams) => {
    return (
      <Box
        id={`${params.row.id}`}
        sx={{
          ...styles.download,
          cursor: calenderData?.[`${params.row.id}`]?.toDate
            ? "pointer"
            : "not-allowed",
        }}
        onClick={() => handleClick(params.row.id)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleClick(params.row.id);
          }
        }}
        tabIndex={0}
      >
        Download
      </Box>
    );
  };

  const reportingData: ReportingData = [
    {
      id: 0,
      reports: "Reflections",
      fromDate: "March, 16, 1209",
      toDate: "March, 18, 1209",
      action: "Download",
    },
    {
      id: 1,
      reports: "Contact Us",
      fromDate: "March, 16, 1209",
      toDate: "March, 18, 1209",
      action: "Download",
    },
    {
      id: 2,
      reports: "Family Details",
      fromDate: "March, 16, 1209",
      toDate: "March, 18, 1209",
      action: "Download",
    },
  ];

  const reportingColumn: GridColDef[] = [
    {
      field: "reports",
      headerName: "Reports",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "fromDate",
      headerName: "From",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderFromDate,
    },
    {
      field: "toDate",
      headerName: "To",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderToDate,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderAction,
    },
  ];

  return (
    <Box sx={styles.wrapper}>
      <Info
        content={globalInfoData?.content}
        heading={globalInfoData?.heading}
        mrViewAllModal={mrModalView as InfoModal}
        showToggle
        disabled
      />
      <DataGrid
        rows={reportingData || []}
        columns={reportingColumn}
        disableSelectionOnClick
        rowsPerPageOptions={[10]}
        pageSize={10}
        rowCount={totalData}
        loading={false}
        componentsProps={{
          toolbar: { showQuickFilter: false },
        }}
      />
    </Box>
  );
};

export default ReportingDefault;
