import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputBaseProps,
  InputBase,
  InputLabel,
  Box,
  InputBaseComponentProps,
} from "@mui/material";
import { SxProps } from "@mui/system";
import {
  Controller,
  FieldErrors,
  FieldValues,
  UseControllerProps,
  UseFormResetField,
} from "react-hook-form";
import MaskedInput from "./MaskedInput";
import styles from "./styles";
import { ElementType } from "react";

type MaskedInputProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  maskedFormat: string;
};

type PostalCodeProps<T> = UseControllerProps<T> &
  FormControlProps &
  InputBaseProps & {
    label: string;
    errors?: FieldErrors;
    customStyles?: SxProps;
    maskedFormat?: string;
    shrink?: boolean;
    labelPos?: boolean;
    customStyle?: { [key: string]: SxProps };
    onBlur?: React.HTMLAttributes<HTMLDivElement>;
    resetField?: UseFormResetField<any>;
  };

const PostalCode = <T extends FieldValues>({
  name,
  control,
  errors,
  label,
  rules,
  disabled = false,
  fullWidth = true,
  customStyles,
  maskedFormat = "*** ***",
  className,
  resetField,
  onBlur,
  shrink = false,
  labelPos = true,
  customStyle,
  ...rest
}: PostalCodeProps<T>) => {
  const error = errors && name ? errors[name] : undefined;
  const showError = !!error?.message;

  return (
    <Controller
      render={({ field }) => (
        <FormControl
          className={className}
          fullWidth={fullWidth}
          sx={{ ...styles.wrapper, ...customStyles } as SxProps}
          variant="standard"
        >
          <Box className={labelPos && "labelPos"}>
            <InputLabel
              htmlFor={name}
              shrink={shrink}
              className="label"
              required={!!rules?.required}
              disabled={disabled}
            >
              {label}
            </InputLabel>
            <InputBase
              sx={{ ...customStyle?.input } as SxProps}
              value={field.value}
              onChange={field.onChange}
              disabled={disabled}
              error={showError}
              onBlur={(e) => {
                if (e.target.value === "___ ___") {
                  resetField(name, {
                    keepError: false,
                    keepTouched: false,
                    keepDirty: false,
                  });
                }

                !!onBlur ? onBlur(e) : field.onBlur();
              }}
              name={name}
              id={name}
              inputComponent={
                MaskedInput as React.ElementType<MaskedInputProps> as ElementType<InputBaseComponentProps>
              }
              inputProps={{ maskedFormat }}
              {...rest}
            />
          </Box>

          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
      name={name}
      control={control}
      rules={rules}
      {...rest}
    />
  );
};

export default PostalCode;
