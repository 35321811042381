import { createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "redux/slices/signOut";

interface PccInfo {
  pccId: string;
}

const initialState: PccInfo = {
  pccId: "",
};

const pccSlice = createSlice({
  name: "pccData",
  initialState,
  reducers: {
    setPccId: (state, action) => {
      state.pccId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction, (state, _action) => {
      state.pccId = initialState.pccId;
    });
  },
});

export const { setPccId } = pccSlice.actions;

export default pccSlice.reducer;
