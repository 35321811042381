import { Styles } from "types/common";

const styles: Styles = {
  progressWrapper: {
    position: "relative",
    width: "395px",
    height: "89px",
    borderRadius: "6px",
    border: "2px solid #EFEFEF",
    boxShadow: "8px 2px 8px -4735px rgba(58, 58, 58, 0.64)",
    display: "flex",
    background: "#fff",
    flexDirection: "column",
    p: "11px 28px 17px 16px",
    gap: "10px",
    margin: "0 auto",
  },
  progressText: {
    fontSize: "12px",
    fontWeight: "500",
  },
  progressContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "15px",
    width: "100%",
  },
  fileIcon: {
    width: "32px",
    height: "32px",
    borderRadius: " 6px",
    border: "1px solid #ADADAD",
    boxShadow: "8px 2px 8px -4735px rgba(58, 58, 58, 0.64)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  progressStatus: {
    display: "flex",
    flexDirection: "column",
    gap: "3px",
    width: "100%",
    "& .MuiLinearProgress-root": {
      backgroundColor: "#ffffff",
      border: "1px solid #E5E5E5",
    },

    "& .MuiLinearProgress-bar": {
      backgroundColor: "custom.background.dropdownCta",
      borderRight: "1px solid #E5E5E5",
    },
  },
  closeIcon: {
    "& .MuiSvgIcon-root ": {
      width: "11px",
      height: "11px",
    },
    cursor: "pointer",
    position: "absolute",
    top: 8,
    right: 8,
  },
};

export default styles;
