import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import Button from "components/common/Button";
import DataGrid from "components/common/DataGrid";
import { Date as DatePicker, Time } from "components/common/FormComponents";
import CheckBox from "components/common/FormComponents/Checkbox";
import Info from "components/common/Info";
import PopUp, { Action } from "components/common/PopUp";
import {
  InfoPopUp,
  SimpleInput,
  SimplePopUp,
} from "components/common/PopUp/Modal";
import SearchBar from "components/common/SearchField";
import { errorModal } from "constants/dummyData";
import errorMessage from "constants/errorMessage";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useAppSelector } from "redux/store";
import { deleteAppointmentById, getAllAppointments } from "utils/api/user";
import {
  appointmentDataTransformer,
  compareTime,
  getDateDifference,
  toGMT,
  toLocalDate,
} from "utils/common";
import { InfoModal } from "../ManageResidents";
import AppointmentForm from "./Form";
import styles from "./styles";

export type FormType = "MarkUnavailable" | "Cancel" | null;

export type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
  props?: any;
};

const cancelModal: ModalData = {
  heading: "Cancel Appointment",
  actionLeft: {
    label: "Cancel",
  },
  actionRight: {
    label: "Yes",
    type: "submit",
  },
};

const Appointments = () => {
  const [openModal, setModal] = useState<"filter" | "cancel" | "error">(null);
  const [page, setPage] = useState<number>(1);
  const [form, setForm] = useState<FormType>();
  const [localForm, setLocalForm] = useState<boolean>(false);
  const [modalData, setModalData] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [search, setSearch] = useState("");
  const [rowData, setRowData] = useState<any>({});
  const [today, setToday] = useState<boolean>(false);
  const [tomorrow, setTomorrow] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>({});
  const [slotCount, setSlotsCount] = useState<number>(0);
  const [filter, setFilter] = useState<boolean>(false);
  const {
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
    reset,
    setError,
    formState: { errors, isValid },
  } = useForm<{
    fromDate: string;
    toDate: string;
    fromTime: string;
    toTime: string;
    cancel: string;
  }>({
    mode: "onChange",
    defaultValues: {
      fromDate: "",
      toDate: "",
      fromTime: "",
      toTime: "",
      cancel: "",
    },
  });

  const start = getValues("fromDate");
  const end = getValues("toDate");

  const from = watch("fromTime");
  const to = watch("toTime");

  useEffect(() => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    if (startDate > endDate) {
      setError("toDate", {
        message: "To Date can't be less than from date.",
      });
    }

    if (getDateDifference(startDate, endDate) === 0 && !compareTime(to, from)) {
      setError("toTime", {
        message: "Please enter valid to time.",
      });
    }
  }, [end, from, setError, start, to]);

  useEffect(() => {
    if (today) {
      reset({
        fromDate: toLocalDate(new Date()),
        toDate: toLocalDate(new Date()),
        fromTime: "12:00 AM",
        toTime: "11:59 PM",
        cancel: "",
      });
    }

    if (tomorrow) {
      reset({
        fromDate: toLocalDate(new Date().setDate(new Date().getDate() + 1)),
        toDate: toLocalDate(new Date().setDate(new Date().getDate() + 1)),
        fromTime: "12:00 AM",
        toTime: "11:59 PM",
        cancel: "",
      });
    }

    if (tomorrow && today) {
      reset({
        fromDate: toLocalDate(new Date()),
        toDate: toLocalDate(new Date().setDate(new Date().getDate() + 1)),
        fromTime: "12:00 AM",
        toTime: "11:59 PM",
        cancel: "",
      });
    }
  }, [reset, today, tomorrow]);

  useEffect(() => {
    const startDate = new Date(watch("fromDate"));
    const endDate = new Date(watch("toDate"));

    if (startDate > endDate) {
      setError("toDate", {
        message: "End date can't be less than start date.",
      });
    }

    const status =
      getDateDifference(startDate, endDate) === 0 && !compareTime(to, from);

    if (status) {
      setError("toTime", {
        message: "End Time Must Be After Start Time",
        type: "exceedStartTime",
      });
    }
  }, [from, setError, to, watch, watch("fromDate"), watch("toDate")]);

  useEffect(() => {
    refetchAllAppointments();
  }, []);

  const {
    pccInfo: { pccId },
    user: { globalInfoData, mrModalView },
  } = useAppSelector((state) => state);

  const { data, refetch: refetchAllAppointments } = useQuery(
    ["getAppointments", page, dateRange, pccId, search],
    () => getAllAppointments({ pccId, page, search, ...dateRange }),
    {
      onSuccess: (res) => {
        setTotalData(res?.data?.metadata?.total);
        dateRange?.to ? setFilter(true) : setFilter(false);
      },
    },
  );

  const { mutate: appointmentDelete, isLoading } = useMutation(
    "deleteAppointment",
    () =>
      deleteAppointmentById({
        appointmentId: rowData?.id,
        reason: watch("cancel"),
      }),
    {
      onSuccess: () => {
        refetchAllAppointments({});
        setValue("cancel", "");
        setModal(null);
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setModal("error");
        }
      },
      retry: 1,
    },
  );

  const getSearchText = (text: string) => {
    const txt = text.trim().toLowerCase();
    setSearch(txt);
  };

  const handleRightBtnClick = () => {
    switch (openModal) {
      case "cancel":
        appointmentDelete();
        break;
      case "filter":
        const inValidTime = `"":"" ""`;
        const { cancel, ...rest } = getValues();

        setDateRange({
          from: toGMT(
            rest.fromDate,
            rest?.fromTime === inValidTime
              ? "12:00 AM"
              : rest?.fromTime || "12:00 AM",
          ).toISOString(),
          to: toGMT(
            rest.toDate,
            rest?.toTime === inValidTime
              ? "11:59 PM"
              : rest?.toTime || "11:59 PM",
          ).toISOString(),
        });
        handleClose();
        break;
    }
  };

  const handleFilterClick = () => {
    setModalData({
      heading: "Filter",
      actionLeft: {
        label: "Clear",
        onClick: () => {
          reset({
            cancel: "",
            fromDate: "",
            toTime: `"":"" ""`,
            toDate: "",
            fromTime: `"":"" ""`,
          });
          setToday(false);
          setTomorrow(false);
          setDateRange({
            from: "",
            to: "",
          });
        },
      },
      actionRight: {
        label: "Apply",
      },
    });
    setModal("filter");
  };

  const onAppointmentClick = (row) => {
    setForm("Cancel");
  };

  const handleClose = () => {
    setValue("cancel", "");
    clearErrors("cancel");
    setModal(null);
  };

  const onClickUnavailable = () => {
    setForm("MarkUnavailable");
  };

  const renderAppointment = (params: GridCellParams) => {
    return (
      <Box
        sx={styles.tableLink}
        onClick={() => {
          setRowData(params.row);
          onAppointmentClick(params.row);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setRowData(params.row);
            onAppointmentClick(params.row);
          }
        }}
        tabIndex={0}
      >
        {params.row.appointment}
      </Box>
    );
  };

  const renderVisitor = (params: GridCellParams) => {
    return <>{params.row.visitorName}</>;
  };

  const renderAction = (params: GridCellParams) => {
    return (
      <Box
        sx={styles.cancelWrapper}
        onClick={() => {
          setModalData(cancelModal);
          setModal("cancel");
          setRowData(params.row);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setModalData(cancelModal);
            setModal("cancel");
            setRowData(params.row);
          }
        }}
        tabIndex={0}
      >
        Cancel
      </Box>
    );
  };

  const appointmentColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "S.No",
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "appointment",
      headerName: "Appointment Title",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderAppointment,
    },
    {
      field: "residentName",
      headerName: "Resident Name",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "visitorName",
      headerName: "Visitor Name",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderVisitor,
    },
    {
      field: "dateTime",
      headerName: "Date & Time",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderAction,
    },
  ];

  return (
    <Box sx={styles.wrapper}>
      {!!modalData && (
        <PopUp
          open={!!openModal}
          handleClose={handleClose}
          heading={modalData.heading}
          actionLeft={modalData.actionLeft}
          actionRight={{
            ...modalData.actionRight,
            disabled:
              openModal === "cancel" ? !watch("cancel").length : !isValid,
          }}
          loading={false}
          customStyles={{
            button: { marginTop: openModal === "filter" ? "14px" : "0px" },
          }}
          handleRightBtnClick={handleRightBtnClick}
          note={
            openModal === "filter"
              ? "Note: Data will be retained for a period of three months in compliance with industry regulations."
              : ""
          }
        >
          {openModal === "filter" && (
            <InfoPopUp
              customStyles={{
                container: styles.infoPopupContainer,
                viewAllWrapper: styles.viewAllWrapper,
              }}
            >
              <Box sx={styles.filterWrapper}>
                <Box sx={styles.labelWrapper}>Date</Box>
                <Box sx={styles.fieldWrapper}>
                  <CheckBox
                    label="Today"
                    checked={today}
                    handleChange={(e) => setToday(e.target.checked)}
                  />

                  <CheckBox
                    label="Tomorrow"
                    checked={tomorrow}
                    handleChange={(e) => setTomorrow(e.target.checked)}
                  />
                  <DatePicker
                    name="fromDate"
                    control={control}
                    type="date"
                    label="From Date"
                    labelPos
                    className="date"
                    minDate={watch().fromDate}
                    maxDate={watch().toDate}
                    errors={errors}
                    fullWidth
                    setValue={setValue}
                    rules={{ required: errorMessage.required }}
                    customStyles={{
                      wrapper: styles.secondaryDateCustomWrapper,
                      input: styles.dateSelector,
                    }}
                  />
                  <DatePicker
                    name="toDate"
                    control={control}
                    type="date"
                    label="To Date"
                    labelPos
                    className="date"
                    minDate={watch().fromDate}
                    maxDate={watch().toDate}
                    errors={errors}
                    fullWidth
                    setValue={setValue}
                    rules={{
                      required: errorMessage.required,
                      validate: () =>
                        new Date(watch("fromDate")) <=
                          new Date(watch("toDate")) ||
                        "End date can't be less than start date.",
                    }}
                    customStyles={{
                      input: styles.dateSelector,
                    }}
                  />
                </Box>
                <Box sx={{ ...styles.labelWrapper, marginTop: "10px" }}>
                  Time
                </Box>
                <Box sx={styles.fieldWrapper}>
                  <Time
                    name="fromTime"
                    label="From"
                    control={control}
                    labelPos
                    getValues={getValues}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    customStyles={{
                      wrapper: styles.timeCustomWrapper,
                    }}
                  />
                  <Time
                    name="toTime"
                    label="To"
                    control={control}
                    labelPos
                    getValues={getValues}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    rules={{
                      validate: () =>
                        !(
                          getDateDifference(
                            new Date(watch("fromDate")),
                            new Date(watch("toDate")),
                          ) === 0 && !compareTime(to, from)
                        ) || "End Time Must Be After Start Time",
                    }}
                  />
                </Box>
              </Box>
            </InfoPopUp>
          )}
          {openModal === "cancel" && (
            <SimpleInput
              name="cancel"
              label={"Please enter the reason for canceling this appointment"}
              control={control}
              placeholder="Reason for cancelation"
              errors={errors}
              autoFocus={false}
              rules={{
                required: {
                  value: true,
                  message: errorMessage.required,
                },
              }}
              minRows={3.5}
              maxRows={3.5}
              multiline
              textArea
            />
          )}
          {openModal === "error" && (
            <SimplePopUp description={modalData.props.description} />
          )}
        </PopUp>
      )}
      <Info
        content={globalInfoData?.content}
        heading={globalInfoData?.heading}
        mrViewAllModal={mrModalView as InfoModal}
        showToggle={false}
      />
      {!form ? (
        <Box sx={styles.tableWrapper}>
          <Box sx={styles.secondaryWrapper}>
            <Box sx={styles.searchWrapper}>
              <SearchBar getText={getSearchText} />
            </Box>
            <Box
              sx={{
                ...styles.settingWrapper,
                backgroundColor: filter ? "#056ee6" : "white",
              }}
              tabIndex={0}
              onClick={() => handleFilterClick()}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleFilterClick();
                }
              }}
            >
              <Box
                component="img"
                sx={{
                  filter: filter ? "brightness(0) invert(1)" : "none",
                }}
                src="/icons/settingsBox.svg"
                alt="filter"
              />
            </Box>
            <Box sx={styles.btnWrapper}>
              <Button
                label="Block off time"
                icon
                sx={styles.btn}
                onClick={() => onClickUnavailable()}
              />
            </Box>
          </Box>
          <DataGrid
            rows={appointmentDataTransformer(data?.data?.data) || []}
            columns={appointmentColumn}
            disableSelectionOnClick
            onPageChange={(pageInfo) => setPage(pageInfo + 1)}
            rowsPerPageOptions={[5]}
            pageSize={10}
            rowCount={totalData}
            loading={isLoading}
            componentsProps={{
              toolbar: { showQuickFilter: true },
            }}
            emptyTable="No appointments yet."
          />
        </Box>
      ) : (
        <Box sx={styles.formBtnWrapper}>
          <Box sx={styles.backWrapper}>
            <Box
              sx={styles.backStyles}
              onClick={() => {
                localForm ? setLocalForm(false) : setForm(null);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  localForm ? setLocalForm(false) : setForm(null);
                }
              }}
              tabIndex={0}
            >
              <ArrowBackIosIcon sx={styles.backArrow} />
              <Box>Back</Box>
            </Box>
          </Box>
          <AppointmentForm
            submitBtnLabel="Cancel"
            setForm={setForm}
            rowData={rowData}
            as={form}
            refetch={refetchAllAppointments}
            setLocalForm={setLocalForm}
            localForm={localForm}
            setSlotsCount={setSlotsCount}
            slotCount={slotCount}
          />
        </Box>
      )}
    </Box>
  );
};

export default Appointments;
