import { SxProps } from "@mui/material";

const styles: { [key: string]: SxProps } = {
  backWrapper: {
    position: "absolute",
    marginTop: "-42px",
  },

  backArrow: {
    marginRight: "10px",
    cursor: "pointer",
  },

  backStyles: {
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    cursor: "pointer",
    color: "custom.misc.trueBlue",
    top: "10px",
    left: "10px",
    "&:after": {
      top: "20px",
      content: '""',
      display: "block",
      margin: "auto",
      height: "1.5px",
      width: "100%",
      background: "#030303",
      position: "absolute",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
      marginRight: "0",
    },
  },

  actionRight: { marginTop: "-30px" },

  popupWrapper: {
    minWidth: "915px",
    minHeight: "535px",
    padding: "30px 64px 32px",
  },

  infoContainer: {
    height: "100%",
    padding: "0",
    width: "100%",
  },

  viewAllWrapper: {
    background: "#F3F5F8",
    padding: "0",
  },

  editStyles: {
    color: "#056EE6",
    fontSize: "12px",
    textDecoration: "underline",
    cursor: "pointer",
  },

  customFormWrapper: { padding: "0" },

  customEmailWrapper: { width: "375px" },

  customFieldWrapper: { width: "184px" },
};

export default styles;
