import { Box } from "@mui/material";
import Button from "components/common/Button";
import { Input } from "components/common/FormComponents";
import PopUp from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import errorMessage from "constants/errorMessage";
import { route } from "constants/routes";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { useAppSelector } from "redux/store";
import { forgotPassword } from "utils/api/user";
import { getTrimmedData } from "utils/helper";
import regex from "utils/regex";
import styles from "./styles";

export type ForgotPasswordFormData = {
  email: string;
};

const ForgotPassword = () => {
  const { signInEmail } = useAppSelector((state) => state.user);
  const { control, formState, handleSubmit, setError } =
    useForm<ForgotPasswordFormData>({
      defaultValues: {
        email: useMemo(() => signInEmail, [signInEmail]),
      },
      criteriaMode: "all",
      mode: "all",
    });

  const [openModal, setOpenModal] = useState(false);
  const { isValid } = formState;

  const history = useHistory();

  const forgot = useMutation(
    "ForgotPassword",
    (data: ForgotPasswordFormData) => forgotPassword(data),
    {
      onSuccess: (res) => setOpenModal(true),
      onError: (err) => {
        if (err["response"].data.errorCode === "MYG_ERR_LOC002") {
          setError("email", { message: err["response"].data.message });
        } else {
          setError("email", { message: errorMessage.invalidEmail });
        }
      },
    },
  );

  const onSubmit = (data: ForgotPasswordFormData) => {
    forgot.mutate(getTrimmedData(data));
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <PopUp
        open={openModal}
        handleClose={handleClose}
        heading="Reset Link Sent"
        actionLeft={{
          hidden: true,
        }}
        actionRight={{
          label: "Okay",
        }}
        handleRightBtnClick={handleClose}
      >
        <SimplePopUp description="A link to reset your password has been sent. Please check your email for further instructions." />
      </PopUp>
      <Box sx={styles.wrapper}>
        <Box sx={styles.forgotPassword}>Forgot Password?</Box>
        <Box sx={styles.heading}>
          Please enter a registered Email ID to receive a reset link.
        </Box>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="email"
            label="Email ID"
            control={control}
            errors={formState.errors}
            customStyles={styles.input}
            rules={{
              required: { value: true, message: errorMessage.required },
              pattern: {
                value: regex.email,
                message: errorMessage.invalidEmail,
              },
            }}
          />

          <Button
            type="submit"
            label="Continue"
            customStyles={styles.continueBtn}
            disabled={!isValid}
          />
        </Box>
        <Button
          as="FormButton"
          label="Sign In"
          onClick={() => history.push(route.signIn.path)}
        />
      </Box>
    </>
  );
};

export default ForgotPassword;
