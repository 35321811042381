import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    gap: "14px",
    mb: "20px",
    "& .MuiRadio-root": {
      padding: "4px",
      ml: "18px",
      "&:hover": {
        padding: "4px",
      },
    },
    "& .radioCss": {
      "& .MuiSvgIcon-root": {
        transitionDuration: "0s" /* You can adjust the duration as needed */,
        width: "18px",
        height: "18px",
        color: "#000000 !important",
      },
    },

    "& .Mui-checked": {
      position: "relative",

      "&::after": {
        position: "absolute",
        content: "''",
        backgroundImage: "url('/icons/selectedRadio.svg')",
        width: "16px",
        height: "16px",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      },
    },
  },
};

export default styles;
