import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    marginTop: "70px",
    padding: "0 20px",
  },
  footerWrapper: {
    height: "60px",
    backgroundColor: "custom.background.main",
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    textDecoration: "underline",
    fontSize: "12px",
    gap: "8px",
    marginTop: "20px",
    "& a": {
      color: "#056ee6",
    },
  },
};

export default styles;
