import { Box, IconButton, Popover } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../dateFunction";
import styles from "./styles";

type CalendarProps = {
  setDate: (val: string) => void;
  minDate?: string;
  maxDate?: string;
  readOnly?: boolean;
  disableFuture?: boolean;
  showYearDropdown?: boolean;
  noSelected?: boolean;
};

const CalendarIcon = () => {
  return (
    <Box
      sx={styles.calendarIcon}
      component="img"
      src="/icons/calendar.svg"
      alt="calendar-icon"
    />
  );
};

const Calendar = ({
  setDate,
  readOnly,
  minDate = "",
  maxDate = "",
  disableFuture = false,
  showYearDropdown = false,
  noSelected,
}: CalendarProps) => {
  const [startDate, setStartDate] = useState(new Date());
  const [calendarAnchor, setCalendarAnchor] = useState<HTMLElement | null>(
    null,
  );

  const startRange = new Date(minDate);
  const endRange = new Date(maxDate);

  useEffect(() => {
    if (minDate) {
      setStartDate(new Date(minDate));
    }
  }, []);

  const onChangeHandler = (date) => {
    setStartDate(date);
    setDate(formatDate(date));
    handleClose();
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setCalendarAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setCalendarAnchor(null);
  };

  const disableFutureDates = (date) => {
    return disableFuture ? date < new Date() : true;
  };

  const open = !!calendarAnchor;
  const id = open ? "calendar-popover" : undefined;

  return (
    <Box>
      <IconButton onClick={handleClick} disabled={readOnly}>
        <CalendarIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={calendarAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          ...styles.calendarWrapper,
          "& .react-datepicker__current-month": {
            fontSize: "11px",
            marginBottom: showYearDropdown ? "8px" : "18px",
            color: "custom.text.secondary",
            fontWeight: 700,
          },

          "& .react-datepicker__navigation--years-upcoming": {
            top: 0,
            position: "relative",
            textIndent: "0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "&::after": {
              content: `""`,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "14px",
              height: "14px",
              backgroundImage: `url(/icons/Up.svg)`,
              backgroundSize: "cover",
            },
          },

          "& .react-datepicker__navigation--years-previous": {
            top: 0,
            position: "relative",
            textIndent: "0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "&::after": {
              content: `""`,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "14px",
              height: "14px",
              backgroundImage: `url(/icons/down.svg)`,
              backgroundSize: "cover",
            },
          },
        }}
      >
        <DatePicker
          selected={noSelected ? null : startDate}
          startDate={startRange}
          endDate={endRange}
          onChange={(date) => onChangeHandler(date)}
          filterDate={disableFutureDates}
          inline
          showYearDropdown={showYearDropdown}
        />
      </Popover>
    </Box>
  );
};

export default Calendar;
