import { InputBase } from "@mui/material";
import { styled } from "@mui/system";
import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    margin: "25px 0",
    position: "relative",
    "& .labelInline": {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },

    "& .label": {
      fontSize: "14px",
      fontWeight: "bold",
      minWidth: "110px",
      color: "custom.text.main",
    },
  },

  separator: {
    margin: "0 4px 0 0",
  },

  input: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    textAlign: "center",
    "& .inputField": {
      color: "custom.text.secondary",
      borderWidth: "1px",
      fontSize: "12px",
      borderRadius: "5px",
      borderStyle: "solid",
      borderColor: "custom.border.main",
      width: "56px",
      height: "28px",
      margin: "0 4px 0 0",
      padding: "12px 20px",
    },
    "& .MuiInputBase-input": {
      MozAppearance: "textfield",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        "&::-moz-focus-inner": {
          borderStyle: "none",
        },
      },
    },
  },

  periodBtn: {
    borderWidth: "1px",
    borderRadius: "5px",
    borderStyle: "solid",
    width: "42px",
    fontSize: "12px",
    height: "28px",
    borderColor: "custom.background.darkGray",
    padding: "4px 10px",
    marginRight: "4px",
    cursor: "pointer",
    "& input ": {
      cursor: "pointer",
    },
    "& input :focus": {
      outline: "none",
    },
  },

  error: {
    textTransform: "capitalize",
    color: "error.main",
    fontSize: "12px",
    padding: "0px",
    margin: "0px 4px",
    marginLeft: "113px",
  },
};

export default styles;

type PeriodProps = {
  timePeriod: boolean;
};

export const Period = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== "timePeriod",
})(({ timePeriod }: PeriodProps) =>
  ({
    borderWidth: "1px",
    borderRadius: "5px",
    borderStyle: "solid",
    width: "42px",
    fontSize: "12px",
    height: "28px",
    borderColor: "custom.background.darkGray",
    padding: "4px 10px",
    marginRight: "4px",
    cursor: "pointer",
    backgroundColor: timePeriod ? "primary.dark" : "none",
    color: timePeriod ? "white" : "custom.background.darkGray",
    "& input": {
      cursor: "pointer",
    },
    "& input:focus": {
      outline: "none",
    },
  }),
);
