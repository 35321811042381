import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import Button from "components/common/Button";
import { Input, MultiSelect } from "components/common/FormComponents";
import TextArea from "components/common/FormComponents/TextArea";
import PopUp, { Action } from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import { errorModal, selectOptions } from "constants/dummyData";
import errorMessage from "constants/errorMessage";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
} from "react-query";
import { useAppSelector } from "redux/store";
import { addNewAnnouncement, updateAnnouncementById } from "utils/api/user";
import styles from "./styles";

type AnnouncementFormData = {
  title: string;
  description: string;
  tags: string[];
};

type FormType = "add" | "edit";

type AnnouncementFormProps = {
  submitBtnLabel: string;
  btnLabel?: string;
  data?: AnnouncementFormData;
  viewOnly?: boolean;
  onSubmitForm?: (data: any) => void;
  form: any;
  refetchAllAnnouncements: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<QueryObserverResult<AxiosResponse<any>, unknown>>;
  setForm: React.Dispatch<React.SetStateAction<FormType>>;
};

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
};

type InfoModal = {
  props: {
    description: string;
  };
} & ModalData;

const AnnouncementForm = ({
  btnLabel,
  data = {
    title: "",
    description: "",
    tags: [],
  },
  viewOnly = false,
  onSubmitForm,
  submitBtnLabel,
  form,
  refetchAllAnnouncements,
  setForm,
}: AnnouncementFormProps) => {
  const { control, handleSubmit, formState, clearErrors, getValues } =
    useForm<any>({
      mode: "onChange",
      defaultValues: data,
    });

  const { errors, isValid, isDirty } = formState;

  const {
    pccInfo: { pccId },
    user: {
      announcementRowData: { id },
      userDetails: { id: announcedBy },
    },
  } = useAppSelector((state) => state);

  const {
    mutate: addNewAnnouncementData,
    isLoading: addNewAnnouncementLoading,
  } = useMutation(
    "addNewAnnouncements",
    (announcement: any) => addNewAnnouncement(announcement),
    {
      onSuccess: () => {
        refetchAllAnnouncements();
        setForm(null);
        setModal(false);
        setForm(null);
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setModal(true);
        }
      },
      retry: 1,
    },
  );

  const {
    mutate: getUpdatedAnnouncement,
    isLoading: updateAnnouncementLoading,
  } = useMutation(
    "updateAnnouncementById",
    (updatedAnnouncementData: { announcementId: string; updatedData: any }) =>
      updateAnnouncementById(updatedAnnouncementData),
    {
      onSuccess: () => {
        setModal(false);
        refetchAllAnnouncements();
        setForm(null);
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModal(true);
          setModalData(errorModal);
        }
      },
      retry: 1,
    },
  );

  const editAnnouncementModal: InfoModal = {
    heading: "Edit Announcement",

    props: {
      description: "Are you sure you want to edit and post this Announcement?",
    },
    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      type: "submit",
      label: "Yes",
    },
  };

  const createAnnouncementModal: InfoModal = {
    heading: "Create Announcement",

    props: {
      description:
        "Are you sure you want to create and post this announcement?",
    },

    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      type: "submit",
      label: "Yes",
    },
  };
  const [modalData, setModalData] = useState(
    viewOnly ? editAnnouncementModal : createAnnouncementModal,
  );
  const [readOnly, setReadOnly] = useState<boolean>(viewOnly);
  const [openModal, setModal] = useState<boolean>(false);

  const handleClose = () => {
    setModal(false);
    setModalData(viewOnly ? editAnnouncementModal : createAnnouncementModal);
  };

  const getTagsValue = () => {
    const { tags } = getValues();
    return tags.map((item) => item?.value?.toLowerCase());
  };

  const handleModal = () => {
    if (form === "add") {
      addNewAnnouncementData({
        ...getValues(),
        tags: getTagsValue(),
        careHomeId: pccId,
        announcedBy,
      });
      setModal(false);
    } else {
      getUpdatedAnnouncement({
        announcementId: id,
        updatedData: {
          ...getValues(),
          tags: getTagsValue(),
          careHomeId: pccId,
          announcedBy,
        },
      });
      setModal(false);
    }
  };

  return (
    <Box sx={styles.wrapper}>
      {!!modalData && (
        <PopUp
          open={!!openModal}
          handleClose={handleClose}
          heading={modalData.heading}
          actionLeft={modalData.actionLeft}
          actionRight={modalData.actionRight}
          handleRightBtnClick={handleModal}
          loading={addNewAnnouncementLoading || updateAnnouncementLoading}
        >
          <SimplePopUp description={modalData.props.description} />
        </PopUp>
      )}
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Box sx={styles.submitWrapper}>
          {readOnly ? (
            <Button
              label={btnLabel}
              sx={styles.submitBtn}
              variant="contained"
              onClick={() => setReadOnly(false)}
            />
          ) : (
            <>
              <Button
                label={submitBtnLabel}
                sx={styles.submitBtn}
                variant="contained"
                disabled={!(isValid && isDirty)}
                type="submit"
                onClick={() => setModal(true)}
              />
            </>
          )}
        </Box>
        <Box sx={styles.inputWrapper}>
          <Box sx={styles.input}>
            <Input
              name="title"
              label="Subject"
              control={control}
              readOnly={readOnly}
              errors={errors}
              autoFocus={!readOnly}
              labelPos
              rules={{
                required: errorMessage.required,
              }}
            />
          </Box>
          <Box sx={{ ...styles.textArea }}>
            <TextArea
              name="description"
              label="Description"
              type="text"
              control={control}
              multiline
              readOnly={readOnly}
              minRows={10}
              maxRows={10}
              errors={errors}
              maxLength={500}
              labelPos
              customStyles={styles.textArea}
            />
          </Box>
          <MultiSelect
            control={control}
            labelStyle={styles.labelStyle}
            clearErrors={clearErrors}
            name="tags"
            readOnly={readOnly}
            label="Select Tags"
            typing={false}
            menuOptions={selectOptions}
            rules={{ required: errorMessage.required }}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AnnouncementForm;
