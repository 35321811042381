import { Box, SxProps } from "@mui/material";
import React from "react";
import styles from "./styles";

type SecondaryPopUpType = {
  data?: {
    name: string;
    email: string;
  }[];
  children?: React.ReactNode;
  customStyles?: { [key: string]: SxProps };
};

const Info = ({ data, children, customStyles }: SecondaryPopUpType) => {
  return (
    <Box
      sx={
        { ...styles.viewAllWrapper, ...customStyles?.viewAllWrapper } as SxProps
      }
    >
      {!!data && (
        <Box
          sx={
            {
              ...styles.container,
              ...(data?.length === 0 && styles.noData),
            } as SxProps
          }
        >
          {data.length === 0 ? (
            <Box sx={styles.noData}>Data not available</Box>
          ) : (
            <>
              <Box sx={styles.viewAll}>
                <Box sx={styles.viewItemHead}>Name</Box>
                <Box sx={styles.viewItemHead}>Email</Box>
              </Box>
              {data.map((item, idx) => (
                <Box sx={{ ...styles.viewAll, gap: "5px" }} key={idx}>
                  <Box sx={styles.viewItem}>{item.name}</Box>
                  <Box sx={styles.emailTypo}>{item.email}</Box>
                </Box>
              ))}
            </>
          )}
        </Box>
      )}
      {!!children && (
        <Box
          sx={{ ...styles.container, ...customStyles?.container } as SxProps}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default Info;
