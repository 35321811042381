import ResidentsDefault from "components/feature/ManageResidents";

const ManageResidents = () => {
  return (
    <>
      <ResidentsDefault />
    </>
  );
};

export default ManageResidents;
