import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "primary.main",
    // padding: "0 10px",
  },
  inputWrapper: {
    margin: "10px 0 24px 0",
    padding: "18px 40px 0px 40px",
    backgroundColor: "custom.background.gray",
  },

  labelStyle: {
    fontSize: "14px",
    fontWeight: "bold",
    minWidth: "110px",
  },

  dflex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "40px",
  },

  eventBtnWrapper: { display: "flex", flexBasis: "100%" },

  submitWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },

  submitBtn: {
    fontSize: "14px",
    minWidth: "150px",
    margin: "8px 0",
    height: "28px",
    backgroundColor: "primary.dark",
    "&:disabled": {
      backgroundColor: "primary.light",
      color: "white",
    },
  },

  input: {
    flexBasis: "49%",
  },

  marginCommonStyles: {
    marginTop: "20px",
  },

  bottomSection: {
    marginBottom: "-5px",
  },
};

export default styles;
