import { Box, Button, SxProps } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import DataGrid from "components/common/DataGrid";
import PopUp, { Action } from "components/common/PopUp";

import { SimplePopUp } from "components/common/PopUp/Modal";
import FamilyRadioPopUp from "components/common/PopUp/Modal/FamilyRadioPopUp";
import Toggle from "components/common/Toggle";
import { errorModal } from "constants/dummyData";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useAppSelector } from "redux/store";
import { MemberStatusToggle } from "types/api";
import { ManageResidentData } from "types/common";
import {
  addMember,
  changePrimaryMember,
  getMember,
  updateMemberStatus,
} from "utils/api/user";
import { familyTransformer, primaryTransformer } from "utils/helper";
import { ResidentDetailsProps } from "../";
import InviteResident from "../InviteResident";
import styles from "../styles";
import AddNewPrimaryContact from "./AddNewPrimaryContact";

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
};

type Modal =
  | "activate"
  | "change"
  | "invite"
  | "unableToFetch"
  | "addNew"
  | undefined;

type ContactsProps = {
  contact: ManageResidentData;
  refetchAllResidents: any;
  residentId: string;
  setShowResidentDetail: React.Dispatch<
    React.SetStateAction<ResidentDetailsProps>
  >;
  refetchFlag: boolean;
  setRefetchFlag: React.Dispatch<React.SetStateAction<boolean>>;
};

type InviteSent = {
  props: {
    description: string;
  };
} & ModalData;

type ToggleResident = {
  props: {
    description: string;
    note?: string;
  };
} & ModalData;

export type ChangePrimaryContactFormData = {
  newPrimaryContact: string;
};

const Contacts = ({
  contact,
  refetchAllResidents,
  residentId,
  setShowResidentDetail,
  refetchFlag,
  setRefetchFlag,
}: ContactsProps) => {
  const [rowData, setRowData] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [changeToPrimary, setChangeToPrimary] = useState<string>("");
  const [totalData, setTotalData] = useState(0);
  const [isEmailUnique, setIsEmailUnique] = useState(false);

  const { control, formState, getValues, setError, watch } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
  }>({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
  });

  const { isValid } = formState;

  const {
    pccInfo: { pccId },
    user: { globalInfoData },
  } = useAppSelector((state) => state);

  const { data: primaryMembers, refetch: refetchPrimary } = useQuery(
    ["getPrimary", contact?.id],
    () => getMember({ gerryId: contact?.id, type: "primary" }),
    {
      onSuccess: () => {
        refetchAllResidents();
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal("unableToFetch");
        }
      },
      retry: 1,
    },
  );

  const { data: familyMembers, refetch: refetchFamily } = useQuery(
    ["getFamily", contact?.id, page],
    () =>
      getMember({
        gerryId: contact?.id,
        type: "family",
        page,
        prePage: 8,
      }),
    {
      onSuccess: (res) => {
        setTotalData(res?.data?.metadata?.total);
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal("unableToFetch");
        }
      },
      retry: 1,
    },
  );

  const { mutate, isLoading } = useMutation(
    "statusToggle",
    (data: MemberStatusToggle) => updateMemberStatus(data),
    {
      onSuccess: () => {
        refetchAllResidents();
        refetchPrimary();
        refetchFamily();
        handleClose();
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal("unableToFetch");
        }
      },
      retry: 1,
    },
  );

  // Api to change primary contact - (residentId & newMemberId)
  const { mutate: changePrimaryMutate } = useMutation(
    "changePrimaryContact",
    (data: { residentId: string; newPrimaryId: string }) =>
      changePrimaryMember(data),
    {
      onSuccess: () => {
        setModalData(inviteSent);
        setOpenModal("invite");
        setIsEmailUnique(false);
      },
      onError: (err) => {},
    },
  );

  // Api to add a family member first then, make it primary.
  const { mutate: addMemberMutate } = useMutation(
    "addAMember",
    (data: {
      residentId: string;
      isPrimary: boolean;
      firstName: string;
      lastName: string;
      email: string;
    }) => addMember(data),
    {
      onSuccess: (res) => {
        const newMemberId = res?.data?.data?.id;
        changePrimaryMutate({
          residentId: residentId,
          newPrimaryId: newMemberId,
        });
      },
      onError: (err) => {
        if (err["response"].data.errorCode === "MYG_ERR_C006") {
          setError("firstName", {
            message: "RESIDENT ALREADY EXISTS",
          });
        }
      },
    },
  );

  const [openModal, setOpenModal] = useState<Modal>(undefined);
  const [pageSize, setPageSize] = useState<number>(8);

  useEffect(() => {
    if (refetchFlag) {
      refetchFamily();
      setRefetchFlag(false);
    }
  }, [refetchFamily, refetchFlag, setRefetchFlag]);

  const getDescWithName = (name, isActive) => {
    return isActive
      ? `Do you want to deactivate '${name}'?`
      : `Do you want to activate '${name}'?`;
  };

  const deactivateResident: ToggleResident = {
    heading: "Deactivate Family Member",
    props: {
      description: "Are you sure you want to deactivate ‘Asus Nursing Home’?",
      note: "Note: The data streaming will be stopped for this resident’s family members.",
    },

    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      label: "Deactivate",
      disabled: false,
      color: "error",
      actionCustomStyles: {
        button: {
          backgroundColor: "#c0291d",
        },
      },
    },
  };

  const [modalData, setModalData] = useState(deactivateResident);
  const activateResident: ToggleResident = {
    heading: "Activate Family Member",
    props: {
      description: "Do you want to activate ‘Asus Nursing Home’?",
      note: "",
    },
    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      disabled: false,
      label: "Activate",
    },
  };

  const inviteSent: InviteSent = {
    heading: "Invite Sent",
    props: {
      description: "An email invite has been sent.",
    },
    actionLeft: {
      hidden: true,
    },
    actionRight: {
      label: "Okay",
      disabled: false,
      onClick: () => handleClose(),
    },
  };

  const changePrimaryCont: InviteSent = {
    heading: "Select New Primary Contact",
    props: {
      description: "",
    },
    actionLeft: {
      type: "button",
      label: "Add New",
      onClick: () => {
        setModalData(addNewPrimary);
        setOpenModal("addNew");
      },
    },
    actionRight: {
      type: "submit",
      label: "Send Invite",
      disabled: !!!changeToPrimary,
    },
  };

  const addNewPrimary: ModalData & {
    props: {
      description: string;
    };
  } = {
    heading: "Add New Primary Contact",
    props: {
      description: "",
    },
    actionLeft: {
      hidden: true,
    },
    actionRight: {
      label: "Send Invite",
      disabled: !isValid,
    },
  };

  const renderStatus = (params: GridCellParams, type = "primary") => {
    return (
      <Box sx={styles.actionIcon}>
        <Toggle
          value={params.row.status}
          onChange={() => handleToggle(params.row, type)}
        />
      </Box>
    );
  };

  const renderInvitePrimary = (params: GridCellParams) => {
    return (
      <Box sx={styles.tableLink}>
        {params?.row?.onBoarded && globalInfoData?.source === "gerry" ? (
          <Box
            onClick={() => {
              setModalData(changePrimaryCont);
              setOpenModal("change");
            }}
          >
            Change Primary Contact
          </Box>
        ) : (
          <InviteResident
            params={{ ...params.row, gerryId: contact.gerryId, id: residentId }}
            label="Please enter primary contact email address"
            type="primaryMember"
            refetchPrimary={refetchPrimary}
          />
        )}
      </Box>
    );
  };

  const renderInvite = (params: GridCellParams) => {
    return (
      <Box sx={params.row.status ? styles.tableLink : styles.tableLinkDisabled}>
        <InviteResident
          params={{ ...params.row, gerryId: contact.gerryId, id: residentId }}
          type="familyMember"
          secondaryHeading="Invite Family Member"
          refetchPrimary={refetchPrimary}
          refetchFamily={refetchFamily}
        />
      </Box>
    );
  };

  const primaryContactColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "S.No",
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "primaryContact",
      headerName: "Primary Contact",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email ID",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Data",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderStatus,
    },

    {
      field: "invite",
      headerName: "Invite",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderInvitePrimary,
    },
  ];

  const familyMemberColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "S.No",
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "familyMember",
      headerName: "Family Member",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email ID",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Data",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => renderStatus(params, "family"),
    },
    {
      field: "invite",
      headerName: "",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => renderInvite(params),
      renderHeader: () => {
        return globalInfoData?.source !== "pcc" ? (
          <Button
            variant="outlined"
            onClick={() => {
              setShowResidentDetail({
                primaryMembers: primaryMembers?.data?.data,
                familyMembers: familyMembers?.data?.data,
                show: true,
              });
            }}
            sx={{ width: "auto", height: "38px" }}
          >
            Add family member
          </Button>
        ) : (
          <></>
        );
      },
    },
  ];

  const handleToggle = (data, type = "primary") => {
    const name = type === "primary" ? data.primaryContact : data.familyMember;
    setRowData(data);
    data.status
      ? setModalData({
          ...deactivateResident,
          props: {
            ...deactivateResident.props,
            description: getDescWithName(name, data.status),
          },
          heading:
            type === "primary"
              ? "Deactivate Primary Contact"
              : "Deactivate Family Member",
        })
      : setModalData({
          ...activateResident,
          props: {
            ...activateResident.props,
            description: getDescWithName(name, data.status),
          },
          heading:
            type === "primary"
              ? "Activate Primary Contact"
              : "Activate Family Member",
        });
    setOpenModal("activate");
  };

  const handleClose = () => {
    setOpenModal(undefined);
  };

  const handleModal = () => {
    switch (openModal) {
      case "activate": {
        setOpenModal(undefined);
        mutate({ gerryId: contact?.id, pccId, ...rowData });
        break;
      }
      case "change": {
        //api calling for change primary contact without pcc
        changePrimaryMutate({
          residentId: residentId,
          newPrimaryId: changeToPrimary,
        });
        break;
      }
      case "addNew": {
        //api calling for addNew primary contact without pcc
        const formData = getValues();
        const dataObj = {
          residentId: residentId,
          isPrimary: false,
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          email: formData?.email,
        };
        if (dataObj?.email === "") {
          delete dataObj.email;
        }
        isEmailUnique && addMemberMutate(dataObj);
        break;
      }

      case "invite": {
        refetchPrimary();
        refetchFamily();
        handleClose();
        setOpenModal(undefined);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <Box
      sx={{ ...styles.wrapper, ...styles.familyMemberTableWrapper } as SxProps}
    >
      {!!modalData && (
        <PopUp
          open={!!openModal}
          handleClose={handleClose}
          heading={modalData.heading}
          actionLeft={modalData.actionLeft}
          actionRight={{
            ...modalData.actionRight,
            disabled:
              openModal === "change"
                ? !!!changeToPrimary
                : openModal === "addNew"
                ? !isValid
                : false,
          }}
          handleRightBtnClick={handleModal}
          loading={isLoading}
          customStyles={
            openModal === "change" && {
              leftActionButton: {
                position: "absolute",
                top: "50px",
              },
            }
          }
        >
          {openModal === "activate" && (
            <SimplePopUp
              description={modalData.props.description}
              note={modalData.props.note}
            />
          )}

          {openModal === "change" && (
            <FamilyRadioPopUp
              data={familyMembers?.data?.data}
              totalData={totalData}
              changeToPrimary={changeToPrimary}
              setChangeToPrimary={setChangeToPrimary}
              contactId={contact?.id}
            />
          )}

          {openModal === "addNew" && (
            <AddNewPrimaryContact
              control={control}
              formState={formState}
              setError={setError}
              watch={watch}
              setIsEmailUnique={setIsEmailUnique}
            />
          )}

          {openModal === "invite" && (
            <SimplePopUp description={modalData.props.description} />
          )}

          {openModal === "unableToFetch" && (
            <SimplePopUp
              description={modalData.props.description}
              note={modalData.props.note}
            />
          )}
        </PopUp>
      )}

      <DataGrid
        rows={primaryTransformer(primaryMembers?.data?.data)?.slice(-1) || []}
        columns={primaryContactColumn}
        disableSelectionOnClick
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCount={primaryMembers?.data?.metadata?.total || 0}
        loading={false}
        customStyles={{ height: 150 }}
        hideFooterPagination
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        emptyTable="No primary member added yet."
      />

      <DataGrid
        rows={familyTransformer(familyMembers?.data?.data) || []}
        columns={familyMemberColumn}
        disableSelectionOnClick
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        pageSize={pageSize}
        rowCount={totalData}
        loading={false}
        onPageChange={(pageNo) => setPage(pageNo + 1)}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        emptyTable="No family member added yet."
      />
    </Box>
  );
};

export default Contacts;
