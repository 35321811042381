import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import { AxiosResponse } from "axios";
import Button from "components/common/Button";
import { Input } from "components/common/FormComponents";
import PopUp, { Action } from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import errorMessage from "constants/errorMessage";
import { useEffect, useState } from "react";
import { FieldValues, UseFormReset, useForm } from "react-hook-form";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutateFunction,
  useMutation,
} from "react-query";
import { sendInvite } from "utils/api/user";
import regex from "utils/regex";
import styles from "./styles";

type FormData = {
  email: string;
  allEmails: string[];
};

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
};

type InviteEmail = ModalData;

type InviteSent = {
  props: {
    description: string;
  };
} & ModalData;

type Modal = "invite" | "sent" | "loading" | undefined;

type InviteProps = {
  label?: string;
  pccId: string;
  isResending?: boolean;
  open?: boolean;
  reset?: UseFormReset<FieldValues>;
  refetch?: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<QueryObserverResult<AxiosResponse<any>, unknown>>;
  mutateFun?: UseMutateFunction<AxiosResponse<any>>;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  emails?: [];
  leftBtnLabel?: string;
  handleLeftBtnClose?: () => void;
};

const ClearIcon = () => {
  return <Box component="img" src="icons/clear-icon.svg" alt="clear-icon" />;
};
const Invite = ({
  label = "",
  pccId,
  isResending,
  refetch,
  reset,
  open = false,
  setOpen,
  mutateFun,
  emails: adminEmails,
  leftBtnLabel = "Cancel",
  handleLeftBtnClose,
}: InviteProps) => {
  const {
    control,
    getValues,
    trigger,
    setError,
    setValue,
    formState: { errors, isValid },
    watch,
    reset: resetInvite,
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: { email: "", allEmails: [] },
  });

  useEffect(() => {
    if (open) {
      setModal("invite");
    }
  }, [open]);

  const { mutate, isLoading } = useMutation(
    "sendInvite",
    (data: { emails: string[] }) => sendInvite({ pccId, data, isResending }),
    {
      onSuccess: () => {
        setModal("sent");
        setModalData(inviteSent);
        refetch && refetch();
      },
      onError: (err) => {
        setError("email", { message: err["response"].data.message });
      },
    },
  );

  const [openModal, setModal] = useState<Modal>();
  const [emails, setEmails] = useState<string[]>([]);

  const handleClose = () => {
    resetInvite();
    setModal(undefined);
    setEmails([]);
    setModalData(invite);
    if (!!setOpen) {
      setOpen(false);
      reset();
    }
  };

  const getEmails = () => {
    trigger("email", { shouldFocus: true }).then((res) => {
      if (res) {
        const currEmail = getValues("email");
        if (currEmail.length === 0) return;
        if (!emails.some((email) => email === currEmail)) {
          setEmails([...emails, currEmail]);
          setValue("email", "");
        } else {
          setError("email", { message: errorMessage.emailExist });
        }
      }
    });
  };

  const deleteEmail = (idx: number) => {
    setEmails(emails.filter((_, index) => index !== idx));
  };

  const invite: InviteEmail = {
    heading: "Invite Care Home Admin",
    actionLeft: {
      label: leftBtnLabel,
      onClick: () =>
        handleLeftBtnClose ? handleLeftBtnClose() : handleClose(),
    },
    actionRight: {
      label: "Send Invite",
      disabled: emails.length === 0,
    },
  };

  const inviteSent: InviteSent = {
    heading: "Invite Sent",
    props: {
      description: "An email invite has been sent.",
    },
    actionLeft: {
      hidden: true,
    },
    actionRight: {
      label: "Okay",
      onClick: () => handleClose(),
    },
  };
  const [modalData, setModalData] = useState(invite);

  const handleRightBtnClick = () => {
    switch (openModal) {
      case "invite": {
        if (!errors.email && emails.length) {
        }
        mutate({
          emails,
        });
        break;
      }
      case "sent": {
        mutateFun && mutateFun();
        handleClose();
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <Box>
      {label && (
        <Box
          onClick={() => setModal("invite")}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setModal("invite");
            }
          }}
          tabIndex={0}
        >
          {label}
        </Box>
      )}
      <PopUp
        open={!!openModal}
        handleClose={handleClose}
        heading={modalData.heading}
        actionLeft={modalData.actionLeft}
        actionRight={{
          ...modalData.actionRight,
          disabled: emails.length === 0,
        }}
        handleRightBtnClick={handleRightBtnClick}
        loading={isLoading}
      >
        {openModal === "invite" && (
          <Box>
            <Input
              name="email"
              label="Please enter the Email Address of the Care Home admin to send an invite:"
              placeholder="Add Email"
              control={control}
              errors={errors}
              fullWidth
              customStyles={styles.input}
              autoFocus={true}
              rules={{
                pattern: {
                  value: regex.email,
                  message: errorMessage.invalidEmail,
                },
              }}
            />
            <Button
              label="Add"
              type="submit"
              icon
              disabled={!(watch("email") && isValid)}
              customStyles={styles.btn}
              onClick={getEmails}
            >
              <AddIcon />
            </Button>
            <Box sx={styles.emailWrapper}>
              {emails.length !== 0 &&
                emails.map((email, idx) => (
                  <Box sx={styles.emails} key={idx}>
                    {email}
                    <Box
                      component="span"
                      sx={styles.icon}
                      onClick={() => deleteEmail(idx)}
                    >
                      <ClearIcon />
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        )}

        {openModal === "sent" && (
          <SimplePopUp description={inviteSent.props.description} />
        )}
      </PopUp>
    </Box>
  );
};

export default Invite;
