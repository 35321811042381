import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import { useState } from "react";
import {
  Control,
  UseFormReset,
  FieldValues,
  UseFormSetValue,
  FieldErrors,
  UseFormWatch,
  UseFormSetError,
  UseFormResetField,
  UseFormClearErrors,
} from "react-hook-form";
import { ResidentDetails } from "..";
import styles from "../styles";
import ResidentDetail from "./Forms/ResidentDetail";

type ResidentProps<T extends object> = {
  control: Control<ResidentDetails, any>;
  setValue: UseFormSetValue<ResidentDetails>;
  reset: UseFormReset<ResidentDetails>;
  errors: FieldErrors;
  disabled?: boolean;
  watch: UseFormWatch<any>;
  setError: UseFormSetError<T>;
  customStyles?: SxProps;
  resetField: UseFormResetField<T>;
  clearErrors?: UseFormClearErrors<T>;
};

export const ClearBtn = ({ onClick }) => {
  return (
    <Box
      sx={styles.delBtn}
      component="img"
      src="/icons/clear-icon.svg"
      alt="del-icon"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick();
        }
      }}
      tabIndex={0}
    />
  );
};

const Resident = <T extends FieldValues>({
  control,
  errors,
  setValue,
  reset,
  disabled,
  watch,
  setError,
  customStyles,
  resetField,
  clearErrors,
}: ResidentProps<T>) => {
  const [formVisibility, setFormVisibility] = useState<boolean>(true);

  return (
    <>
      {formVisibility && (
        <ResidentDetail
          control={control}
          errors={errors}
          setFormVisibility={setFormVisibility}
          disabled={disabled}
          setValue={setValue}
          watch={watch}
          setError={setError}
          customStyles={customStyles}
          resetField={resetField}
          clearErrors={clearErrors}
        />
      )}
    </>
  );
};

export default Resident;
